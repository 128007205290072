import React, { useEffect, useState, useCallback } from 'react';
import { push, replace } from 'connected-react-router';
import { Switch, Route, NavLink, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Base64Image from '../../../components/Base64Image';
import { isEmpty } from 'lodash';

import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import classNames from 'classnames';

// Local imports
import { initialSkriningforClientProfile, getClientScreenings } from '../../../actions/omama.actions';
import { getUsersAction } from '../../../actions/users.actions';
import { strings } from '../../../strings/StringsProvider';
import { api } from '../../../conf';

import {
  deleteAction,
  getAdminClient,
  getClientScreeningsForReport,
  getAdminClientActionsAction,
  getAdminClientActionsCount,
  getAdminClientsAction,
  adminClientChangeDate,
  handleDeactivationDate,
  handleDeactivationOption,
  handleDeactivationDescription,
  getAllClientMeasurements,
} from '../../../actions/admin.actions';
import { setLoading } from '../../../actions/status.actions';
import { populateClientFormAction } from '../../../actions/clientForm.actions';
import { Modal, DatePicker, Table, Tag, TimePicker, Input, Dropdown, Popover } from 'antd';
import { DownOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import './AdminClient.scss';
import ActionDetailAdmin from '../../../components/ActionDetailAdmin';
import ScreeningDetailAdmin from '../../../components/ScreeningDetailAdmin';
import InterNDAScoreTable from '../../../components/InterNDAScoreTable';
import PreschoolScreeningResults from '../../../containers/admin/Measurements/PreschoolScreeningResults';
import ClientMilestones from '../../../containers/admin/ClientMilestones';
import { getDateDiff } from 'tools/date.tools';
import { getAllScreeningQuestions } from '../../../slices/screenings';
import { ArrowRightOutlined } from '@ant-design/icons';
import { AdminClientTabInfoDesktop } from './AdminClientTabInfo-desktop';
import { AdminClientTabGalleryDesktop } from './AdminClientTabGallery-desktop';
import ScreeningTable from '../../../components/ScreeningTable/ScreeningTable';

const { Column } = Table;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const subMenuItems = [
  { path: 'lekcie', text: strings.lessons1 },
  { path: 'galeria', text: strings.gallery },
  { path: 'info', text: strings.info },
  { path: 'milniky', text: strings.milestones },
  { path: 'skriningy', text: strings.screenings },
  { path: 'merania', text: strings.measurements },
];
const descriptionColumns = { xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 };
const columns = [
  {
    title: strings.date,
    dataIndex: 'date',
    key: 'date',
    render: (date) => <span>{dayjs(date).format('D. M. YYYY')}</span>,
  },
  {
    title: strings.time,
    dataIndex: 'date',
    key: 'time',
    render: (date) => <span>{dayjs(date).format('HH:mm')}</span>,
  },
  {
    title: strings.lessonState,
    dataIndex: 'statusForTable',
    key: 'status',
  },
  {
    title: strings.parentJoined,
    dataIndex: 'evaluation',
    key: 'evaluation1',
    render: (evaluation) => {
      let odpoved = evaluation.question1;
      switch (odpoved) {
        case 'notAtAll':
          odpoved = strings.notAtAll;
          break;
        case 'sometimes':
          odpoved = strings.sometimes;
          break;
        case 'often':
          odpoved = strings.veryOften;
          break;
        default:
          odpoved = '';
          break;
      }
      return <span>{odpoved}</span>;
    },
  },
  {
    title: strings.parentPraised,
    dataIndex: 'evaluation',
    key: 'evaluation2',
    render: (evaluation) => {
      let odpoved = evaluation.question2;
      switch (odpoved) {
        case 'notAtAll':
          odpoved = strings.notAtAll;
          break;
        case 'sometimes':
          odpoved = strings.sometimes;
          break;
        case 'often':
          odpoved = strings.veryOften;
          break;
        default:
          odpoved = '';
          break;
      }
      return <span>{odpoved}</span>;
    },
  },
  {
    title: strings.parentDevoted,
    dataIndex: 'evaluation',
    key: 'evaluation3',
    render: (evaluation) => {
      let odpoved = evaluation.question3;
      switch (odpoved) {
        case 'notAtAll':
          odpoved = strings.notAtAll;
          break;
        case 'sometimes':
          odpoved = strings.sometimes;
          break;
        case 'often':
          odpoved = strings.veryOften;
          break;
        default:
          odpoved = '';
          break;
      }
      return <span>{odpoved}</span>;
    },
  },
  {
    title: strings.comment,
    dataIndex: 'comment',
    key: 'comment',
  },
];

const measurementColumns = [
  {
    title: strings.date,
    dataIndex: 'date',
    key: 'date',
    render: (date, { type }) => (
      <span data-test-id={`client-measurements-${type}-date-desktop`}>{dayjs(date).format('D. M. YYYY')}</span>
    ),
  },
  {
    title: strings.createdBy,
    dataIndex: 'createdBy',
    key: 'createdBy',
    render: (createdBy, { type }) => (
      <div data-test-id={`client-measurements-${type}-createdBy-desktop`}>{createdBy}</div>
    ),
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: () => (
      <div className="showMeasurement">
        <span>{strings.show}</span>
        <ArrowRightOutlined />
      </div>
    ),
  },
];

const SHOW_ALL = strings.showAll;

function AdminClient({ match }) {
  const location = useLocation();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [activationVisible, setActivationVisible] = useState(false);
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [userRole] = useState(jwtDecode(localStorage.getItem('access-token')).role);
  const [actionDate, setActionDate] = useState('');
  const [deactivationDescription, setDeactivationDescription] = useState('');
  const [actionTime, setActionTime] = useState(dayjs());
  const [detailAction, setDetailAction] = useState(undefined);
  const [screeningDetailVisible, setScreeningDetailVisible] = useState(false);
  const [screeningDetail, setScreeningDetail] = useState(undefined);
  const [showDeleteClient, setShowDeleteClient] = useState(false);

  const [isAdmin] = useState(jwtDecode(localStorage.getItem('access-token')).role === 'admin');
  const [section, setSection] = useState(SHOW_ALL);
  const [displaySection, setDisplaySection] = useState(SHOW_ALL);

  const [openPopUp, setOpenPopUp] = useState(false);
  const client = useSelector((state) => state.admin.oneClient);
  const screeningReport = useSelector((state) => state.report.screeningReport);
  const clientActions = useSelector((state) => state.admin.singleClientActions);
  const clientFrom = useSelector((state) => state.admin.clientFrom);
  const clientTo = useSelector((state) => state.admin.clientTo);
  const usersById = useSelector((state) => state.users.byId);
  const counts = useSelector((state) => state.admin.counts);
  const initialScreenings = useSelector((state) => state.omama.initialScreeningsForClientProfile);
  const screenings = useSelector((state) => state.screenings);
  const interNDA = useSelector((state) => state.measurements.interNDA);
  const preschoolScreening = useSelector((state) => state.measurements.preschoolScreening);

  const dispatch = useDispatch();

  const changePage = (location) => dispatch(push(location));
  const replacePage = useCallback((location) => dispatch(replace(location)), [dispatch]);

  const open = match.params.clientId;

  useEffect(() => {
    dispatch(getAdminClient(open));
  }, [dispatch, open]);

  useEffect(() => {
    if (location.search.includes('obnovitUdaje=true')) {
      window.scrollTo(0, 0);
      replacePage(location.pathname);
    }
  }, [dispatch, location.search, location.pathname, replacePage]);

  useEffect(() => {
    if (isEmpty(usersById)) {
      dispatch(getUsersAction());
    }
    dispatch(adminClientChangeDate([null, null]));
    dispatch(
      getAdminClientActionsAction(
        open,
        dayjs().startOf('year').format('YYYY-MM-DD'),
        dayjs().endOf('year').format('YYYY-MM-DD'),
      ),
    );

    dispatch(getAdminClientActionsCount(open));
    dispatch(getAllScreeningQuestions());

    if (client) {
      dispatch(getClientScreeningsForReport(client));
    }

    dispatch(getClientScreenings(open));
    dispatch(initialSkriningforClientProfile(open));
    dispatch(getAllClientMeasurements(open));

    setDisplaySection(getFilterString('displaySection') !== null ? getFilterString('displaySection') : SHOW_ALL);
    setSection(getFilterString('section') !== null ? getFilterString('section') : SHOW_ALL);
  }, [client, dispatch, open, usersById]);

  useEffect(() => {
    if (location.pathname === match.url + '/skriningy') {
      dispatch(getClientScreeningsForReport(client));
    }
  }, [client, dispatch, match.url, location]);

  useEffect(() => {
    return () => {
      setDisplaySection(setFilterString('displaySection', SHOW_ALL));
      setSection(setFilterString('section', SHOW_ALL));
    };
  }, []);

  const getFilterString = (key) => {
    return JSON.parse(window.localStorage.getItem(key));
  };

  const setFilterString = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const sections = [
    { key: SHOW_ALL, label: SHOW_ALL },
    { key: strings.child, label: strings.child },
    { key: strings.gdpr, label: strings.gdpr },
    { key: strings.family, label: strings.family },
    { key: strings.pregnancyAndBirth, label: strings.pregnancyAndBirth },
    { key: strings.breastFeeding, label: strings.breastFeeding },
    { key: strings.psychomotoricsGrouwth, label: strings.psychomotoricsGrouwth },
    { key: strings.generalHealth, label: strings.generalHealth },
    { key: strings.worries, label: strings.worries },
    { key: strings.living, label: strings.living },
    { key: strings.kindergarden, label: strings.kindergarden },
  ];

  const onSectionChange = ({ key }) => {
    const label = sections.find((section) => section.key === key).label;
    setSection(key);
    setDisplaySection(label);

    setFilterString('section', key);
    setFilterString('displaySection', label);
  };

  const deactivateFullDescription = <div className="descriptionPopup">{client.otherReason}</div>;

  const scrollWin = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderStatsSubmenu = () => {
    return (
      <div className="submenu">
        <Button
          type="secondary"
          icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
          label={strings.goBack}
          className="go-back-button"
          onClick={history.goBack}
        />
        <ul>
          {subMenuItems.map((item, i) => (
            <li key={i}>
              <NavLink
                className={item.path === 'lekcie' && window.location.pathname === match.url ? 'active' : null}
                activeClassName="active"
                data-test-id={`clientMenuPhoto-${item.text}-desktop`}
                to={`${match.url}/${item.path}`}
                replace
              >
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderStatsSubmenuMobile = () => {
    return subMenuItems.map((item, i) => (
      <NavLink
        key={i}
        className="admin-omama-tabsMobile_row"
        onClick={() => scrollWin()}
        data-test-id={`clientMenuPhoto-${item.text}-mobile`}
        to={`${match.url}/${item.path}`}
      >
        <span>{item.text}</span>
        <ArrowRightOutlined />
      </NavLink>
    ));
  };

  const searchActionsAndChangeDate = () => (date) => {
    dispatch(adminClientChangeDate(date));
    const from = date?.[0];
    const to = date?.[1];
    (from || to) && dispatch(getAdminClientActionsAction(match.params.clientId, from, dayjs(to).add(1, 'days')));
  };

  const changeActionDate = () => (date) => {
    dispatch(adminClientChangeDate(date));
  };

  const searchActions = () => dispatch(getAdminClientActionsAction(match.params.clientId, clientFrom, clientTo));

  const searchPreviousYear = () => {
    dispatch(
      adminClientChangeDate([dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')]),
    );
    dispatch(
      getAdminClientActionsAction(
        match.params.clientId,
        dayjs().subtract(1, 'year').startOf('year'),
        dayjs().subtract(1, 'year').endOf('year'),
      ),
    );
  };

  // MODALOVSKE veci
  const changeActionDateState = (value) => {
    if (value && value.target && value.target.value !== undefined) {
      setActionDate(value.target.value);
    } else {
      setActionDate(value);
    }
  };

  const changeActionTimeState = (value) => {
    if (value && value.target && value.target.value !== undefined) {
      setActionTime(value.target.value);
    } else {
      setActionTime(value);
    }
  };

  const showModalAdd = () => {
    setVisibleModalAdd(true);
  };

  const handleCancelModalAdd = () => {
    setVisibleModalAdd(false);
    setActionDate('');
    setActionTime(dayjs());
  };

  const addAction = async () => {
    dispatch(setLoading(true));

    const time = dayjs(actionTime).format('HH:mm');
    const date = dayjs(actionDate + 'T' + time).format();

    const body = {
      date,
      client: true,
      id: match.params.clientId,
      omamaId: client.omamaID,
      status: 'active',
      differentAction: '',
      timeSpent: 1.2,
    };

    const from = clientFrom || dayjs().startOf('year').format('YYYY-MM-DD');
    const to = clientTo || dayjs().endOf('year').format('YYYY-MM-DD');

    try {
      await axios.post(api.adminAddNewAction, body);
      if (dayjs(actionDate).isBetween(from, to, 'years', '[]')) {
        dispatch(getAdminClientActionsAction(match.params.clientId, from, to));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      setVisibleModalAdd(false);
      setActionDate('');
      setActionTime(dayjs());
    }
  };

  const showDetailAction = (action) => {
    setDetailAction(action);
  };

  const detailActionOk = () => {
    setDetailAction(undefined);
  };

  const detailActionCancel = () => {
    setDetailAction(undefined);
  };

  const deleteActionHandler = (id) => {
    dispatch(
      deleteAction(id, true, () => {
        detailActionCancel();
      }),
    ).then(() => {
      dispatch(
        getAdminClientActionsAction(
          match.params.clientId,
          clientFrom || dayjs().startOf('year').format('YYYY-MM-DD'),
          clientTo || dayjs().endOf('year').format('YYYY-MM-DD'),
        ),
      );
    });
  };

  const renderLekcie = () => {
    return (
      <div>
        <div className="adminClient-form clientDesktopView">
          <div className="adminClient-form-group">
            <RangePicker
              onChange={changeActionDate()}
              name="birthDate"
              defaultValue={[null, null]}
              value={[clientFrom, clientTo]}
              className="adminClient_datepicker"
              placeholder={['Od', 'Do']}
            />
            <Button
              type="secondary"
              className="new-table-control-button"
              onClick={() => searchActions()}
              disabled={!clientTo || !clientTo}
            >
              {strings.search}
            </Button>
            <Button type="secondary" className="new-table-control-button" onClick={() => searchPreviousYear()}>
              {strings.lastYear}
            </Button>
          </div>
          <div className="adminClient-form-add-button">
            <Button className="new-table-control-button" onClick={() => showModalAdd()}>
              {strings.addLesson}
            </Button>
          </div>
        </div>
        <Modal
          title={strings.addLesson}
          open={visibleModalAdd}
          onCancel={handleCancelModalAdd}
          cancelText={strings.close}
          onOk={addAction}
          okText={strings.confirm}
          okButtonProps={{
            disabled: !(typeof actionDate === 'string') || actionTime === null,
          }}
        >
          <div>
            <div className="pridatContainer">
              <div className="typeDropdown"></div>
              <br />
              <div className="addNewActionPicker">
                <h3 className="pickerHeader">{strings.date}</h3>
                <Input type="date" value={actionDate} onChange={changeActionDateState} className="picker" />
              </div>
              <div className="addNewActionPicker">
                <h3 className="pickerHeader">{strings.time}</h3>
                <br />
                <TimePicker
                  defaultValue={dayjs('12:00', 'HH:mm')}
                  type="time"
                  format={'HH:mm'}
                  minuteStep={15}
                  needConfirm={false}
                  onChange={changeActionTimeState}
                  value={actionTime}
                  className="picker"
                  placeholder={strings.chooseTime}
                />
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>

        <Table
          className="new-table clientDesktopView clientLessonsDesktopTable"
          rowClassName={(record) => (record.statusForTable === strings.closedByClient ? 'grayColor' : 'inherit')}
          rowKey="_id"
          dataSource={clientActions}
          columns={columns}
          pagination={{ pageSize: 20 }}
          onRow={(record) => ({
            onClick: () => showDetailAction(record),
          })}
        />
        <Modal
          centered
          styles={{ body: { padding: '24px 16px' } }}
          title="Detail"
          open={detailAction !== undefined}
          onCancel={detailActionCancel}
          onOk={detailActionOk}
          footer={null}
        >
          {detailAction && (
            <ActionDetailAdmin
              action={detailAction}
              client={client}
              fullprops={{ client, deleteAction }}
              deleteAction={deleteActionHandler}
            />
          )}
        </Modal>
      </div>
    );
  };

  const renderLekcieMobile = () => {
    return (
      <div className="lessonsMobileView">
        <div className="container">
          <div className="goBack backFromLessons" onClick={() => history.goBack()}>
            <LeftOutlined />
            <span>{strings.back1}</span>
          </div>
          <div className="container-inner">
            <div className="container-inner-options">
              <div className="container-inner-options-rangePicker">
                <RangePicker
                  onChange={searchActionsAndChangeDate()}
                  defaultValue={[null, null]}
                  value={[clientFrom, clientTo]}
                  style={{ transform: 'scale(0.8)' }}
                  placeholder={['Od', 'Do']}
                />
              </div>
              <div className="container-inner-options-monthPicker">
                <Button className="adminClient-button" onClick={() => searchPreviousYear()}>
                  {strings.lastYear}
                </Button>
              </div>
              <div className="container-inner-options-addAction" onClick={() => showModalAdd()}>
                <img className="ikona pridatButton" src="/images/Icons/plus_icon.png" alt="ikona" />
              </div>
            </div>
            <div className="container-inner-toDoList">
              {clientActions
                .sort((a, b) => dayjs(b.date).diff(a.date))
                .map((lesson) => {
                  return (
                    <div
                      key={lesson._id}
                      className="container-inner-toDoList-toDo"
                      onClick={() => showDetailAction(lesson)}
                    >
                      <div className="container-inner-toDoList-toDo-date">
                        {dayjs(lesson.date).format('D. M. YYYY')}
                      </div>
                      <div className="container-inner-toDoList-toDo-time">{dayjs(lesson.date).format('HH:mm')}</div>
                      <div className="container-inner-toDoList-toDo-title">{lesson.statusForTable}</div>
                      <div className="container-inner-toDoList-toDo-arrow">
                        <RightOutlined className="icon" />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const calculateAttendanceTime = (startDate, endDate) => {
    let totalTime;
    if (endDate === '' || endDate === 'Invalid date') totalTime = dayjs().diff(dayjs(startDate), 'months');
    else totalTime = dayjs(endDate).diff(dayjs(startDate), 'months');
    const years = Math.floor(totalTime / 12);
    const months = totalTime % 12;

    let attendanceTime = '';
    if (years === 1) attendanceTime += `${years} ${strings.year.toLowerCase()} `;
    else if (years >= 2 && years <= 4) attendanceTime += `${years} ${strings.years} `;
    else attendanceTime += `${years} ${strings.years1} `;

    if (months === 1) attendanceTime += `${months} ${strings.oneMonth}`;
    else if (months >= 2 && months <= 4) attendanceTime += `${months} ${strings.twoTillFourMonths}`;
    else attendanceTime += `${months} ${strings.zeroOrFiveMonths}`;

    return attendanceTime;
  };

  const renderInfoMobile = () => {
    // const users = usersById;
    let clientInfo = {
      Dieťa: [
        {
          key: 'child',
          children: [
            {
              title: strings.nameAndSurname,
              data: `${client.firstName} ${client.lastName}`,
            },
            { title: strings.city, data: client.city },
            { title: strings.street, data: client.street },
            { title: strings.birthCity, data: client.birthDateAdress },
            {
              title: strings.birthDate,
              data: dayjs(client.birthDate).format('D. M. YYYY'),
            },
            {
              title: strings.plannedBirth,
              data: dayjs(client.gestacnyBirthDate).format('D. M. YYYY'),
            },
            {
              title: strings.inProgramFrom1,
              data: dayjs(client.inProgramFromDate).format('D. M. YYYY'),
            },
            {
              title: strings.preschoolClub,
              data: client.predskolskyKlub ? strings.yes : strings.no,
            },
            { title: strings.omama, data: usersById[client?.omamaID]?.name },
          ],
        },
      ],
      [strings.gdpr]: [
        {
          key: 'gdpr',
          children: [
            {
              title: strings.photosInApp,
              data: client.photosInApp ? strings.yes : strings.no,
            },
            {
              title: strings.photosOnWeb,
              data: client.photosOnWeb ? strings.yes : strings.no,
            },
            {
              title: strings.photosPrinted,
              data: client.photosPrinted ? strings.yes : strings.no,
            },
            {
              title: strings.videoReports,
              data: client.videoReports ? strings.yes : strings.no,
            },
            {
              title: strings.pediatricScreenings,
              data: client.pediatricScreenings ? strings.yes : strings.no,
            },
            {
              title: strings.growthTracking,
              data: client.growthTracking ? strings.yes : strings.no,
            },
            {
              title: strings.educationCarrierGrowth,
              data: client.educationCarrierGrowth ? strings.yes : strings.no,
            },
            {
              title: strings.contactLater,
              data: client.contactLater ? strings.yes : strings.no,
            },
          ],
        },
      ],
      Rodina: [
        {
          header: strings.mother,
          key: 'family',
          children: [
            { title: strings.nameAndSurname, data: client.motherName },
            {
              title: strings.birthDate,
              data: client.motherBirthDate && dayjs(client.motherBirthDate).format('DD. MM. YYYY'),
            },
            { title: strings.phoneNumber, data: client.motherPhone },
            { title: strings.healthState, data: client.motherHealthState },
            { title: strings.educationEnded, data: client.motherEducation },
            { title: strings.class, data: client.motherEducationLevel },
            { title: strings.job, data: client.motherWork },
          ],
        },
        {
          header: strings.father,
          key: 'family',
          children: [
            { title: strings.nameAndSurname, data: client.fatherName },
            {
              title: strings.birthDate,
              data: client.fatherBirthDate && dayjs(client.fatherBirthDate).format('DD. MM. YYYY'),
            },
            { title: strings.phoneNumber, data: client.fatherPhone },
            { title: strings.healthState, data: client.fatherHealthState },
            { title: strings.educationEnded, data: client.fatherEducation },
            { title: strings.class, data: client.fatherEducationLevel },
            { title: strings.job, data: client.fatherWork },
          ],
        },
        {
          header: strings.siblings,
          key: 'family',
          children: [
            { title: strings.numberOfSiblings, data: client.siblingsNumber },
            { title: strings.childIsBornInOrder, data: client.childBirthOrder },
            { title: strings.languages, data: client.language },
          ],
        },
      ],
      [strings.pregnancyAndBirth]: [
        {
          header: strings.pregnancy,
          key: 'pregnancy',
          children: [
            { title: strings.story, data: client.pregnancy },
            {
              title: strings.smokingDuringPregnancy,
              data: client.smokingPregnancy ? strings.yes : strings.no,
            },
            {
              title: strings.alcoholDuringPregnancy,
              data: client.alcoholPregnancy ? strings.yes : strings.no,
            },
            {
              title: strings.drugsDuringPregnancy,
              data: client.drugsPregnancy ? strings.yes : strings.no,
            },
            {
              title: strings.pillsDuringPregnancy,
              data: client.medicinePregrancy,
            },
          ],
        },
        {
          header: strings.birth,
          key: 'pregnancy',
          children: [
            {
              title: strings.inDueDate,
              data: client.birthInTerm ? strings.yes : strings.no,
            },
            {
              title: strings.beforeDueDate,
              data: client.prematureBirth ? strings.yes : strings.no,
            },
            {
              title: strings.afterDueDate,
              data: client.lateBirth ? strings.yes : strings.no,
            },
            {
              title: strings.artificialBirth,
              data: client.inducedBirth ? strings.yes : strings.no,
            },
            {
              title: strings.spontaniousBirth,
              data: client.spontaneousBirth ? strings.yes : strings.no,
            },
            {
              title: strings.sekcionBirth,
              data: client.csectionBirth ? strings.yes : strings.no,
            },
            {
              title: strings.natural,
              data: client.naturalBirth ? strings.yes : strings.no,
            },
            {
              title: strings.complicationsDuringBirth,
              data: client.childbirthComplications,
            },
            { title: strings.birthWeight2, data: client.birthWeight },
            { title: strings.birthLength1, data: client.birthLength },
            {
              title: strings.birthHeadCircumference,
              data: client.birthHeadCircumference,
            },
          ],
        },
      ],
      [strings.breastFeeding]: [
        {
          key: 'breastfeeding',
          children: [
            {
              title: strings.breatFedChild,
              data: client.breastFed ? strings.yes : strings.no,
            },
            {
              title: strings.lengthOfBreastFeeding,
              data: client.breastFeedTime,
            },
            { title: strings.solidFoodFromWhichMonth, data: client.solidFood },
            {
              title: strings.smokingDuringBreastFeeding,
              data: client.smokingBreastFeeding ? strings.yes : strings.no,
            },
            {
              title: strings.alcoholDuringBreastFeeding,
              data: client.alcoholBreastFeeding ? strings.yes : strings.no,
            },
            {
              title: strings.drugsDuringBreastFeeding,
              data: client.drugsBreastFeeding ? strings.yes : strings.no,
            },
            {
              title: strings.pillsDuringBreastFeeding,
              data: client.medicineBreastFeeding,
            },
          ],
        },
      ],
      [strings.psychomotoricsGrouwth]: [
        {
          key: 'growth',
          children: [
            { title: strings.rollingOver, data: client.growthRollingover },
            { title: strings.sitting, data: client.growthSitting },
            { title: strings.goingOnFour, data: client.growthCrawling },
            { title: strings.walking, data: client.growthWalking },
            { title: strings.chattering, data: client.growthCooing },
            { title: strings.firstWords, data: client.growthTalking },
          ],
        },
      ],
      [strings.generalHealth]: [
        {
          key: 'health',
          children: [
            {
              title: strings.smokingNearChildBelowTwoYears,
              data: client.smokingNearChildBelowTwoYears ? strings.yes : strings.no,
            },
            {
              title: strings.alcoholNearChildBelowTwoYears,
              data: client.alcoholNearChildBelowTwoYears ? strings.yes : strings.no,
            },
            {
              title: strings.drugsNearChildBelowTwoYears,
              data: client.drugsNearChildBelowTwoYears ? strings.yes : strings.no,
            },
            {
              title: strings.medicineNearChildBelowTwoYears,
              data: client.medicineNearChildBelowTwoYears,
            },
            { title: strings.hospitalization, data: client.hospitalization },
            { title: strings.injury, data: client.injury },
            { title: strings.healthIssues1, data: client.healthIssues },
            {
              title: strings.mandatoryVaccine,
              data: client.obligatoryVaccination ? strings.yes : strings.no,
            },
            {
              title: strings.regularExaminations,
              data: client.regularExaminations ? strings.yes : strings.no,
            },
            {
              title: strings.examinationOffice,
              data: client.examinationOffice,
            },
            {
              title: strings.dateOfLastExamination,
              data: client.dateOfLastExamination && dayjs(client.dateOfLastExamination).format('DD. MM. YYYY'),
            },
            { title: strings.healthCondition, data: client.healthCondition },
          ],
        },
      ],
      [strings.worries]: [
        {
          key: 'concerns',
          children: [
            {
              title: strings.eyesightIssueConcern,
              data: client.eyesightIssueConcern ? strings.yes : strings.no,
            },
            {
              title: strings.hearingIssueConcern,
              data: client.hearingIssueConcern ? strings.yes : strings.no,
            },
            {
              title: strings.sleepIssueConcern,
              data: client.sleepIssueConcern ? strings.yes : strings.no,
            },
            {
              title: strings.growNurtureIssueConcern,
              data: client.growNurtureIssueConcern ? strings.yes : strings.no,
            },
            {
              title: strings.healthIssueConcern,
              data: client.healthIssueConcern ? strings.yes : strings.no,
            },
            {
              title: strings.wholeGrowIssueConcern,
              data: client.wholeGrowIssueConcern ? strings.yes : strings.no,
            },
          ],
        },
      ],
      [strings.living]: [
        {
          key: 'housing',
          children: [
            { title: strings.materialOfHouse, data: client.houseMaterial },
            { title: strings.amountOfRooms, data: client.houseRooms },
            { title: strings.amountOfPeople, data: client.houseInhabitants },
            { title: strings.fuelUsed, data: client.houseFuel },
            {
              title: strings.kitchenAndSleep,
              data: client.houseSameRoomForChildAndKitchen ? strings.yes : strings.no,
            },
            {
              title: strings.ownBathroom,
              data: client.houseSeparateBathRoom ? strings.yes : strings.no,
            },
          ],
        },
      ],
      [strings.kindergarden]: [
        {
          key: 'kindergarten',
          children: [
            {
              title: strings.childAtemptKindergarden,
              data: client.visitingInstitution ? strings.yes : strings.no,
            },
            { title: strings.nameOfKindergarden, data: client.institutionName },
            {
              title: strings.lengthAttendedSchool,
              data:
                client.infantSchoolStartDate &&
                calculateAttendanceTime(client.infantSchoolStartDate, client.infantSchoolEndDate),
            },
          ],
        },
      ],
      [strings.elementaryschool]: [
        {
          key: 'elementarySchool',
          children: [
            {
              title: strings.childAtemptPrimarySchool,
              data: client.elementarySchool ? strings.yes : strings.no,
            },
            {
              title: strings.namePrimarySchool,
              data: client.elementarySchoolName,
            },
            {
              title: strings.typePrimarySchool,
              data: client.specialSchool
                ? strings.specialSchool
                : client.elementarySchool
                  ? strings.ordinarySchool
                  : '',
            },
            {
              title: strings.lengthAttendedSchool,
              data:
                client.elementarySchoolStartDate &&
                calculateAttendanceTime(client.elementarySchoolStartDate, client.elementarySchoolEndDate),
            },
          ],
        },
      ],
    };

    return (
      <div className="admin-main">
        <div className="infoContainer">
          <div className="infoContainer-topBar">
            <div className="goBack" onClick={() => history.goBack()}>
              <LeftOutlined />
              <span>{strings.back1}</span>
            </div>
            <Dropdown
              trigger={['click']}
              className="infoContainer-topBar-sectionsDropdown"
              menu={{ items: sections, onClick: onSectionChange }}
            >
              <div className="usersDropdownValue">
                {displaySection} <DownOutlined />
              </div>
            </Dropdown>
          </div>
          {clientInfo &&
            Object.keys(clientInfo).map((key) => {
              return (
                <div key={key}>
                  <div className={section === strings.showAll ? '' : section === key ? '' : 'unfiltered'}>
                    <h3 className="infoContainer-sectionHeader">{key}</h3>
                    {clientInfo[key].map((info, i) => {
                      return (
                        <div key={i}>
                          <h4 className="infoContainer-subsectionHeader">{info?.header}</h4>
                          {info.children.map((child, c) => {
                            return (
                              <div key={c}>
                                <div className="infoContainer-title">{child.title}</div>
                                <div className="infoContainer-data">{child.data}</div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderMilestones = () => {
    return <ClientMilestones client={client} interNDA={interNDA} preschoolScreening={preschoolScreening} />;
  };

  const renderMilestonesMobile = () => {
    return <ClientMilestones client={client} interNDA={interNDA} preschoolScreening={preschoolScreening} />;
  };

  const showScreeningDetail = (screening) => {
    if (screening.number >= 11 && !isEmpty(screening.originalAnswers)) {
      setScreeningDetail(screening);
      setScreeningDetailVisible(true);
    }
  };

  const screeningDetailCancel = () => {
    setScreeningDetail(undefined);
    setScreeningDetailVisible(false);
  };

  const renderSkriningy = () => {
    return (
      <div>
        <div className="clientInfo clientDesktopView">
          <Table
            dataSource={screeningReport}
            className="new-table clientScreeningTable"
            pagination={{ pageSize: 12 }}
            onRow={(record) => ({
              onClick: () => showScreeningDetail(record),
            })}
          >
            <Column title="č.PP" align="center" dataIndex="number" key="number" />
            <Column title={strings.date} align="center" dataIndex="date" key="date" />
            <Column
              title={
                <>
                  <div>{strings.psychical}/</div>
                  <div>{strings.age3}</div>
                </>
              }
              align="center"
              dataIndex="age"
              key="age"
            />
            <Column
              title={strings.motorics}
              align="center"
              dataIndex="motorics"
              key="motorics"
              render={(motorics) => (
                <span>
                  {Object.keys(motorics).map((item) => {
                    let color = motorics[item] ? 'green' : 'red';
                    return (
                      <Tag color={color} key={item}>
                        {item}
                      </Tag>
                    );
                  })}
                </span>
              )}
            />
            <Column
              title={strings.adaptiveBehaviour}
              align="center"
              dataIndex="adaptive_behaviour"
              key="adaptive_behaviour"
              render={(adaptive_behaviour) => (
                <span>
                  {Object.keys(adaptive_behaviour).map((item) => {
                    let color = adaptive_behaviour[item] ? 'green' : 'red';
                    return (
                      <Tag color={color} key={item}>
                        {item}
                      </Tag>
                    );
                  })}
                </span>
              )}
            />
            <Column
              title={strings.communication}
              align="center"
              dataIndex="communication"
              key="communication"
              render={(communication) =>
                Object.keys(communication).map((item) => {
                  let color = communication[item] ? 'green' : 'red';
                  return (
                    <Tag color={color} key={item}>
                      {item}
                    </Tag>
                  );
                })
              }
            />
            <Column
              title={
                <>
                  <div>{strings.score1}</div>
                  <div>{strings.points2}</div>
                </>
              }
              align="center"
              dataIndex="development_score"
              key="development_score"
              render={(development_score) => (
                <span>
                  <Tag color={development_score.pasmo}>{development_score.body}</Tag>
                </span>
              )}
            />
            <Column
              title={strings.specificBehaviour}
              align="center"
              dataIndex="specific_behaviour"
              key="specific_behaviour"
              render={(specific_behaviour) => (
                <span>
                  {Object.keys(specific_behaviour).map((item) => {
                    let color = specific_behaviour[item] ? 'green' : 'red';
                    return (
                      <Tag color={color} key={item}>
                        {item}
                      </Tag>
                    );
                  })}
                </span>
              )}
            />
            <Column
              title={
                <>
                  <div>{strings.specificBehaviour}</div>
                  <div>{strings.points2}</div>
                </>
              }
              align="center"
              dataIndex="specific_behaviour_score"
              key="specific_behaviour_score"
              render={(specific_behaviour_score) => (
                <span>
                  <Tag color={specific_behaviour_score.pasmo}>{specific_behaviour_score.body}</Tag>
                </span>
              )}
            />
            <Column
              title={strings.worries}
              align="center"
              dataIndex="worries"
              key="worries"
              render={(worries) => (
                <span>
                  {Object.keys(worries).map((item) => {
                    let color = worries[item] ? 'green' : 'red';
                    return (
                      <Tag color={color} key={item}>
                        {item}
                      </Tag>
                    );
                  })}
                </span>
              )}
            />
            <Column
              title={
                <>
                  <div>{strings.worries}</div>
                  <div>{strings.points2}</div>
                </>
              }
              align="center"
              dataIndex="worries_score"
              key="worries_score"
              render={(worries_score) => (
                <span>
                  <Tag color={worries_score.pasmo}>{worries_score.body}</Tag>
                </span>
              )}
            />
          </Table>
          <Modal
            title={strings.detailOfScreening}
            open={screeningDetailVisible}
            onCancel={screeningDetailCancel}
            onOk={screeningDetailCancel}
            footer={null}
            width={'80%'}
          >
            {screeningDetail && <ScreeningDetailAdmin screening={screeningDetail} />}
          </Modal>
        </div>
        {client.gestacnyBirthDate && (
          <div className="admin-client-screenings">
            <ScreeningTable
              strings={strings}
              clientBirthdate={client.gestacnyBirthDate}
              screenings={screenings}
              initialScreenings={initialScreenings}
              toScreening={(data) => navigateToScreeningForm(data.screeningType, client?._id)}
            />
          </div>
        )}
      </div>
    );
  };

  const navigateToScreeningForm = (screeningType, clientID) => {
    changePage('/admin/screening/' + screeningType + '/klient/' + clientID);
  };

  const navigateToScreeningResults = (screeningType, screeningID, clientID) => {
    changePage('/admin/screening/' + screeningType + '/' + screeningID + '/klient/' + clientID);
  };

  const toScreening = (data) => {
    const { screeningType, screeningID, initialDate } = data;

    isAdmin && initialDate
      ? navigateToScreeningResults(screeningType, screeningID, client?._id)
      : navigateToScreeningForm(screeningType, client?._id);
  };

  const renderSkriningyMobile = () => {
    return (
      <div className="admin-main">
        <div className="screeningsContainer">
          <div className="goBack" onClick={() => history.goBack()}>
            <LeftOutlined />
            <span>{strings.back1}</span>
          </div>
          {client.gestacnyBirthDate && (
            <ScreeningTable
              strings={strings}
              clientBirthdate={client.gestacnyBirthDate}
              screenings={screenings}
              initialScreenings={initialScreenings}
              toScreening={toScreening}
            />
          )}
        </div>
      </div>
    );
  };

  const renderMeasurements = () => {
    return (
      <div>
        <div className="clientInfo clientDesktopView">
          <div className="measurementTitleFirst" data-test-id="client-measurements-interNDA-desktop">
            {strings.interNDAMeasurement}
          </div>
          <div className="clientInfo clientDesktopView clientMeasurement">
            {interNDA ? (
              <div>
                <div style={{ paddingTop: '20px' }}>
                  <Table
                    className="new-table measurement-info-table  clientDesktopView"
                    rowKey={(record) => record.date}
                    dataSource={[interNDA]}
                    columns={measurementColumns}
                    pagination={false}
                    size="large"
                    showHeader={true}
                    onRow={() => ({
                      onClick: () => changePage(`/admin/klient/${client._id}/merania/interNDA`),
                    })}
                  />
                </div>
                <div>
                  <InterNDAScoreTable
                    cognitionScore={interNDA.cognition}
                    fineMotorScore={interNDA.fineMotor}
                    grossMotorScore={interNDA.grossMotor}
                    languageScore={interNDA.language}
                    positiveBehaviourScore={interNDA.positiveBehaviour}
                    negativeBehaviourScore={interNDA.negativeBehaviour}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="noMeasurement">{strings.noMeasurementMessage}</div>
                {userRole === 'admin' && (
                  <Button
                    className="adminClient-button"
                    onClick={() => changePage(`/admin/klient/${client._id}/merania/interNDA`)}
                  >
                    {strings.addMeasurement}
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="measurementTitle" data-test-id="client-measurements-preschoolScreening-desktop">
            {strings.preschoolScreening}
          </div>
          <div className="clientInfo clientDesktopView clientMeasurement">
            {preschoolScreening ? (
              <div>
                <div style={{ paddingTop: '20px' }}>
                  <Table
                    className="new-table measurement-info-table clientDesktopView"
                    rowKey={(record) => record.date}
                    dataSource={[preschoolScreening]}
                    columns={measurementColumns}
                    pagination={false}
                    size="large"
                    showHeader={true}
                    onRow={() => ({
                      onClick: () => changePage(`/admin/klient/${client._id}/merania/predskolsky-skrining`),
                    })}
                  />
                  <div>
                    <PreschoolScreeningResults preschoolScreeningAnswers={preschoolScreening.answers} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="noMeasurement">{strings.noMeasurementMessage}</div>
                {userRole === 'admin' && (
                  <Button
                    className="adminClient-button"
                    onClick={() => changePage(`/admin/klient/${client._id}/merania/predskolsky-skrining`)}
                  >
                    {strings.addMeasurement}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderMeasurementsMobile = () => {
    return (
      <div className="admin-main">
        <div className="measurements-container">
          <div className="goBack" onClick={() => history.goBack()}>
            <LeftOutlined />
            <span>{strings.back1}</span>
          </div>
          <div className="measurementTitleFirst" data-test-id="client-measurements-interNDA-mobile">
            {strings.interNDAMeasurement}
          </div>
          <div className="clientMobileView clientMeasurement">
            {interNDA ? (
              <div>
                <div className="measurement-header">
                  <span className="measurement-header-date">{strings.date}</span>
                  <span className="measurement-header-createdBy">{strings.createdBy}</span>
                  <span className="measurement-header-show"></span>
                </div>
                <div className="measurement-content">
                  <div className="measurement-content-date" data-test-id="client-measurements-inter-NDA-date-mobile">
                    {dayjs(interNDA.date).format('D. M. YYYY')}
                  </div>
                  <div
                    className="measurement-content-createdBy"
                    data-test-id="client-measurements-inter-NDA-createdBy-mobile"
                  >
                    {interNDA.createdBy}
                  </div>
                  <div
                    className="measurement-content-show"
                    onClick={() => changePage(`/admin/klient/${client._id}/merania/interNDA`)}
                  >
                    <div>
                      <span>{strings.show}</span>
                      <RightOutlined className="icon" />
                    </div>
                  </div>
                </div>
                <div>
                  <InterNDAScoreTable
                    cognitionScore={interNDA.cognition}
                    fineMotorScore={interNDA.fineMotor}
                    grossMotorScore={interNDA.grossMotor}
                    languageScore={interNDA.language}
                    positiveBehaviourScore={interNDA.positiveBehaviour}
                    negativeBehaviourScore={interNDA.negativeBehaviour}
                  />
                </div>
              </div>
            ) : (
              <div className="noMeasurementContainer">
                <div className="noMeasurement">{strings.noMeasurementMessage}</div>
                {userRole === 'admin' && (
                  <Button
                    className="adminClient-button"
                    onClick={() => changePage(`/admin/klient/${client._id}/merania/interNDA`)}
                  >
                    {strings.addMeasurement}
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="measurementTitle" data-test-id="client-measurements-preschoolScreening-mobile">
            {strings.preschoolScreening}
          </div>
          <div className="clientMobileView clientMeasurement">
            {preschoolScreening ? (
              <div>
                <div className="measurement-header">
                  <span className="measurement-header-date">{strings.date}</span>
                  <span className="measurement-header-createdBy">{strings.createdBy}</span>
                  <span className="measurement-header-show"></span>
                </div>
                <div className="measurement-content">
                  <div
                    className="measurement-content-date"
                    data-test-id="client-measurements-preschoolScreening-date-mobile"
                  >
                    {dayjs(preschoolScreening.date).format('D. M. YYYY')}
                  </div>
                  <div
                    className="measurement-content-createdBy"
                    data-test-id="client-measurements-preschoolScreening-createdBy-mobile"
                  >
                    {preschoolScreening.createdBy}
                  </div>
                  <div
                    className="measurement-content-show"
                    onClick={() => changePage(`/admin/klient/${client._id}/merania/predskolsky-skrining`)}
                  >
                    <div>
                      <span>{strings.show}</span>
                      <RightOutlined className="icon" />
                    </div>
                  </div>
                </div>
                <div>
                  <PreschoolScreeningResults preschoolScreeningAnswers={preschoolScreening.answers} />
                </div>
              </div>
            ) : (
              <div className="noMeasurementContainer">
                <div className="noMeasurement">{strings.noMeasurementMessage}</div>
                {userRole === 'admin' && (
                  <Button
                    className="adminClient-button"
                    onClick={() => changePage(`/admin/klient/${client._id}/merania/predskolsky-skrining`)}
                  >
                    {strings.addMeasurement}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const editClient = () => {
    dispatch(populateClientFormAction(client));
    replacePage('/admin/klient/upravit');
  };

  const showModal = () => {
    dispatch(handleDeactivationDate(dayjs().format()));
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showActivationModal = () => {
    if (!('deactivatedOnDate' in client)) {
      dispatch(handleDeactivationDate(''));
    }
    setActivationVisible(true);
  };

  const handleActivationCancel = () => {
    setActivationVisible(false);
  };

  const displayDeleteClient = () => {
    setShowDeleteClient(true);
  };

  const cancelDeleteClient = () => {
    setShowDeleteClient(false);
  };

  const deleteClient = async () => {
    const body = {
      id: client._id,
    };
    try {
      dispatch(setLoading(true));
      await axios.post(api.deleteClient, body);
      dispatch(
        getAdminClientsAction().then(() => {
          dispatch(setLoading(false));
          changePage('/admin/klienti');
        }),
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const changeDate = () => (date) => {
    dispatch(handleDeactivationDate(dayjs(date).format()));
  };

  const selectDeactivateOption = useCallback(
    ({ key }) => {
      dispatch(handleDeactivationOption(key));
    },
    [dispatch],
  );

  const handleTextArea = (event) => {
    setDeactivationDescription(event.target.value);
  };
  const handleDescription = useCallback(() => {
    if (deactivationDescription?.length > 3)
      dispatch(handleDeactivationDescription(client.deactivateReason, deactivationDescription));
  }, [deactivationDescription, client, dispatch]);

  const deactivateOptions = useCallback(() => {
    const options = Object.values(strings.deactivationOptions);
    return options.map((option) => ({
      key: option.key,
      label: option.description,
    }));
  }, []);

  const getDateObject = (date) => (date ? dayjs(date) : dayjs());

  const deactivateClient = async () => {
    const body = {
      id: client._id,
      set: {
        active: false,
        deactivatedOnDate: client.deactivatedOnDate,
        deactivateReason: client.deactivateReason,
        otherReason: client.otherReason,
      },
    };

    try {
      dispatch(setLoading(true));
      await axios.post(api.deactivateClient, body);
      return dispatch(getAdminClient(client._id));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const activateClient = async () => {
    setDeactivationDescription('');
    const body = {
      id: client._id,
      set: {
        active: true,
        deactivatedOnDate: null,
        deactivateReason: null,
        otherReason: null,
      },
    };

    try {
      dispatch(setLoading(true));
      await axios.post(api.deactivateClient, body);
      return dispatch(getAdminClient(client._id));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getFormatedAge = (date) => {
    const { months } = getDateDiff(date || new Date(), new Date());
    switch (true) {
      case months === 1:
        return `${months} ${strings.oneMonth}`;
      case months === 0 || months >= 5:
        return `${months} ${strings.zeroOrFiveMonths}`;
      case months >= 2 || months <= 4:
        return `${months} ${strings.twoTillFourMonths}`;
      default:
        return `${months} ${strings.zeroOrFiveMonths}`;
    }
  };

  const hideDetail = !match.isExact;

  if (client && !isEmpty(usersById)) {
    return (
      //REWORK background classNames
      <div className="statsBackground">
        <div className="adminBox">
          <div
            className={classNames('admin-main', {
              profileHidden: hideDetail,
            })}
          >
            <div className="admin-main-clientProfile">
              <div className="admin-main-clientProfile-inner">
                <div className="admin-client-panel">
                  <div className="mobileOnly goBack" onClick={() => history.goBack()}>
                    <LeftOutlined />
                    <span>{strings.back1}</span>
                  </div>
                  <div className="admin-client-panel-mobileContainer">
                    <Base64Image
                      className="admin-client-photo"
                      type="user"
                      imageID={client._id}
                      backUpImage="/images/cestavonLOGO_unofficial.png"
                    />
                    <div className="admin-client-panel-mobileContainer-inner">
                      <b className="admin-client-name">{`${client.firstName} ${client.lastName}`}</b>
                      <div className="admin-client-region desktopOnly">{client.region}</div>
                      <div className="admin-client-city desktopOnly">{client.city}</div>
                      <div className="admin-client-info">
                        <div className="admin-client-info-data">
                          <div className="clientInfo-title desktopOnly">{strings.age}:</div>
                          <div className="admin-client-age">{getFormatedAge(client.birthDate)}</div>
                        </div>
                        <div className="admin-client-info-data">
                          <div className="flexLayout">
                            <div className="clientInfo-title">{strings.inProgramFrom}</div>
                            <div className="admin-client-date clientInfo-data">
                              {dayjs(client.inProgramFromDate).format('D. M. YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className="admin-client-info-data">
                          <div className="clientInfo-title desktopOnly">{strings.omama}:</div>
                          <div className="admin-client-date desktopOnly">
                            {usersById[client.omamaID] ? usersById[client.omamaID].name : strings.omamaWasntFound}
                          </div>
                        </div>
                        <div className="admin-client-info-data">
                          <div className="flexLayout">
                            <div className="clientInfo-title">{strings.status}:</div>
                            <div className="admin-client-date desktopOnly">
                              {client.active
                                ? client.gender === strings.woman
                                  ? strings.activeUpperWoman
                                  : strings.activeUpperMan
                                : client.gender === strings.woman
                                  ? strings.nonActiveUpperWoman
                                  : strings.nonActiveUpperMan}
                            </div>
                            <div className="admin-client-date clientInfo-data mobileOnly">
                              {client.active
                                ? client.gender === strings.woman
                                  ? strings.activeUpperWoman
                                  : strings.activeUpperMan
                                : client.gender === strings.woman
                                  ? strings.nonActiveUpperWoman
                                  : strings.nonActiveUpperMan}
                            </div>
                          </div>
                        </div>
                        {!client.active && (
                          <>
                            <div className="admin-client-info-data">
                              <div className="flexLayout">
                                <div className="clientInfo-title">
                                  {client.active ? null : strings.notActiveFrom + ':'}
                                </div>
                                <div className="admin-client-date clientInfo-data">
                                  {client.active
                                    ? null
                                    : client.deactivatedOnDate
                                      ? dayjs(client.deactivatedOnDate).format('D. M. YYYY')
                                      : dayjs().format('D. M. YYYY')}
                                </div>
                              </div>
                              <div className="admin-client-info-data">
                                <div className="flexLayout">
                                  <div className="clientInfo-title">
                                    {client.active ? null : strings.deactivateReason + ':'}
                                  </div>
                                  <div className="admin-client-date clientInfo-data">
                                    {client.active
                                      ? null
                                      : client.deactivateReason && (
                                          // eslint-disable-next-line react/jsx-indent
                                          <div className="clientBox">
                                            {strings.deactivationOptions[client.deactivateReason].description + ' '}
                                            {client.deactivateReason === 'others' && (
                                              <Popover
                                                className="truncate"
                                                trigger="click"
                                                open={openPopUp}
                                                onOpenChange={(e) => setOpenPopUp(e)}
                                                placement="right"
                                                content={deactivateFullDescription}
                                              >
                                                <span style={{ cursor: 'pointer' }}>({client.otherReason})</span>
                                              </Popover>
                                            )}
                                          </div>
                                        )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="admin-client-info-data">
                          <div className="clientInfo-title desktopOnly">{strings.absolvedTogether}:</div>
                          <div className="admin-client-date desktopOnly">
                            {strings.lessons1}: {counts.lessons}
                          </div>
                          <div className="admin-client-date desktopOnly">
                            {strings.preschoolClubs2}: {counts.clubs}
                          </div>
                        </div>
                      </div>
                      {userRole === 'admin' && (
                        <div className="clientEditButtons desktopOnly">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Button className="clientEditButton" onClick={editClient} type="secondary">
                              {strings.editProfile}
                            </Button>
                            {client.active ? (
                              <Button className="clientEditButton" onClick={showModal} type="secondary">
                                {strings.deactivateProfile}
                              </Button>
                            ) : (
                              <Button className="clientEditButton" onClick={showActivationModal} type="secondary">
                                {strings.activateProfile}
                              </Button>
                            )}
                            <Button className="clientEditButton" onClick={() => displayDeleteClient()} type="secondary">
                              {strings.deleteClient}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mobileOtherInfo">
                    <div className="mobileOnly">
                      <img className="otherInfo-img" alt="Ikona miesta" src="/images/Icons/location_icon.svg" />
                      <span className="otherInfo-data">{client.city}</span>
                      {client.region && <span className="otherInfo-data otherInfo-data-region">, {client.region}</span>}
                    </div>
                    <div className="mobileOnly">
                      <span className="otherInfo-title">{strings.omama}:</span>
                      <span className="otherInfo-data">
                        {usersById[client.omamaID] ? usersById[client.omamaID].name : strings.omamaWasntFound}
                      </span>
                    </div>
                    <div className="mobileOnly otherInfo-title">{strings.absolved}:</div>
                    <div className="count-row mobileOnly">
                      <div className="count-row-cell">
                        <div className="count-row-cell-value">{counts.lessons}</div>
                        <span className="count-row-cell-label">lekcie</span>
                      </div>
                      <div className="count-row-cell">
                        <div className="count-row-cell-value">{counts.clubs}</div>
                        <span className="count-row-cell-label">{strings.preschoolClubs2.toLowerCase()}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {userRole === 'admin' && (
                  <div className="mobileOnly">
                    <div className="admin-editButtons clientMobileButton">
                      <div shape="round" onClick={editClient}>
                        {strings.edit}
                      </div>
                      <div shape="round" onClick={() => displayDeleteClient()}>
                        {strings.remove}
                      </div>
                    </div>
                    <div className="admin-editButtons clientMobileButton activateButton">
                      {client.active ? (
                        <div shape="round" onClick={showModal}>
                          {strings.deactivate}
                        </div>
                      ) : (
                        <div shape="round" onClick={showActivationModal}>
                          {strings.activate}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="admin-rightside clientDesktopView">
                <div>{renderStatsSubmenu()}</div>
                <div className="admin-clients-bottompanel">
                  <Switch>
                    <Route path={match.url + '/lekcie'}>{renderLekcie()}</Route>
                    <Route path={match.url + '/galeria'}>
                      <AdminClientTabGalleryDesktop client={client} match={match} />
                    </Route>
                    <Route path={match.url + '/info'}>
                      <AdminClientTabInfoDesktop
                        calculateAttendanceTime={calculateAttendanceTime}
                        descriptionColumns={descriptionColumns}
                        client={client}
                      />
                    </Route>
                    <Route path={match.url + '/milniky'}>{renderMilestones()}</Route>
                    <Route path={match.url + '/skriningy'}>{renderSkriningy()}</Route>
                    <Route path={match.url + '/merania'}>{renderMeasurements()}</Route>
                    <Route exact path={match.url}>
                      {renderLekcie()}
                    </Route>
                  </Switch>
                </div>
              </div>
              <Modal
                cancelText={strings.close}
                okText={strings.remove}
                title={strings.deleteClient1}
                open={showDeleteClient}
                onOk={() => deleteClient(cancelDeleteClient())}
                onCancel={cancelDeleteClient}
              >
                <p>{strings.deleteClientPermanentlyQuestion}</p>
              </Modal>
              <Modal
                cancelText={strings.cancel}
                okText={strings.activate}
                title={strings.activateClient}
                open={activationVisible}
                onOk={() => activateClient(handleActivationCancel())}
                onCancel={handleActivationCancel}
              >
                <p>Aktivovať klienta ?</p>
              </Modal>
              <div className="deactivateButtonModal">
                <Modal
                  cancelText={strings.cancel}
                  okText={strings.deactivate}
                  title={strings.deactivateClient}
                  open={visible}
                  onOk={() => deactivateClient(handleCancel())}
                  okButtonProps={{
                    disabled: !(client.deactivateReason === 'others'
                      ? deactivationDescription?.length > 3
                      : client.deactivateReason),
                  }}
                  onCancel={handleCancel}
                >
                  <DatePicker
                    allowClear={false}
                    placeholder={strings.deactivatedOnDate}
                    name="deactivatedOnDate"
                    onChange={changeDate('deactivatedOnDate')}
                    value={getDateObject(client.deactivatedOnDate)}
                  />

                  <span
                    className="deactivate-option-container"
                    data-test-id="admin-clientDeactivation-modal-reason-dropdown-mobile"
                  >
                    <Dropdown
                      menu={{ items: deactivateOptions(), onClick: selectDeactivateOption }}
                      trigger={['click']}
                      style={{ width: '100%' }}
                      placement="bottom"
                      className="table-control-dropdown deactivation-specification clients-table-dropdown"
                    >
                      <div>
                        <div
                          className={
                            client.deactivateReason
                              ? 'dropdown-value dropdown-deactivate '
                              : 'dropdown-value dropdown-deactivate dropdown-placeholder'
                          }
                        >
                          {client.deactivateReason
                            ? strings.deactivationOptions[client.deactivateReason].description
                            : strings.chooseOption}
                        </div>
                        <div className="dropdown-icon-container icon-deactivate">
                          <img src="/images/Icons/down-icon.svg" alt="Dropdown icon" className="icon-deactivate" />
                        </div>
                      </div>
                    </Dropdown>
                  </span>
                  {client.deactivateReason && strings.deactivationOptions[client.deactivateReason].key === 'others' ? (
                    <div className="descriptionContainer">
                      <TextArea
                        placeholder={strings.description}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        onChange={handleTextArea}
                        onBlur={handleDescription}
                        value={deactivationDescription}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Modal>
              </div>
              <div className="mobileOnly tabs">{renderStatsSubmenuMobile()}</div>
            </div>
          </div>
          <div className="clientMobileView">
            <Switch>
              <Route path={match.url + '/lekcie'}>{renderLekcieMobile()}</Route>
              <Route path={match.url + '/galeria'}>
                <AdminClientTabGalleryDesktop client={client} match={match} />
              </Route>
              <Route path={match.url + '/info'}>{renderInfoMobile()}</Route>
              <Route path={match.url + '/milniky'}>{renderMilestonesMobile()}</Route>
              <Route path={match.url + '/skriningy'}>{renderSkriningyMobile()}</Route>
              <Route path={match.url + '/merania'}>{renderMeasurementsMobile()}</Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  } else return <div></div>;
}

AdminClient.propTypes = {
  client: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  changePage: PropTypes.func,
  replacePage: PropTypes.func,
  screeningReport: PropTypes.arrayOf(PropTypes.object),
  clientFrom: PropTypes.object,
  clientTo: PropTypes.object,
  clientActions: PropTypes.arrayOf(PropTypes.object),
  counts: PropTypes.object,
  clients: PropTypes.arrayOf(PropTypes.object),
  screeningTests: PropTypes.object,
  getAllScreeningQuestions: PropTypes.func,
  interNDA: PropTypes.object,
  preschoolScreening: PropTypes.object,
  getAllClientMeasurements: PropTypes.func,
};

export default AdminClient;
