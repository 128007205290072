import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { DatePicker, Dropdown, Input, Modal, Select, Table, TimePicker } from 'antd';
import { DownOutlined, LeftCircleOutlined, LeftOutlined, RightCircleOutlined, RightOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

// Local imports
import { api } from '../../conf';
import { strings } from '../../strings/StringsProvider';
import {
  adminMentorChangeDate,
  changeMentorAction,
  changeNextMonthMentor,
  changePreviousMonthMentor,
  deleteMentorAction,
  getAdminMentorActionsAction,
} from '../../actions/mentor.actions';
import { getUsersAction } from '../../actions/users.actions';
import { setLoading } from '../../actions/status.actions';
import './MentorActions.scss';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dayjs from 'dayjs';
import { findLabelByKeyInDropdownMenu } from '../../tools/utils';

const { RangePicker } = DatePicker;

function MentorActions() {
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [actionDate, setActionDate] = useState('');
  const [actionTime, setActionTime] = useState(dayjs());
  const [omama, setOmama] = useState('');
  const [omamaKey, setOmamaKey] = useState('');
  const [actionKey, setActionKey] = useState('');
  const [actionName, setActionName] = useState(strings.chooseAction);
  const [timeSpent, setTimeSpent] = useState(1);
  const [differentAction, setDifferentAction] = useState('');
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleActionEdit, setVisibleActionEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [comment, setComment] = useState('');
  const [currentRecord, setCurrentRecord] = useState(null);
  const [editActionData, setEditActionData] = useState({});
  const [editActionDate, setEditActionDate] = useState(null);
  const [editActionTime, setEditActionTime] = useState(null);
  const { userId } = useParams();
  const mentorId = userId || jwtDecode(localStorage.getItem('access-token')).username;

  const mentorStartMonth = useSelector((state) => state.mentor.mentorStartMonth);
  const mentorEndMonth = useSelector((state) => state.mentor.mentorEndMonth);
  const mentorFrom = useSelector((state) => state.mentor.mentorFrom);
  const mentorTo = useSelector((state) => state.mentor.mentorTo);
  const omamas = useSelector((state) =>
    state.users.byArr.filter((user) => user.role === 'omama' && user.mentorID === mentorId),
  );
  const mentorActions = useSelector((state) =>
    state.mentor.mentorActions.map((action) => ({
      ...action,
      nameForTable: action.omama ? action.id + ' ' + action.omamaName : action.id,
    })),
  );

  const dispatch = useDispatch();
  const changePage = (location) => dispatch(push(location));

  useEffect(() => {
    if (mentorId) {
      dispatch(
        getAdminMentorActionsAction(
          mentorId,
          mentorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD'),
          dayjs(mentorEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
            dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
        ),
      );
    }
  }, [dispatch, mentorId, mentorStartMonth, mentorEndMonth]);

  const changeNextMonth = () => {
    dispatch(changeNextMonthMentor(mentorId, mentorStartMonth, mentorEndMonth));
  };

  const changePreviousMonth = () => {
    dispatch(changePreviousMonthMentor(mentorId, mentorStartMonth, mentorEndMonth));
  };

  const changeActionDate = (date) => {
    dispatch(adminMentorChangeDate(date));
  };

  const searchActionsAndChangeDate = (date) => {
    dispatch(adminMentorChangeDate(date));
    const from = date?.[0];
    const to = date?.[1];
    (from || to) && dispatch(getAdminMentorActionsAction(mentorId, from, dayjs(to).add(1, 'days')));
  };

  const searchActions = () => {
    dispatch(getAdminMentorActionsAction(mentorId, mentorFrom, dayjs(mentorTo).add(1, 'days')));
  };

  const onActionChange = ({ key }) => {
    setActionKey(key);
    setActionName(findLabelByKeyInDropdownMenu(key, actionOptions));
  };

  const actionOptions = [
    {
      key: 'group-mentoring',
      type: 'group',
      label: 'MENTORING:',
      children: [
        {
          key: 'mentoring',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-mentoring">
              {strings.mentoring}
            </span>
          ),
        },
        {
          key: 'phoneCall',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-phoneCall">
              {strings.phoneCall}
            </span>
          ),
        },
      ],
    },
    {
      key: 'group-otherActivities',
      type: 'group',
      label: `${strings.otherActions}:`,
      children: [
        {
          key: 'parentalClub',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-parentalClub">
              {strings.parentalClub1}
            </span>
          ),
        },
        {
          key: 'supervision',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-supervision">
              {strings.supervision}
            </span>
          ),
        },
        {
          key: 'training',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-training">
              {strings.training}
            </span>
          ),
        },
        {
          key: 'miu',
          label: <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-miu">{strings.miu}</span>,
        },
        {
          key: 'travel',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-travel">{strings.travel}</span>
          ),
        },
        {
          key: 'interNDA',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-interNDA">
              {strings.interNDA}
            </span>
          ),
        },
        {
          key: 'otherJob',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-otherJob">
              {strings.otherJob}
            </span>
          ),
        },
      ],
    },
    {
      key: 'group-vacation',
      type: 'group',
      label: 'NEPRÍTOMNOSŤ V PRÁCI:',
      children: [
        {
          key: 'vacation',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-vacation">
              {strings.vacation}
            </span>
          ),
        },
        {
          key: 'doctor',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-doctor">{strings.doctor}</span>
          ),
        },
        {
          key: 'familyDoctor',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-familyDoctor">
              {strings.familyDoctor}
            </span>
          ),
        },
        {
          key: 'workUnable',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-workUnable">
              {strings.workUnable}
            </span>
          ),
        },
        {
          key: 'OCR',
          label: <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-OCR">{strings.OCR}</span>,
        },
        {
          key: 'extraTimeOff',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-extraTimeOff">
              {strings.extraTimeOff}
            </span>
          ),
        },
        {
          key: 'other',
          label: (
            <span data-test-id="admin-mentorDetail-actions-addAction-actionType-option-other">{strings.other}</span>
          ),
        },
      ],
    },
  ];

  const changeState = (stateSetter) => (value) => {
    if (value && value.target && value.target.value !== undefined) {
      stateSetter(value.target.value);
    } else {
      stateSetter(value);
    }
  };

  const showModalAdd = () => {
    if (isEmpty(omamas)) {
      dispatch(getUsersAction());
    }
    setVisibleModalAdd(true);
  };

  const editAction = () => {
    const from = mentorFrom || mentorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD');

    const to =
      mentorTo?.add(1, 'days') ||
      dayjs(mentorEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
      dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD');

    let body = editActionData;
    if (editActionDate || editActionTime) {
      body.date =
        (editActionDate || currentRecord.date.split('T')[0]) +
        'T' +
        (dayjs(editActionTime).format().split('T')[1] || currentRecord.date.split('T')[1]);
    }

    dispatch(changeMentorAction(currentRecord?._id, body, currentRecord?.mentorID, from, to));

    setVisibleActionEdit(false);
    setEditActionTime(null);
    setEditActionDate(null);
    setEditActionData({});
    setCurrentRecord(null);
    setVisibleEdit(false);
  };

  const handleCancelModalAdd = () => {
    setVisibleModalAdd(false);
    setActionDate('');
    setActionTime(dayjs());
    setOmama('');
    setOmamaKey('');
    setActionKey('');
    setActionName(strings.chooseAction);
    setTimeSpent(1);
    setDifferentAction('');
    setComment('');
  };

  const checkButton = () => {
    if (actionKey === 'mentoring' || actionKey === 'phoneCall' || actionKey === 'otherMentoring') {
      if (isEmpty(actionDate) || isEmpty(actionTime) || isEmpty(actionName) || isEmpty(omamaKey)) {
        return true;
      }
    } else if (
      isEmpty(actionDate) ||
      actionName === strings.chooseAction ||
      isEmpty(actionTime) ||
      isEmpty(actionName)
    ) {
      return true;
    }
    return false;
  };

  const addAction = async () => {
    dispatch(setLoading(true));

    const time = dayjs(actionTime).format('HH:mm');
    const date = dayjs(actionDate + 'T' + time).format();

    const body = {
      date,
      omama: actionKey === 'mentoring' || actionKey === 'phoneCall' || actionKey === 'otherMentoring',
      id: actionName,
      mentorID: mentorId,
      omamaID: actionKey === 'mentoring' || actionKey === 'phoneCall' || actionKey === 'otherMentoring' ? omamaKey : '',
      omamaName: actionKey === 'mentoring' || actionKey === 'phoneCall' || actionKey === 'otherMentoring' ? omama : '',
      status: 'active',
      differentAction,
      comment,
      timeSpent: parseFloat(timeSpent),
    };

    const from = mentorFrom || mentorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD');
    const to = mentorTo
      ? dayjs(mentorTo).add(1, 'days')
      : dayjs(mentorEndMonth).add(1, 'days').format('YYYY-MM-DD') || dayjs().endOf('month').format('YYYY-MM-DD');
    try {
      await axios.post(api.adminAddNewMentorAction, body);
      if (dayjs(actionDate).isBetween(from, to, undefined, '[)')) {
        dispatch(getAdminMentorActionsAction(mentorId, from, to));
      }
      dispatch(setLoading(false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while creating new mentor action', error);
      dispatch(setLoading(false));
    } finally {
      setVisibleModalAdd(false);
      setActionDate('');
      setActionTime(dayjs());
      setOmama(strings.chooseOmama);
      setOmamaKey('');
      setActionKey('');
      setActionName(strings.chooseAction);
      setTimeSpent(1);
      setDifferentAction('');
      setComment('');
    }
  };

  const deleteActionOk = () => {
    const from = mentorFrom || mentorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD');

    const to =
      mentorTo?.add(1, 'days') ||
      dayjs(mentorEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
      dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD');

    dispatch(deleteMentorAction(currentRecord._id)).then(() =>
      dispatch(getAdminMentorActionsAction(currentRecord.mentorID, from, to)),
    );

    setVisibleDelete(false);
    setVisibleEdit(false);
    setCurrentRecord(null);
  };

  const changePageHandler = (url) => {
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    changePage(url);
  };

  const renderSelectOptions = () => {
    const { Option } = Select;
    return omamas.map((omama) => (
      <Option
        className="selectRow"
        key={omama._id}
        data-test-id={`admin-mentorDetail-actions-addAction-omamas-option-${omama._id}`}
      >
        {omama.name}
      </Option>
    ));
  };

  const handleChange = (value) => {
    const names = value
      .map((e) => {
        return omamas.find((x) => x._id === e).name;
      })
      .sort()
      .join(', ');
    setOmamaKey(value);
    setOmama(names);
  };

  const handleEditStart = (record) => {
    setVisibleEdit(true);
    setCurrentRecord(record);
  };

  const handleEditEnd = () => {
    setVisibleEdit(false);
    setCurrentRecord(null);
  };

  const columns = [
    {
      title: strings.date,
      dataIndex: 'date',
      key: 'date',
      width: '9vw',
      render: (date) => <span>{dayjs(date).format('D. M. YYYY')}</span>,
    },
    {
      title: strings.time,
      dataIndex: 'date',
      key: 'time',
      width: '8vw',
      render: (date) => <span>{dayjs(date).format('HH:mm')}</span>,
    },
    {
      title: strings.name,
      dataIndex: 'nameForTable',
      key: 'nameForTable',
      width: '18vw',
    },
    {
      title: strings.spentTime,
      dataIndex: 'timeSpent',
      key: 'timeSpent',
      width: '8vw',
    },
    {
      title: strings.comment,
      dataIndex: 'differentAction',
      key: 'comment',
      render: (value, row) => <div>{row.comment || row.differentAction}</div>,
    },
  ];

  return (
    <div>
      <div className="desktopView">
        <div className="adminClient-form">
          <div className="adminClient-form-group">
            <RangePicker
              onChange={changeActionDate}
              // onPanelChange
              name="omamaCourse"
              defaultValue={[null, null]}
              value={[mentorFrom, mentorTo]}
              className="adminClient_datepicker"
              placeholder={['Od', 'Do']}
            />
            <Button
              type="secondary"
              onClick={searchActions}
              disabled={!mentorFrom || !mentorTo}
              style={{ textWrap: 'nowrap' }}
              data-test-id="mentor-plan-dateSearch-searchButton"
            >
              {strings.search}
            </Button>
            <div className="admin-omama-actions-week__changeWeek">
              <div style={{ fontWeight: 'bold' }}>
                <LeftCircleOutlined
                  className="week-navigation-left big"
                  onClick={changePreviousMonth}
                  data-test-id="admin-mentorDetail-actions-previousMonth-button"
                />
              </div>
              <div className="monthData" style={{ padding: '0 5px', fontWeight: 'bold' }}>
                {dayjs(mentorEndMonth).format('MMMM YYYY')}
              </div>
              <div style={{ fontWeight: 'bold' }}>
                <RightCircleOutlined
                  className="week-navigation-right big"
                  onClick={changeNextMonth}
                  data-test-id="admin-mentorDetail-actions-nextMonth-button"
                />
              </div>
            </div>
          </div>

          <div className="adminClient-form-add-button">
            <Button
              style={{ marginLeft: '15px' }}
              className="adminClient-button"
              onClick={showModalAdd}
              data-test-id="admin-mentorDetail-actions-addAction-button"
            >
              {strings.addAction}
            </Button>
          </div>
        </div>

        <Table
          className="mentor-actions-table new-table"
          rowKey="_id"
          dataSource={mentorActions}
          columns={columns}
          pagination={{ pageSize: 20 }}
          onRow={(record) => ({
            onClick: () => handleEditStart(record),
          })}
        />
        <Modal
          centered
          title={strings.addAction}
          open={visibleModalAdd}
          onCancel={handleCancelModalAdd}
          cancelText={strings.close}
          cancelButtonProps={{ 'data-test-id': 'admin-mentorDetail-actions-addAction-close-button' }}
          onOk={addAction}
          okText={strings.confirm}
          okButtonProps={{
            disabled: checkButton(),
            'data-test-id': 'admin-mentorDetail-actions-addAction-confirm-button',
          }}
        >
          <div className="pridatContainer">
            <div className="addNewActionPicker">
              <h3 className="pickerHeader">{strings.date}</h3>
              <Input
                type="date"
                value={actionDate}
                onChange={changeState(setActionDate)}
                className="picker"
                data-test-id="admin-mentorDetail-actions-addAction-date-input"
              />
            </div>
            <div className="addNewActionPicker">
              <h3 className="pickerHeader">{strings.time}</h3>
              <br />
              <TimePicker
                defaultValue={dayjs('12:00', 'HH:mm')}
                type="time"
                format={'HH:mm'}
                minuteStep={15}
                onChange={changeState(setActionTime)}
                value={actionTime}
                needConfirm={false}
                onScrollCapture={false}
                className="picker"
                placeholder={strings.chooseTime}
                data-test-id="admin-mentorDetail-actions-addAction-time-input"
              />
            </div>
            <div className="adminOmama-spentTimePicker">
              <div>{strings.spentTime1}</div>
              <br />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  type="number"
                  id="timeSpent"
                  onChange={changeState(setTimeSpent)}
                  value={timeSpent}
                  step={0.5}
                  min={0}
                  data-test-id="admin-mentorDetail-actions-addAction-duration-input"
                />
                <div>h</div>
              </div>
            </div>
            <br />

            <div>
              <p className="actionHeader">{strings.chooseAction1}</p>
              <div className="dropdownSelect">
                <Dropdown
                  trigger={['click']}
                  menu={{ items: actionOptions, onClick: onActionChange }}
                  placement="bottom"
                >
                  <div data-test-id="admin-mentorDetail-actions-addAction-actionType-dropdown">
                    {actionName} <DownOutlined />
                  </div>
                </Dropdown>
              </div>

              <div className="activityFormField">
                <div>
                  <Input
                    placeholder={strings.comment}
                    value={comment}
                    onChange={changeState(setComment)}
                    data-test-id="admin-mentorDetail-actions-addAction-comment-input"
                  />
                </div>
              </div>
            </div>
            {(actionKey === 'mentoring' || actionKey === 'phoneCall' || actionKey === 'otherMentoring') && (
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  popupClassName="selectMenu"
                  className="SelectInputMulti"
                  placeholder={strings.chooseOmamas}
                  onChange={handleChange}
                  data-test-id="admin-mentorDetail-actions-addAction-omamas-dropdown"
                >
                  {renderSelectOptions()}
                </Select>
              </div>
            )}

            <br />
            <br />
          </div>
        </Modal>

        {visibleEdit ? (
          <Modal centered title={strings.editAction} open={visibleEdit} onCancel={handleEditEnd} footer={false}>
            <div className="changeActionContainer">
              <Button
                shape="round"
                //onClick={() => this.setState({ visibleActionEdit: true })}
                onClick={() => setVisibleActionEdit(true)}
                data-test-id="admin-mentorDetail-actions-editAction-button"
              >
                {strings.editAction}
              </Button>
              <Button
                shape="round"
                onClick={() => setVisibleDelete(true)}
                data-test-id="admin-mentorDetail-actions-deleteAction-button"
              >
                {strings.deleteAction}
              </Button>
            </div>
            <Modal
              title={strings.editAction}
              open={visibleActionEdit}
              onCancel={() => setVisibleActionEdit(false)}
              cancelText={strings.cancel}
              onOk={editAction}
              okText={strings.confirm}
            >
              <div className="editActionAdmin">
                <label>{strings.date}</label>
                <Input
                  type="date"
                  defaultValue={dayjs(currentRecord?.date).format('YYYY-MM-DD')}
                  onChange={(e) => setEditActionDate(e.target.value)}
                  className="picker admin-input"
                />

                <label>{strings.time}</label>
                <TimePicker
                  type="time"
                  format={'HH:mm'}
                  minuteStep={15}
                  needConfirm={false}
                  onChange={(e) => setEditActionTime(e)}
                  defaultValue={dayjs(currentRecord?.date)}
                  className="picker admin-input"
                  placeholder={strings.chooseTime}
                />

                <label>{strings.timeSpent}</label>
                <div className="timeSpentEdit">
                  <Input
                    type="number"
                    id="timeSpent"
                    // onChange={e =>
                    //   this.setState({
                    //     editActionData: {
                    //       ...this.state.editActionData,
                    //       timeSpent: Number(e.target.value)
                    //     }
                    //   })
                    // }
                    onChange={(e) =>
                      setEditActionData({
                        ...editActionData,
                        timeSpent: Number(e.target.value),
                      })
                    }
                    defaultValue={currentRecord?.timeSpent}
                    step={0.5}
                    min={0}
                    className="timeSpentInput"
                  />
                  <span>h</span>
                </div>

                <label>{strings.comment}</label>
                <textarea
                  placeholder={strings.comment}
                  defaultValue={currentRecord?.comment || ''}
                  className="changeCommentInput"
                  // onChange={e =>
                  //   this.setState({
                  //     editActionData: {
                  //       ...this.state.editActionData,
                  //       comment: e.target.value
                  //     }
                  //   })
                  // }
                  onChange={(e) =>
                    setEditActionData({
                      ...editActionData,
                      comment: e.target.value,
                    })
                  }
                />
              </div>
            </Modal>
            <Modal
              centered
              title={strings.deleteAction}
              open={visibleDelete}
              onCancel={() => setVisibleDelete(false)}
              onOk={deleteActionOk}
            >
              {strings.question}
            </Modal>
          </Modal>
        ) : null}
      </div>
      <div className="mentorPlanMobileView">
        <div className="container">
          <div className="container-inner">
            <div className="container-inner-options">
              <div className="container-inner-options-rangePicker">
                <RangePicker
                  onChange={searchActionsAndChangeDate}
                  name="omamaCourse"
                  defaultValue={[null, null]}
                  value={[mentorFrom, mentorTo]}
                  style={{ transform: 'scale(0.8)' }}
                  placeholder={['Od', 'Do']}
                />
              </div>
              <div className="container-inner-options-monthPicker">
                <div className="container-inner-options-monthPicker-icon">
                  <LeftOutlined onClick={changePreviousMonth} />
                </div>
                <div className="container-inner-options-monthPicker-text">
                  {dayjs(mentorEndMonth).format('MMMM YYYY')}
                </div>
                <div className="container-inner-options-monthPicker-icon">
                  <RightOutlined onClick={changeNextMonth} />
                </div>
              </div>
              <div className="container-inner-options-addAction" onClick={() => showModalAdd()}>
                <img className="ikona pridatButton" src="/images/Icons/plus_icon.png" alt="ikona" />
              </div>
            </div>
            <div className="container-inner-toDoList">
              {mentorActions
                .sort((a, b) => dayjs(b.date).diff(a.date))
                .map((toDo) => {
                  return (
                    <div
                      key={toDo._id}
                      className="container-inner-toDoList-toDo"
                      onClick={() => changePageHandler(`/admin/plan/akcia/${toDo._id}`)}
                    >
                      <div className="container-inner-toDoList-toDo-date">{dayjs(toDo.date).format('D. M. YYYY')}</div>
                      <div className="container-inner-toDoList-toDo-time">{dayjs(toDo.date).format('HH:mm')}</div>
                      <div className="container-inner-toDoList-toDo-title">{toDo.nameForTable}</div>
                      <div className="container-inner-toDoList-toDo-arrow">
                        <RightOutlined className="icon" />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MentorActions;
