import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../../strings/StringsProvider';

function StatisticsClients({ renderAgeGroups, clientsByBirthDate }) {
  return (
    <div className="clientsStats" data-test-id="statistics-clients-stats">
      <h4 className="clientsStats-header">{strings.ageGroups}</h4>
      <div className="ageGroups">{renderAgeGroups(clientsByBirthDate)}</div>
    </div>
  );
}

StatisticsClients.propTypes = {
  renderAgeGroups: PropTypes.func.isRequired,
  clientsByBirthDate: PropTypes.array.isRequired,
};

export default StatisticsClients;
