import {
  RESET_REPORT,
  RESET_REPORTS,
  GET_OMAMA_REPORT,
  RESET_OMAMA_REPORT,
  ADD_NEW_REPORT_DATA,
  GET_OMAMA_REPORT_STATISTICS,
  GET_MENTOR_REPORT_STATISTICS,
  GET_SUPERVISOR_REPORT_STATISTICS,
} from '../reducers/report.reducer';
import { orderBy, uniqBy, keyBy } from 'lodash';
import { groupActions } from '../tools/action.tools';
import axios from 'axios';
import dayjs from 'dayjs';

// Local imports
import { api } from '../conf';
import { setLoading } from './status.actions';
import {
  accessoryStatusMap,
  employmentTypesMap,
  omamaLevelMap,
  providedByMap,
  nonClientActionsMap,
  parentEngagementAnswerMap,
} from '../data/enums';
import { getMonthWorkingDays } from '../tools/date.tools';
import { strings } from 'strings/StringsProvider';
import { MAX_LIMIT } from 'conf/api';
import { showErrorMessage } from 'tools/errorHandling';

export const getTimeReportAction = (date, reportName) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    let reportTypeUrl;

    switch (reportName) {
      case 'omamasTimeReport':
        reportTypeUrl = 'omamas';
        break;
      case 'mentorsTimeReport':
        reportTypeUrl = 'mentors';
        break;
      case 'supervisorsTimeReport':
        reportTypeUrl = 'supervisors';
        break;
      default:
        reportTypeUrl = '';
        dispatch(setLoading(false));
        return false;
    }

    try {
      const { data } = await axios.get(
        api.getWorkSchedule(
          date.startOf('month').format('YYYY-MM-DD'),
          date.endOf('month').format('YYYY-MM-DD'),
          reportTypeUrl,
        ),
      );
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: data.data,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const resetReportsAction = () => {
  return { type: RESET_REPORTS };
};

export const getOmamaReportAction = (start, end) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(api.getOmamaReport(start, end));
      dispatch({
        type: GET_OMAMA_REPORT,
        payload: {
          month: dayjs(start).format('MMMM'),
          report: data.data,
        },
      });
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const resetOmamaReportAction = () => {
  return { type: RESET_OMAMA_REPORT };
};

export const getUsersReportAction = (body, reportName) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.post(api.getUsersReport, body);
      for (let user of data.data) {
        user['created'] = dayjs(user['created']).format('DD. MM. YYYY HH:mm');
        user['lastModified'] = user['lastModified']
          ? dayjs(user['lastModified']).format('DD. MM. YYYY HH:mm')
          : user['created'];
        user['deactivationDate'] =
          user['deactivationDate'] && dayjs(user['deactivationDate']).format('DD. MM. YYYY HH:mm');
        user['phoneNumber'] = `'${user['phoneNumber']}'`;

        if (user.role === 'omama') {
          user['onLevelSince'] = user['onLevelSince']
            ? dayjs(user['onLevelSince']).format('DD. MM. YYYY')
            : user['created'];
          user['level'] = omamaLevelMap[user['level']];
          user['employmentType'] = employmentTypesMap[user['employmentType']];
        }
      }
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: data.data,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getClientsReportAction = (body, reportName) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.post(api.getClientsReport, body);
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: data.data,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

const calculateClientAge = (clientBirthDay, actionDate) => {
  const months = dayjs(actionDate).diff(dayjs(clientBirthDay), 'months');
  switch (true) {
    /* eslint-disable */
    case months == 1:
      return `${months} ${strings.oneMonth}`;
    case months == 0 || months >= 5:
      return `${months} ${strings.zeroOrFiveMonths}`;
    case months >= 2 || months <= 4:
      return `${months} ${strings.twoTillFourMonths}`;
    default:
      return strings.beforeBirth;
    /* eslint-enable */
  }
};

export const getNotEnoughLessonsReportAction = (reportName) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getClients());
      const { data: omamas } = await axios.get(api.getOmamas);
      const clients = data.data.filter((client) => client.active && client.lessonsAlarm);

      for (const client of clients) {
        client.clientName = `${client.firstName} ${client.lastName}`;
        client.birthDate = dayjs(client.birthDate).format('DD.MM.YYYY');
        const omama = omamas.users.find((omama) => omama._id === client.omamaID);
        client.omamaName = omama ? omama.name : '';
        const clientActions = await axios.get(
          api.getAdminClientActions(
            client._id,
            dayjs().subtract(2, 'months').format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD'),
          ),
        );
        let actionDates = '';
        for (const action of clientActions.data.data) {
          if (action.status === 'done' || action.status === 'active') {
            actionDates =
              actionDates !== ''
                ? actionDates + ' ' + dayjs(action.date).format('DD.MM.YYYY')
                : dayjs(action.date).format('DD.MM.YYYY');
          }
        }

        Object.assign(client, { actions: actionDates });
      }

      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: clients,
      });
    } catch (err) {
      dispatch(setLoading(false));
    }
  };
};

export const getTwoRedScreeningsReportAction = (reportName) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.post(api.getTwoRedScreeningsReport);
      for (const client of data.clients) {
        client.clientName = `${client.firstName} ${client.lastName}`;
        client.birthDate = dayjs(client.birthDate).format('DD.MM.YYYY');
        client.lastScreeningDate = dayjs(client.lastScreeningDate).format('DD.MM.YYYY');
        client.screeningTypes.forEach((screening, index) => {
          switch (screening) {
            case 2:
              client.screeningTypes[index] = `S-PMV 2 (do 4. ${strings.week3})`;
              break;
            case 3:
              client.screeningTypes[index] = `S-PMV 3 (5-7. ${strings.week4})`;
              break;
            case 4:
              client.screeningTypes[index] = `S-PMV 4 (8-10. ${strings.week4})`;
              break;
            case 5:
              client.screeningTypes[index] = `S-PMV 5 (3-4. ${strings.oneMonth})`;
              break;
            case 6:
              client.screeningTypes[index] = `S-PMV 6 (5-6. ${strings.oneMonth})`;
              break;
            case 7:
              client.screeningTypes[index] = `S-PMV 7 (7-8. ${strings.oneMonth})`;
              break;
            case 8:
              client.screeningTypes[index] = `S-PMV 8 (9-10. ${strings.oneMonth})`;
              break;
            case 9:
              client.screeningTypes[index] = `S-PMV 9 (11-12. ${strings.oneMonth})`;
              break;
            case 10:
              client.screeningTypes[index] = `S-PMV 10 (15-18. ${strings.oneMonth})`;
              break;
            case 11:
              client.screeningTypes[index] = `S-PMV 11 (26-35. ${strings.oneMonth})`;
              break;
            case 12:
              client.screeningTypes[index] = `S-PMV 12 (36-40. ${strings.oneMonth})`;
              break;
            default:
              break;
          }
        });
        client.screeningType = `${client.screeningTypes[0]} ${client.screeningTypes[1]}`;
      }

      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: data.clients,
      });
    } catch (err) {
      dispatch(setLoading(false));
    }
  };
};

export const getClientLectionsReportAction = (clientID, reportName, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(
        api.getAdminClientActions(
          clientID,
          dayjs(start).format('YYYY-MM-DD'),
          dayjs(end).add(1, 'days').format('YYYY-MM-DD'),
        ),
      );
      const { data: client } = await axios.get(api.getAdminClient(clientID));
      const { data: activities } = await axios.get(api.getALLActivities);
      const { data: omamas } = await axios.get(api.getOmamas);
      const doneActions = data.data.filter((action) => action.status === 'done');
      let orderedAdminClientActions = orderBy(doneActions, ['date'], ['asc']);

      for (const action of orderedAdminClientActions) {
        action.clientName = `${client.data.firstName} ${client.data.lastName}`;
        action.omamaName = omamas.users.find((omama) => omama._id === client.data.omamaID).name;
        action.gender = client?.data?.gender;
        action.age = calculateClientAge(client.data.birthDate, action.date);
        action.time = dayjs(action.date).format('HH:mm');
        action.date = dayjs(action.date).format('D. M. YYYY');
        action.question1 =
          action.evaluation.question1 === 'notAtAll'
            ? strings.notAtAll
            : action.evaluation.question1 === 'sometimes'
              ? strings.sometimes
              : strings.veryOften;
        action.question2 =
          action.evaluation.question2 === 'notAtAll'
            ? strings.notAtAll
            : action.evaluation.question2 === 'sometimes'
              ? strings.sometimes
              : strings.veryOften;
        action.question3 =
          action.evaluation.question3 === 'notAtAll'
            ? strings.notAtAll
            : action.evaluation.question3 === 'sometimes'
              ? strings.sometimes
              : strings.veryOften;
        let actionActivities = '';
        for (const checkedActivityID of action.checkedActivities) {
          let foundActivity = activities.activities.find((activity) => activity._id === checkedActivityID);
          actionActivities =
            actionActivities !== '' ? actionActivities + '. ' + foundActivity.name : foundActivity.name;
          Object.assign(action, { activities: actionActivities });
        }
      }

      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: orderedAdminClientActions,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getOmamaLectionsReportAction = (omamaID, reportName, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(
        api.getAdminOmamaActions(
          omamaID,
          dayjs(start).format('YYYY-MM-DD'),
          dayjs(end).add(1, 'days').format('YYYY-MM-DD'),
        ),
      );

      let query = `limit=${MAX_LIMIT}`;
      if (omamaID !== 'all') {
        query += `&omama=${omamaID}`;
      }
      const { data: fetchedClients } = await axios.get(api.getClients(query));
      const omamaClients = fetchedClients.data.map((item) => ({
        ...item,
        name: item.firstName + ' ' + item.lastName,
      }));

      const { data: activities } = await axios.get(api.getALLActivities);
      const { data: omamas } = await axios.get(api.getOmamas);

      const doneActions = data.data.filter((action) => action.status === 'done' && action.client === true);

      for (const action of doneActions) {
        const foundClient = omamaClients.find((client) => client._id === action.id);

        action.clientName = foundClient && foundClient.name;
        action.omamaName = omamas.users.find((omama) => omama._id === action.omamaID).name;
        action.gender = foundClient && foundClient.gender;
        action.age = foundClient ? calculateClientAge(foundClient.birthDate, action.date) : null;
        action.datetime = action.date;
        action.time = dayjs(action.date).format('HH:mm');
        action.date = dayjs(action.date).format('D. M. YYYY');
        action.type = action.differentAction === 'club' ? strings.preschoolClub : strings.lesson;
        action.question1 = parentEngagementAnswerMap[action.evaluation?.question1] || '';
        action.question2 = parentEngagementAnswerMap[action.evaluation?.question2] || '';
        action.question3 = parentEngagementAnswerMap[action.evaluation?.question3] || '';
        let actionActivities = '';
        for (const checkedActivityID of action.checkedActivities) {
          const foundActivity = activities.activities.find((activity) => activity._id === checkedActivityID);
          if (foundActivity) {
            actionActivities =
              actionActivities !== '' ? actionActivities + '. ' + foundActivity.name : foundActivity.name;
          }
        }
        Object.assign(action, { activities: actionActivities });
      }

      const orderedAdminOmamaActions = orderBy(doneActions, ['omamaName', 'datetime'], ['asc', 'asc']);

      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: orderedAdminOmamaActions,
      });
    } catch (error) {
      dispatch(setLoading(false));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export const getSupervisionsReportAction = (reportName, supervisor) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const supervisors = [];
    if (supervisor !== 'allSupervisors') {
      supervisors.push(supervisor);
    }
    try {
      const { data } = await axios.get(api.getSupervisorsReport(), {
        params: {
          supervisors,
        },
      });
      const payloadData = data.data;

      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: payloadData,
      });
    } catch (error) {
      showErrorMessage(error, strings.errors.reportError);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getParentalClubsReportAction = (community, reportName, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(
        api.getAdminOmamaAllParentalClubs(
          community,
          dayjs(start).format('YYYY-MM-DD'),
          dayjs(end).add(1, 'days').format('YYYY-MM-DD'),
        ),
      );

      let orderedParentalClubs = orderBy(data.parentalClubs, ['date'], ['asc']);

      for (const parentalClub of orderedParentalClubs) {
        parentalClub.time = dayjs(parentalClub.date).format('HH:mm');
        parentalClub.date = dayjs(parentalClub.date).format('D. M. YYYY');
        parentalClub.omamaName = parentalClub.omamas[0].name;
        parentalClub.city = parentalClub.omamas[0].city;
        parentalClub.comment = parentalClub.comment ? parentalClub.comment : parentalClub.differentAction;
      }
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: orderedParentalClubs,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const resetReportAction = (reportName) => {
  return { type: RESET_REPORT, payload: reportName };
};

const getWorkingDays = (settings) => {
  let start = settings.startDate;
  const end = settings.endDate;
  let res = [];

  while (start.isBefore(end)) {
    let day = start.format('dddd').toLowerCase();
    if (settings[day]) {
      res.push(start.format('D.M.'));
    }
    start = start.add(1, 'day');
  }
  return res;
};

export const getOmamaReportStatisticsAction = (year, omamaId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const settings = {
      startDate: year === 2019 ? dayjs('2019-09-16') : dayjs().year(year).startOf('year'),
      endDate: dayjs(),
      pondelok: true,
      utorok: true,
      streda: true,
      štvrtok: true,
      piatok: true,
      sobota: false,
      nedeľa: false,
    };

    let fetchedData;
    try {
      if (omamaId) {
        const { data } = await axios.get(
          api.getAdminOmamaActions(
            omamaId,
            dayjs().year(year).startOf('year').format('YYYY-MM-DD'),
            dayjs().year(year).endOf('year').add(1, 'days').format('YYYY-MM-DD'),
          ),
        );
        fetchedData = data.data;
      } else {
        const { data } = await axios.get(
          api.getActions(
            dayjs().year(year).startOf('year').format('YYYY-MM-DD'),
            dayjs().year(year).endOf('year').add(1, 'days').format('YYYY-MM-DD'),
          ),
        );
        fetchedData = data.data;
      }

      const { result } = groupActions(fetchedData);
      const allActionsByDay = result.allActionsByDay;
      let doneLessons,
        doneClubs,
        canceledActions,
        mentoring,
        mentoringSenior,
        phoneCall,
        parentalClub,
        supervision,
        training,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        education,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other;
      doneLessons =
        doneClubs =
        canceledActions =
        mentoring =
        phoneCall =
        mentoringSenior =
        parentalClub =
        supervision =
          0;
      training = miu = travel = INTER_NDA = otherJob = education = vacation = doctorVisit = doctorRelative = 0;
      workUnable = OCR = extraTimeOff = other = 0;

      const uniqActions = uniqBy(fetchedData, (action) => {
        if (action.differentAction === 'club') {
          return action.clubNames + action.date;
        }
        return action._id;
      });

      for (const action of uniqActions) {
        switch (true) {
          /* eslint-disable */
          case action.client === true && action.status === 'done':
            if (action.differentAction === 'club') {
              doneClubs += 1;
            } else {
              doneLessons += 1;
            }
            break;
          case action.client === true && action.status.startsWith('canceled'):
            canceledActions += 1;
            break;

          case action.client === false && action.id === strings.mentoring:
            mentoring += action.timeSpent;
            break;
          case action.client === false && action.id === strings.mentorTillSenior:
            mentoringSenior += action.timeSpent;
            break;
          case action.client === false && action.id === strings.phoneCall:
            phoneCall += action.timeSpent;
            break;
          case action.client === false && action.id === strings.parentalClub1:
            parentalClub += action.timeSpent;
            break;
          case action.client === false && action.id === strings.supervision:
            supervision += action.timeSpent;
            break;
          case action.client === false && action.id === strings.training:
            training += action.timeSpent;
            break;
          case action.client === false && action.id === strings.miu:
            miu += action.timeSpent;
            break;
          case action.client === false && action.id === strings.travel:
            travel += action.timeSpent;
            break;
          case action.client === false && action.id === strings.interNDA:
            INTER_NDA += action.timeSpent;
            break;
          case action.client === false && action.id === strings.otherJob:
            otherJob += action.timeSpent;
            break;
          case action.client === false && action.id === strings.education:
            education += action.timeSpent;
            break;

          // druha skupina
          case action.client === false && action.id === strings.vacation:
            vacation += action.timeSpent;
            break;
          case action.client === false && action.id === strings.doctor:
            doctorVisit += action.timeSpent;
            break;
          case action.client === false && action.id === strings.familyDoctor:
            doctorRelative += action.timeSpent;
            break;
          case action.client === false && action.id === strings.workUnable:
            workUnable += action.timeSpent;
            break;
          case action.client === false && action.id === strings.OCR:
            OCR += action.timeSpent;
            break;
          case action.client === false && action.id === strings.extraTimeOff:
            extraTimeOff += action.timeSpent;
            break;
          case action.client === false && action.id === strings.other:
            other += action.timeSpent;
            break;
          default:
            break;
          /* eslint-enable */
        }
      }

      let nevyplneneVykazy = 0;
      const workingDays = getWorkingDays(settings);
      for (const calendarDay of workingDays) {
        let found = allActionsByDay.find((element) => {
          return element.day === calendarDay;
        });
        if (!found) {
          nevyplneneVykazy += 1;
        } else {
          for (const action of found.Actions) {
            if (action.client === true && action.status === 'active') {
              nevyplneneVykazy += 1;
              break;
            }
          }
        }
      }

      const reportStatistics = {
        nevyplneneVykazy,
        doneLessons,
        doneClubs,
        canceledActions,
        mentoring,
        mentoringSenior,
        phoneCall,
        parentalClub,
        supervision,
        training,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        education,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other,
      };

      await dispatch({
        // await
        type: GET_OMAMA_REPORT_STATISTICS,
        payload: reportStatistics,
      });
      dispatch(setLoading(false));
    } catch (error) {
      console.error('Error in getOmamaReportStatisticsAction', error);
      dispatch(setLoading(false));
    }
  };
};

export const getMentorReportStatisticsAction = (year, mentorID) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const settings = {
      startDate: year === 2019 ? dayjs('2019-09-16') : dayjs().year(year).startOf('year'),
      endDate: dayjs(),
      pondelok: true,
      utorok: true,
      streda: true,
      štvrtok: true,
      piatok: true,
      sobota: false,
      nedeľa: false,
    };

    let fetchedData;
    try {
      if (mentorID) {
        const { data } = await axios.get(
          api.getAdminMentorActions(
            mentorID,
            dayjs().year(year).startOf('year').format('YYYY-MM-DD'),
            dayjs().year(year).endOf('year').add(1, 'days').format('YYYY-MM-DD'),
          ),
        );
        fetchedData = data.data;
      }

      const { result } = groupActions(fetchedData);
      const allActionsByDay = result.allActionsByDay;

      let mentoring,
        phoneCall,
        otherMentoring,
        parentalClub,
        supervision,
        training,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other;
      mentoring = phoneCall = otherMentoring = parentalClub = supervision = training = miu = travel = INTER_NDA = 0;
      otherJob = vacation = doctorVisit = doctorRelative = workUnable = OCR = extraTimeOff = other = 0;

      for (const action of fetchedData) {
        switch (true) {
          /* eslint-disable */
          case action.id === strings.mentoring:
            mentoring += action.timeSpent;
            break;
          case action.id === strings.phoneCall:
            phoneCall += action.timeSpent;
            break;
          case action.id === strings.otherMentoring:
            otherMentoring += action.timeSpent;
            break;
          case action.id === strings.parentalClub1:
            parentalClub += action.timeSpent;
            break;
          case action.id === strings.supervision:
            supervision += action.timeSpent;
            break;
          case action.id === strings.training:
            training += action.timeSpent;
            break;
          case action.id === strings.miu:
            miu += action.timeSpent;
            break;
          case action.id === strings.travel:
            travel += action.timeSpent;
            break;
          case action.id === strings.interNDA:
            INTER_NDA += action.timeSpent;
            break;
          case action.id === strings.otherJob:
            otherJob += action.timeSpent;
            break;

          // druha skupina
          case action.id === strings.vacation:
            vacation += action.timeSpent;
            break;
          case action.id === strings.doctor:
            doctorVisit += action.timeSpent;
            break;
          case action.id === strings.familyDoctor:
            doctorRelative += action.timeSpent;
            break;
          case action.id === strings.workUnable:
            workUnable += action.timeSpent;
            break;
          case action.id === strings.OCR:
            OCR += action.timeSpent;
            break;
          case action.id === strings.extraTimeOff:
            extraTimeOff += action.timeSpent;
            break;
          case action.id === strings.other:
            other += action.timeSpent;
            break;
          default:
            break;
          /* eslint-enable */
        }
      }

      let nevyplneneVykazy = 0;
      const workingDays = getWorkingDays(settings);
      for (const calendarDay of workingDays) {
        const found = allActionsByDay.find((element) => {
          return element.day === calendarDay;
        });
        if (!found) {
          nevyplneneVykazy += 1;
        }
      }

      const reportStatistics = {
        nevyplneneVykazy,
        mentoring,
        phoneCall,
        otherMentoring,
        parentalClub,
        supervision,
        training,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other,
      };

      await dispatch({
        // await
        type: GET_MENTOR_REPORT_STATISTICS,
        payload: reportStatistics,
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getSupervisorReportStatisticsAction = (year, supervisorID) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const settings = {
      startDate: year === 2019 ? dayjs('2019-09-16') : dayjs().year(year).startOf('year'),
      endDate: dayjs(),
      pondelok: true,
      utorok: true,
      streda: true,
      štvrtok: true,
      piatok: true,
      sobota: false,
      nedeľa: false,
    };

    let fetchedData;
    try {
      if (supervisorID) {
        const { data } = await axios.get(
          api.getAdminSupervisorActions(
            supervisorID,
            dayjs().year(year).startOf('year').format('YYYY-MM-DD'),
            dayjs().year(year).endOf('year').add(1, 'days').format('YYYY-MM-DD'),
          ),
        );
        fetchedData = data.data;
      }

      const { result } = groupActions(fetchedData);
      const allActionsByDay = result.allActionsByDay;

      let supervision,
        training,
        mentoring,
        phoneCall,
        otherMentoring,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other;
      supervision = training = mentoring = phoneCall = otherMentoring = miu = travel = INTER_NDA = otherJob = 0;
      vacation = doctorVisit = doctorRelative = workUnable = OCR = extraTimeOff = other = 0;

      for (const action of fetchedData) {
        switch (true) {
          /* eslint-disable */
          case action.id === strings.supervision:
            supervision += action.timeSpent;
            break;
          case action.id === strings.training:
            training += action.timeSpent;
            break;
          case action.id === strings.miu:
            miu += action.timeSpent;
            break;
          case action.id === strings.travel:
            travel += action.timeSpent;
            break;
          case action.id === strings.interNDA:
            INTER_NDA += action.timeSpent;
            break;
          case action.id === strings.otherJob:
            otherJob += action.timeSpent;
            break;
          case action.id === strings.mentoring:
            mentoring += action.timeSpent;
            break;
          case action.id === strings.phoneCall:
            phoneCall += action.timeSpent;
            break;
          case action.id === strings.otherMentoring:
            otherMentoring += action.timeSpent;
            break;
          // druha skupina
          case action.id === strings.vacation:
            vacation += action.timeSpent;
            break;
          case action.id === strings.doctor:
            doctorVisit += action.timeSpent;
            break;
          case action.id === strings.familyDoctor:
            doctorRelative += action.timeSpent;
            break;
          case action.id === strings.workUnable:
            workUnable += action.timeSpent;
            break;
          case action.id === strings.OCR:
            OCR += action.timeSpent;
            break;
          case action.id === strings.extraTimeOff:
            extraTimeOff += action.timeSpent;
            break;
          case action.id === strings.other:
            other += action.timeSpent;
            break;
          default:
            break;
          /* eslint-enable */
        }
      }

      let nevyplneneVykazy = 0;
      const workingDays = getWorkingDays(settings);
      for (const calendarDay of workingDays) {
        let found = allActionsByDay.find((element) => {
          return element.day === calendarDay;
        });
        if (!found) {
          nevyplneneVykazy += 1;
        }
      }

      const reportStatistics = {
        nevyplneneVykazy,
        supervision,
        training,
        mentoring,
        phoneCall,
        otherMentoring,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other,
      };

      await dispatch({
        // await
        type: GET_SUPERVISOR_REPORT_STATISTICS,
        payload: reportStatistics,
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getOmamasStatsReportAction = (reportName, year) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const start = dayjs().year(year).startOf('year');
    const end = dayjs().year(year).add(1, 'days').endOf('year');
    const { data: thisYear } = await axios.get(
      api.getOmamasStatsRange(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')),
    );
    const { data: allTime } = await axios.get(api.getOmamasStatsAll);

    for (let i = 0; i < thisYear.data.length; i++) {
      const row = thisYear.data[i];
      row['created'] = dayjs(row['created']).format('DD. MM. YYYY');
      row['active'] = row['active'] ? 'Aktívna' : 'Neaktívna';
      row['level'] = omamaLevelMap[row['level']];
      row['allTimeLessons'] = allTime.data[i].lessons;
      row['allTimePreschoolClubs'] = allTime.data[i].preschoolClubs;
      row['allTimeParentClubs'] = allTime.data[i].parentClubs;
      row['allTimeSupervisions'] = allTime.data[i].supervisions;
      row['allTimeSupervisionRatingAverage'] =
        allTime.data[i].supervisions > 0 ? `${allTime.data[i].supervisionRatingAverage}%` : '-';
      row['supervisionRatingAverage'] = row.supervisions > 0 ? `${row.supervisionRatingAverage}%` : '-';
    }

    try {
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: thisYear.data,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getParentEngagementReportAction = (reportName, year) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const start = dayjs().year(year).startOf('year').format('YYYY-MM-DD');
    const end = dayjs().year(year).add(1, 'days').endOf('year').format('YYYY-MM-DD');

    const { data: allActions } = await axios.get(api.getStatisticsOther(start, end));
    const { data: users } = await axios.get(api.getOmamas);
    const allOmamas = users.users;
    const actions = allActions.data.actions;
    const omamasByID = keyBy(allOmamas, '_id');

    let tempActionAnswersByOmamas = Object.assign(
      ...allOmamas.map((omama) => ({
        [omama._id]: {
          question1: {
            notAtAll: 0,
            sometimes: 0,
            often: 0,
            count: 0,
          },
          question2: {
            notAtAll: 0,
            sometimes: 0,
            often: 0,
            count: 0,
          },
          question3: {
            notAtAll: 0,
            sometimes: 0,
            often: 0,
            count: 0,
          },
        },
      })),
    );

    let tempActionAnswersTotal = {
      omamaName: strings.together1,
      question1: {
        notAtAll: 0,
        sometimes: 0,
        often: 0,
        count: 0,
      },
      question2: {
        notAtAll: 0,
        sometimes: 0,
        often: 0,
        count: 0,
      },
      question3: {
        notAtAll: 0,
        sometimes: 0,
        often: 0,
        count: 0,
      },
    };

    for (const action of actions) {
      // Ignore actions that have omamaID of unexisting user
      // which would cause this for loop to crash
      if (!omamasByID[action.omamaID]) {
        continue;
      }

      if (action.client === true && action.status === 'done' && action.differentAction !== 'club') {
        tempActionAnswersByOmamas[action.omamaID].question1[action.evaluation.question1] += 1;
        tempActionAnswersByOmamas[action.omamaID].question1.count += 1;
        tempActionAnswersTotal.question1[action.evaluation.question1] += 1;
        tempActionAnswersTotal.question1.count += 1;
        tempActionAnswersByOmamas[action.omamaID].question2[action.evaluation.question2] += 1;
        tempActionAnswersByOmamas[action.omamaID].question2.count += 1;
        tempActionAnswersTotal.question2[action.evaluation.question2] += 1;
        tempActionAnswersTotal.question2.count += 1;
        tempActionAnswersByOmamas[action.omamaID].question3[action.evaluation.question3] += 1;
        tempActionAnswersByOmamas[action.omamaID].question3.count += 1;
        tempActionAnswersTotal.question3[action.evaluation.question3] += 1;
        tempActionAnswersTotal.question3.count += 1;
      }
    }

    let actionAnswers = Object.keys(tempActionAnswersByOmamas).map((omama) => ({
      ...tempActionAnswersByOmamas[omama],
      omamaID: omama,
      omamaName: omamasByID[omama].name,
    }));
    actionAnswers = actionAnswers.filter((omama) => omama.question1.count > 0);
    actionAnswers.push(tempActionAnswersTotal);

    try {
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: actionAnswers,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getTrendsReportAction = (reportName) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const thisYear = dayjs().year();
    let stats = [];
    for (let year = 2018; year <= thisYear; year++) {
      let yearStats = {};
      yearStats['year'] = year;
      yearStats['stats'] = await axios.get(api.getStatistics(year));

      const start = dayjs(year.toString()).startOf('year').format('YYYY-MM-DD');
      const end = dayjs(year.toString()).endOf('year').add(1, 'days').format('YYYY-MM-DD');
      const { data: allActions } = await axios.get(api.getStatisticsOther(start, end));
      const { data: users } = await axios.get(api.getOmamas);
      const allOmamas = users.users;

      const { data: latestScreenings } = await axios.get(api.getAllLatestScreenings(start, end));

      yearStats['screenings'] = {
        green: 0,
        orange: 0,
        red: 0,
        worries: 0,
      };

      for (const scr of latestScreenings.data) {
        if (scr.pasma.vyvinovehoSkore.pasmo === 'green') {
          yearStats.screenings.green += 1;
        } else if (scr.pasma.vyvinovehoSkore.pasmo === 'orange') {
          yearStats.screenings.orange += 1;
        } else {
          yearStats.screenings.red += 1;
        }

        if (scr.pasma.obavy.pasmo === 'red') {
          yearStats.screenings.worries += 1;
        }
      }

      const actions = allActions.data.actions;
      const omamasByID = keyBy(allOmamas, '_id');

      yearStats['parentEngagement'] = {
        question1: {
          notAtAll: 0,
          sometimes: 0,
          often: 0,
        },
        question2: {
          notAtAll: 0,
          sometimes: 0,
          often: 0,
        },
        question3: {
          notAtAll: 0,
          sometimes: 0,
          often: 0,
        },
      };

      for (const action of actions) {
        if (!omamasByID[action.omamaID]) {
          continue;
        }
        if (action.client === true && action.status === 'done' && action.differentAction !== 'club') {
          yearStats.parentEngagement.question1[action.evaluation.question1] += 1;
          yearStats.parentEngagement.question2[action.evaluation.question2] += 1;
          yearStats.parentEngagement.question3[action.evaluation.question3] += 1;
        }
      }

      stats.push(yearStats);
    }

    try {
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: stats,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getOmamasActivitiesReportAction = (reportName) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const omamas = await axios.get(api.getOmamas);
    const start = dayjs().startOf('month').format('YYYY-MM-DD');
    const end = dayjs().endOf('month').format('YYYY-MM-DD');

    const workdays = getMonthWorkingDays(dayjs().month() + 1, dayjs().year());
    let users = omamas.data.users.filter((user) => user.employmentType !== '');
    for (let user of users) {
      const actions = await axios.get(api.getAdminOmamaActions(user._id, start, end));
      const omamaActions = actions.data.data;
      const employmentType = employmentTypesMap[user.employmentType];
      const workingHours = user.employmentType === 1 ? 8 : user.employmentType === 2 ? 4 : 6;
      const idealMaxLessons = (user.expectedClientCount * workdays) / 5;
      let clientActionsCount = 0;
      let nonClientActionsCount = 0;
      const countedPreschoolClubs = {};
      omamaActions.forEach((action) => {
        const isAfterDate = dayjs(action.date).isAfter(dayjs(new Date()).endOf('day'));
        if (isAfterDate) {
          return;
        }
        if (!action.client) {
          if (nonClientActionsMap[action.id]) {
            nonClientActionsCount += action.timeSpent;
          }
        } else if (action.differentAction === 'club' && action.status === 'done') {
          const uid = action.clubNames + action.date;
          if (!countedPreschoolClubs[uid]) {
            clientActionsCount += action.clubIds.length;
            countedPreschoolClubs[uid] = true;
          }
        } else if (action.status === 'done') {
          clientActionsCount += 1;
        }
      });
      const toSubstract = Math.round((nonClientActionsCount * user.expectedClientCount) / 5 / workingHours);
      const calculatedIdealMaxLessons = Math.max(0, Math.round(idealMaxLessons - toSubstract));
      const percentage = Math.round((clientActionsCount / calculatedIdealMaxLessons) * 100 * 100) / 100;
      const clientActions = clientActionsCount + ' ' + strings.lessons2;
      const nonClientActions = nonClientActionsCount + ' ' + strings.workedHours;
      const hoursToSubstract = toSubstract + ' ' + strings.lessons2;

      Object.assign(user, {
        employmentType,
        workingHours,
        clientActionsCount,
        nonClientActionsCount,
        clientActions,
        nonClientActions,
        idealMaxLessons: Math.round(idealMaxLessons),
        calculatedIdealMaxLessons,
        hoursToSubstract,
        percentage,
      });
    }

    users = users.filter((user) => user.clientActionsCount > 0 || user.nonClientActionsCount > 0);

    try {
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: users,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getOmamasRatingReportAction = (reportName, from, to) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const vaha = {
      name: strings.weight2,
      clientPercentage: 6,
      supervisionsRatingPercentage: 22,
      lessonsPercentage: 66,
      parentClubsPercentage: 6,
    };
    const { data: omamas } = await axios.get(
      api.getOmamasRatingStatsRange(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')),
    );
    const users = omamas.data;
    users.forEach((user) => {
      user.result =
        (user.clientPercentage * vaha.clientPercentage) / 100 +
        (user.supervisionsRatingPercentage * vaha.supervisionsRatingPercentage) / 100 +
        (user.lessonsPercentage * vaha.lessonsPercentage) / 100 +
        (user.parentClubsPercentage * vaha.parentClubsPercentage) / 100;
    });
    users.sort((a, b) => b.result - a.result);
    users.unshift(vaha);

    try {
      dispatch(setLoading(false));
      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: users,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getMentorsWorkRatingReportAction = (reportName, from, to, mentor) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getMentorsWorkRatingRange(), {
        params: {
          mentor,
          from: from.format('YYYY-MM-DD'),
          to: to.format('YYYY-MM-DD'),
        },
      });

      return dispatch({
        type: ADD_NEW_REPORT_DATA,
        reportName,
        payload: data.data,
      });
    } catch (error) {
      showErrorMessage(error, strings.errors.reportError);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const getFormatedDiff = (from, to) => {
  const duration = dayjs.duration(dayjs(to).diff(from));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  let yearsString, monthsString, daysString;
  switch (years) {
    case 1:
      yearsString = strings.year.toLowerCase();
      break;
    case 2:
    case 3:
    case 4:
      yearsString = strings.years;
      break;
    default:
      yearsString = strings.years1;
  }
  switch (months) {
    case 1:
      monthsString = strings.oneMonth;
      break;
    case 2:
    case 3:
    case 4:
      monthsString = strings.twoTillFourMonths;
      break;
    default:
      monthsString = strings.zeroOrFiveMonths;
  }
  switch (days) {
    case 1:
      daysString = strings.day;
      break;
    case 2:
    case 3:
    case 4:
      daysString = strings.days1;
      break;
    default:
      daysString = strings.days2;
  }
  return `${years} ${yearsString} ${months} ${monthsString} ${days} ${daysString}`;
};

export const getScreeningsReportAction = (reportName, year, type) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const { data: screenings } = await axios.get(api.getScreeningsResultsReport(year, type));

    const reportData = screenings.data
      ? screenings.data.map((row) => {
          return {
            ...row,
            birthDate: dayjs(row.client.birthDate).format('DD.MM.YYYY'),
            age: getFormatedDiff(row.client.birthDate, row.created),
            premature: dayjs(row.client.gestacnyBirthDate).diff(row.client.birthDate, 'weeks'),
            pediater: row.client.pediatricScreenings ? strings.yes : strings.no,
            created: dayjs(row.created).format('DD.MM.YYYY'),
            answers: row.answers.reduce(
              (acc, answer) => ({
                ...acc,
                [answer.id.split('_')[1]]: answer.answer ? strings.yes : strings.no,
              }),
              {},
            ),
            overallScore: Object.values(row.pasma).reduce((acc, pasmo) => acc + pasmo.body, 0),
          };
        })
      : [];

    dispatch(setLoading(false));
    return dispatch({
      type: ADD_NEW_REPORT_DATA,
      reportName,
      payload: reportData,
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getFilledOutScreeningsReportAction = (reportName) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const { data } = await axios.post(api.getScreeningsDoneReport);
    dispatch(setLoading(false));
    return dispatch({
      type: ADD_NEW_REPORT_DATA,
      reportName,
      payload: data.data,
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getComprehensiveReportAction = (reportName, from, to, region) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axios.get(api.getComprehensiveReport(dayjs(from), dayjs(to), region ? region : 'all'));
    dispatch(setLoading(false));
    return dispatch({
      type: ADD_NEW_REPORT_DATA,
      reportName,
      payload: data.data,
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getMeasurementsReportAction = (reportName, type, region) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const clientRegion = region ? region : 'all';
    const { data } = await axios.get(api.getAllMeasurements(type, clientRegion));
    dispatch(setLoading(false));
    return dispatch({
      type: ADD_NEW_REPORT_DATA,
      reportName,
      payload: data.data,
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getAccessoriesReportAction = (reportName) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axios.get(api.getMissingAccessoriesHistory);

    const reportData = [];
    data.data.forEach((record) => {
      const reportRecord = {
        region: record.omamaRegion,
        providedBy: providedByMap[record.providedBy],
        omama: record.omamaName,
        accessory: record.accessory,
        status: accessoryStatusMap[record.status],
      };
      let previouslyMissing = false;
      let missingDate = '';
      let returnDate = '';
      let note = '';
      record.history.forEach((historyRecord) => {
        if (historyRecord.status === 'missing') {
          missingDate = dayjs(historyRecord.createdAt).format('DD.MM.YYYY');
          note = historyRecord.note;
          previouslyMissing = true;
        } else if (historyRecord.status === 'rented' && previouslyMissing) {
          returnDate = dayjs(historyRecord.createdAt).format('DD.MM.YYYY');
          previouslyMissing = false;
          reportData.push({ ...reportRecord, note, missingDate, returnDate });
        }
      });
      if (previouslyMissing) {
        reportData.push({ ...reportRecord, note, missingDate, returnDate: '' });
      }
    });

    dispatch(setLoading(false));
    return dispatch({
      type: ADD_NEW_REPORT_DATA,
      reportName,
      payload: reportData,
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};
