import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from 'conf/api';

export const getUserNotifications = createAsyncThunk('notifications/getUserNotifications', async () => {
  const response = await axios.get(api.notifications);

  if (response.data.success) {
    const newResponse = {
      ...response.data.notifications,
    };

    return newResponse;
  }
});

export const deleteNotification = createAsyncThunk('notifications/deleteNotification', async (notificationId) => {
  const response = await axios.delete(`${api.notifications}/${notificationId}`);

  if (response.data.success) {
    return response.data.notifications;
  }
});

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    status: 'initial',
    deleteStatus: 'idle',
    data: {
      new: [],
      seen: [],
    },
    popup: false,
  },
  reducers: {
    closePopup: (state) => {
      state.popup = false;
    },
    showPopup: (state) => {
      state.popup = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        state.status = 'done';
        state.data = action.payload;

        if (action.payload.popup?.length > 0) {
          state.popup = true;
        }
      })
      .addCase(getUserNotifications.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteNotification.pending, (state) => {
        state.deleteStatus = 'deleting';
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.deleteStatus = 'failed';
      })
      .addCase(deleteNotification.fulfilled, (state) => {
        state.deleteStatus = 'idle';
      });
  },
});

export const { showPopup, closePopup } = notificationsSlice.actions;
export default notificationsSlice.reducer;
