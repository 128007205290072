import React, { useState, useEffect, useCallback } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Table, Modal, Input, Dropdown, DatePicker, TimePicker, Select } from 'antd';
import {
  DownOutlined,
  EditOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  RightCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import Button from 'components/Button';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import axios from 'axios';

// Local imports
import { api } from '../../conf';
import { strings } from '../../strings/StringsProvider';
import {
  getAdminSupervisorActionsAction,
  changeNextMonthSupervisor,
  changePreviousMonthSupervisor,
  adminSupervisorChangeDate,
  deleteSupervisorAction,
  loadChecklistsInfo,
  changeSupervisorAction,
} from '../../actions/supervisor.actions';
import { getUsersAction } from '../../actions/users.actions';
import { setLoading } from '../../actions/status.actions';
import './SupervisorActions.scss';
import { deepSum, findLabelByKeyInDropdownMenu } from '../../tools/utils';

const { RangePicker } = DatePicker;
const currentSupervisionChecklistVersion = 'v2';

function SupervisorActions({ match, supervisor }) {
  const user = match ? match.params.userId : jwtDecode(localStorage.getItem('access-token'));

  const supervisorId = supervisor ? supervisor._id : user.username;
  const supervisorName = supervisor ? supervisor.name : user.name;
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [actionDate, setActionDate] = useState('');
  const [actionTime, setActionTime] = useState(dayjs());
  const [omama, setOmama] = useState(strings.chooseOmama);
  const [omamaKey, setOmamaKey] = useState('');
  const [actionKey, setActionKey] = useState('');
  const [actionName, setActionName] = useState(strings.chooseAction);
  const [timeSpent, setTimeSpent] = useState(1);
  const [lectionSearched, setLectionSearched] = useState(false);
  const [lection, setLection] = useState(null);
  const [comment, setComment] = useState('');
  const [checklist, setChecklist] = useState(strings.chooseChecklist);
  const [checklistKey, setChecklistKey] = useState('');
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [visibleActionEdit, setVisibleActionEdit] = useState(false);
  const [editActionData, setEditActionData] = useState({});
  const [editActionDate, setEditActionDate] = useState(null);
  const [editActionTime, setEditActionTime] = useState(null);

  const dispatch = useDispatch();

  const omamas = useSelector((state) => state.users.byArr.filter((user) => user.role === 'omama'));

  const supervisorActions = useSelector((state) =>
    state.supervisor.supervisorActions.map((action) => ({
      ...action,
      nameForTable: action.omama ? action.id + ' ' + action.omamaName : action.id,
    })),
  );

  const supervisorStartMonth = useSelector((state) => state.supervisor.supervisorStartMonth);
  const supervisorEndMonth = useSelector((state) => state.supervisor.supervisorEndMonth);
  const supervisorFrom = useSelector((state) => state.supervisor.supervisorFrom);
  const supervisorTo = useSelector((state) => state.supervisor.supervisorTo);
  const checklists = useSelector((state) => state.supervisor.checklistsList);
  const changePage = (location) => push(location);

  useEffect(() => {
    if (supervisorId) {
      dispatch(
        getAdminSupervisorActionsAction(
          supervisorId,
          supervisorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD'),
          dayjs(supervisorEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
            dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
        ),
      );
    }
    dispatch(loadChecklistsInfo());
  }, [dispatch, supervisorEndMonth, supervisorId, supervisorStartMonth]);

  const findOmamaLection = useCallback(async () => {
    if (actionDate && actionTime && omamaKey) {
      const time = dayjs(actionTime).format('HH:mm');
      const date = dayjs(actionDate + 'T' + time).format();
      const from = dayjs(date).set('second', 0).format();
      const { data } = await axios.get(api.findOmamaLection(from, omamaKey));
      setLectionSearched(true);
      setLection(data.lection ? data.lection : null);
    }
  }, [actionDate, actionTime, omamaKey]);

  useEffect(() => {
    findOmamaLection();
  }, [findOmamaLection, omamaKey]);

  const changeNextMonth = () => () => {
    // clear date range search
    changeActionDate([null, null]);
    dispatch(changeNextMonthSupervisor(supervisorId, supervisorStartMonth, supervisorEndMonth));
  };

  const changePreviousMonth = () => () => {
    // clear date range search
    changeActionDate([null, null]);
    dispatch(changePreviousMonthSupervisor(supervisorId, supervisorStartMonth, supervisorEndMonth));
  };

  const searchActionsAndChangeDate = () => (date) => {
    dispatch(adminSupervisorChangeDate(date));
    const from = date?.[0];
    const to = date?.[1];
    (from || to) && dispatch(getAdminSupervisorActionsAction(supervisorId, from, dayjs(to).add(1, 'days')));
  };

  const changeActionDate = (date) => {
    dispatch(adminSupervisorChangeDate(date));
  };

  const searchActions = () => {
    dispatch(getAdminSupervisorActionsAction(supervisorId, supervisorFrom, dayjs(supervisorTo).add(1, 'days')));
  };

  const onChecklistChange = ({ key }) => {
    // change v1 to current checklist version in checklistID
    const newKey = key.replace('_v1', `_${currentSupervisionChecklistVersion}`);
    setChecklistKey(newKey);
    setChecklist(findLabelByKeyInDropdownMenu(key, checklistOptions));
  };

  const onActionChange = ({ key }) => {
    setActionKey(key);
    setActionName(findLabelByKeyInDropdownMenu(key, actionOptions));
    setLectionSearched(false);
  };

  const checklistOptions =
    checklists &&
    checklists.map((checklist) => ({
      key: checklist._id,
      label: (
        <span data-test-id={`admin-supervisor-plan-addAction-checklist-option-${checklist._id}`}>
          {checklist.displayName}
        </span>
      ),
    }));

  const actionOptions = [
    {
      key: 'supervision-group',
      label: strings.supervision1.toUpperCase(),
      type: 'group',
      children: [
        {
          key: 'supervision',
          label: <span data-test-id="admin-supervisor-action-type-supervision">{strings.supervision}</span>,
        },
        {
          key: 'training',
          label: <span data-test-id="admin-supervisor-action-type-training">{strings.training}</span>,
        },
        { key: 'miu', label: <span data-test-id="admin-supervisor-action-type-miu">{strings.miu}</span> },
        { key: 'travel', label: <span data-test-id="admin-supervisor-action-type-travel">{strings.travel}</span> },
        {
          key: 'interNDA',
          label: <span data-test-id="admin-supervisor-action-type-interNDA">{strings.interNDA}</span>,
        },
        {
          key: 'otherJob',
          label: <span data-test-id="admin-supervisor-action-type-otherJob">{strings.otherJob}</span>,
        },
      ],
    },
    {
      key: 'mentoring-group',
      label: strings.mentoring.toUpperCase(),
      type: 'group',
      children: [
        {
          key: 'mentoring',
          label: <span data-test-id="admin-supervisor-action-type-mentoring">{strings.mentoring}</span>,
        },
        {
          key: 'phoneCall',
          label: <span data-test-id="admin-supervisor-action-type-phoneCall">{strings.phoneCall}</span>,
        },
      ],
    },
    {
      key: 'notBeingInJob-group',
      label: strings.notBeingInJob,
      type: 'group',
      children: [
        {
          key: 'vacation',
          label: <span data-test-id="admin-supervisor-action-type-vacation">{strings.vacation}</span>,
        },
        { key: 'doctor', label: <span data-test-id="admin-supervisor-action-type-doctor">{strings.doctor}</span> },
        {
          key: 'familyDoctor',
          label: <span data-test-id="admin-supervisor-action-type-familyDoctor">{strings.familyDoctor}</span>,
        },
        {
          key: 'workUnable',
          label: <span data-test-id="admin-supervisor-action-type-workUnable">{strings.workUnable}</span>,
        },
        { key: 'OCR', label: <span data-test-id="admin-supervisor-action-type-OCR">{strings.OCR}</span> },
        {
          key: 'extraTimeOff',
          label: <span data-test-id="admin-supervisor-action-type-extraTimeOff">{strings.extraTimeOff}</span>,
        },
        { key: 'other', label: <span data-test-id="admin-supervisor-action-type-other">{strings.other}</span> },
      ],
    },
  ];

  const changeState = (stateSetter) => (value) => {
    if (value && value.target && value.target.value !== undefined) {
      stateSetter(value.target.value);
    } else {
      stateSetter(value);
    }
  };

  const showModalAdd = () => {
    if (isEmpty(omamas)) {
      dispatch(getUsersAction());
    }
    setVisibleModalAdd(true);
  };

  const handleCancelModalAdd = () => {
    setVisibleModalAdd(false);
    setActionDate('');
    setActionTime(dayjs());
    setOmama('');
    setOmamaKey('');
    setActionKey('');
    setActionName(strings.chooseAction);
    setTimeSpent(1);
    setLectionSearched(false);
    setLection(null);
    setComment('');
    setChecklistKey('');
    setChecklist(strings.chooseChecklis);
  };

  const isButtonDisabled = () => {
    if (actionKey === 'supervision') {
      return !lection || !checklistKey;
    } else if (actionKey === 'mentoring' || actionKey === 'phoneCall' || actionKey === 'otherMentoring') {
      if (isEmpty(actionDate) || isEmpty(actionTime) || isEmpty(actionName) || isEmpty(omamaKey)) {
        return true;
      }
    } else if (
      isEmpty(actionDate) ||
      actionName === strings.chooseAction ||
      isEmpty(actionTime) ||
      isEmpty(actionName)
    ) {
      return true;
    }
    return false;
  };

  const addAction = async () => {
    dispatch(setLoading(true));

    const time = dayjs(actionTime).format('HH:mm');
    const date = dayjs(actionDate + 'T' + time).format();

    const includeOmama =
      actionKey === 'supervision' ||
      actionKey === 'mentoring' ||
      actionKey === 'phoneCall' ||
      actionKey === 'otherMentoring';

    const body = {
      date,
      omama: includeOmama,
      id: actionName,
      supervisorID: supervisorId,
      supervisorName: supervisorName,
      omamaID: includeOmama ? omamaKey : '',
      omamaName: includeOmama ? omama : '',
      status: 'active',
      actionKey,
      timeSpent: parseFloat(timeSpent),
      comment,
    };

    if (actionKey === 'supervision') {
      body.checklistID = checklistKey;
    }

    const from = supervisorFrom || supervisorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD');
    const to = supervisorTo
      ? dayjs(supervisorTo).add(1, 'days')
      : dayjs(supervisorEndMonth).add(1, 'days').format('YYYY-MM-DD') || dayjs().endOf('month').format('YYYY-MM-DD');
    try {
      if (actionKey === 'supervision' && lection) {
        body.lectionID = lection._id;
        body.supervisorID = supervisorId;
      }

      await axios.post(api.adminAddNewSupervisorAction, body);

      if (dayjs(actionDate).isBetween(from, to, undefined, '[)')) {
        dispatch(getAdminSupervisorActionsAction(supervisorId, from, to));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      setVisibleModalAdd(false);
      setActionDate('');
      setActionTime(dayjs());
      setOmama('');
      setOmamaKey('');
      setActionKey('');
      setActionName(strings.chooseAction);
      setTimeSpent(1);
      setLectionSearched(false);
      setLection(null);
      setComment('');
    }
  };

  const deleteSupervisorActionHandler = async (id, callback) => {
    try {
      await dispatch(deleteSupervisorAction(id));
      callback();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const deleteActionOk = async () => {
    await deleteSupervisorActionHandler(currentRecord._id, () => {
      dispatch(
        getAdminSupervisorActionsAction(
          supervisorId,
          supervisorFrom || supervisorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD'),
          supervisorTo
            ? supervisorTo.add(1, 'days')
            : dayjs(supervisorEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
                dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
        ),
      );

      setVisibleEdit(false);
      setVisibleDelete(false);
      setCurrentRecord(null);
    });
  };

  const openSupervision = (action) => {
    dispatch(changePage(`/supervisor/form/${action._id}`));
  };

  const changePageHandler = (url) => {
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    dispatch(changePage(url));
  };

  const renderSelectOptions = () => {
    const { Option } = Select;
    return omamas.map((omama) => (
      <Option
        className="selectRow"
        key={omama._id}
        data-test-id={`admin-supervisor-plan-addAction-omama-option-${omama._id}`}
      >
        {omama.name}
      </Option>
    ));
  };

  const handleChange = (value) => {
    const names = value
      .map((e) => {
        return omamas.find((x) => x._id === e).name;
      })
      .sort()
      .join(', ');

    setOmamaKey(value);
    setOmama(names);
  };

  const handleEditStart = (event, record) => {
    event.stopPropagation();
    setVisibleEdit(true);
    setCurrentRecord(record);
  };

  const handleEditEnd = () => {
    setVisibleEdit(false);
    setCurrentRecord(null);
  };

  const editAction = () => {
    const from = supervisorFrom || supervisorStartMonth || dayjs().startOf('month').format('YYYY-MM-DD');

    const to =
      supervisorTo?.add(1, 'days') ||
      dayjs(supervisorEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
      dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD');

    let body = editActionData;
    if (editActionDate || editActionTime) {
      body.date =
        (editActionDate || currentRecord.date.split('T')[0]) +
        'T' +
        (dayjs(editActionTime).format().split('T')[1] || currentRecord.date.split('T')[1]);
    }

    dispatch(changeSupervisorAction(currentRecord?._id, body, currentRecord?.supervisorID, from, to));

    setVisibleActionEdit(false);
    setEditActionTime(null);
    setEditActionDate(null);
    setEditActionData({});
    setCurrentRecord(null);
    setVisibleEdit(false);
  };

  const columns = [
    {
      title: strings.date,
      dataIndex: 'date',
      key: 'date',
      render: (date) => <span>{dayjs(date).format('D. M. YYYY')}</span>,
    },
    {
      title: strings.time,
      dataIndex: 'date',
      key: 'time',
      render: (date) => <span>{dayjs(date).format('HH:mm')}</span>,
    },
    {
      title: strings.name,
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => {
        return (
          <>
            <span>{text}</span>
            {record.omamaName && (
              <>
                <br />
                <span>({record.omamaName})</span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: strings.spentTime,
      dataIndex: 'timeSpent',
      key: 'timeSpent',
    },
    {
      title: strings.comment,
      dataIndex: 'comment',
      key: 'comment',
      render: (comment) => <div>{comment}</div>,
    },
    {
      title: strings.points,
      dataIndex: 'pointsSum',
      key: 'pointsSum',
      render: (text, record) => {
        let outputText = text;
        if (record.questions) {
          const sum = deepSum(record.questions);
          outputText = `${sum.value}/${sum.max}, ${Math.round((sum.value / (sum.max / 100)) * 100) / 100}%`;
        }
        return <span>{outputText}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return <EditOutlined className="editIcon" onClick={(event) => handleEditStart(event, record)} />;
      },
    },
  ];

  return (
    <div>
      <div className="supervisorActionsDesktopView">
        <div className="adminClient-form">
          <div className="adminClient-form-group" data-test-id="supervisor-plan-dateRangePicker">
            <RangePicker
              onChange={changeActionDate}
              name="omamaCourse"
              defaultValue={[null, null]}
              value={[supervisorFrom, supervisorTo]}
              className="adminClient_datepicker"
              placeholder={['Od', 'Do']}
            />
            <Button
              type="secondary"
              onClick={() => searchActions()}
              disabled={!supervisorTo || !supervisorTo}
              data-test-id="supervisor-changeWeek-searchButton"
            >
              {strings.search}
            </Button>
            <div className="admin-omama-actions-week__changeWeek">
              <div style={{ fontWeight: 'bold' }} data-test-id="supervisor-changeWeek-leftButton">
                <LeftCircleOutlined className="week-navigation-left big" onClick={changePreviousMonth()} />
              </div>
              <div className="monthData" style={{ padding: '0 5px', fontWeight: 'bold' }}>
                {dayjs(supervisorEndMonth).format('MMMM YYYY')}
              </div>
              <div style={{ fontWeight: 'bold' }} data-test-id="supervisor-changeWeek-rightButton">
                <RightCircleOutlined className="week-navigation-right big" onClick={changeNextMonth()} />
              </div>
            </div>
          </div>
          <div className="adminClient-form-add-button">
            <Button onClick={() => showModalAdd()} data-test-id="supervisor-addActionButton">
              {strings.addAction}
            </Button>
          </div>
        </div>

        <Table
          className="supervisor-actions-table new-table"
          rowKey="_id"
          dataSource={supervisorActions}
          columns={columns}
          pagination={{ pageSize: 20 }}
          rowClassName={(record) => (record.actionKey === 'supervision' ? 'supervision' : 'non-supervision')}
          onRow={(record) => ({
            onClick: () => record.actionKey === 'supervision' && openSupervision(record),
          })}
        />
      </div>
      <div className="supervisorPlanMobileView">
        <div className="container">
          <div className="container-inner">
            <div className="container-inner-options">
              <div className="container-inner-options-rangePicker">
                <RangePicker
                  onChange={searchActionsAndChangeDate()}
                  defaultValue={[null, null]}
                  value={[supervisorFrom, supervisorTo]}
                  style={{ transform: 'scale(0.8)' }}
                  placeholder={['Od', 'Do']}
                />
              </div>
              <div className="container-inner-options-monthPicker">
                <div className="container-inner-options-monthPicker-icon">
                  <LeftOutlined onClick={changePreviousMonth()} />
                </div>
                <div className="container-inner-options-monthPicker-text">
                  {dayjs(supervisorEndMonth).format('MMMM YYYY')}
                </div>
                <div className="container-inner-options-monthPicker-icon">
                  <RightOutlined onClick={changeNextMonth()} />
                </div>
              </div>
              <div className="container-inner-options-addAction" onClick={() => showModalAdd()}>
                <img className="ikona pridatButton" src="/images/Icons/plus_icon.png" alt="ikona" />
              </div>
            </div>
            <div className="container-inner-toDoList">
              {supervisorActions
                .sort((a, b) => dayjs(b.date).diff(a.date))
                .map((toDo) => {
                  return (
                    <div
                      key={toDo._id}
                      className="container-inner-toDoList-toDo"
                      onClick={() => changePageHandler(`/supervisor/akcia/${toDo._id}`)}
                    >
                      <div className="container-inner-toDoList-toDo-date">{dayjs(toDo.date).format('D. M. YYYY')}</div>
                      <div className="container-inner-toDoList-toDo-time">{dayjs(toDo.date).format('HH:mm')}</div>
                      <div className="container-inner-toDoList-toDo-title">{toDo.nameForTable}</div>
                      <div className="container-inner-toDoList-toDo-arrow">
                        <RightOutlined className="icon" />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        title={strings.addAction}
        open={visibleModalAdd}
        onCancel={handleCancelModalAdd}
        cancelText={strings.close}
        onOk={addAction}
        okText={strings.confirm}
        okButtonProps={{ disabled: isButtonDisabled() }}
      >
        <div className="pridatContainer">
          <div className="addNewActionPicker">
            <h3 className="pickerHeader">Dátum</h3>
            <Input
              type="date"
              value={actionDate}
              onChange={changeState(setActionDate)}
              className="picker"
              data-test-id="admin-supervisor-plan-addAction-date-input"
            />
          </div>
          <div className="addNewActionPicker">
            <h3 className="pickerHeader">Čas</h3>
            <br />
            <TimePicker
              defaultValue={dayjs('12:00', 'HH:mm')}
              type="time"
              format={'HH:mm'}
              minuteStep={15}
              needConfirm={false}
              onChange={changeState(setActionTime)}
              value={actionTime}
              className="picker"
              placeholder={strings.chooseTime}
              data-test-id="admin-supervisor-plan-addAction-time-input"
            />
          </div>
          <div className="adminOmama-spentTimePicker">
            <div>{strings.spentTime}</div>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                type="number"
                id="timeSpent"
                onChange={changeState(setTimeSpent)}
                value={timeSpent}
                step={0.5}
                min={0}
                data-test-id="admin-supervisor-plan-addAction-duration-input"
              />
              <div>h</div>
            </div>
          </div>
          <br />

          <div>
            <p className="actionHeader">{strings.chooseAction1}</p>
            <div className="dropdownSelect">
              <Dropdown trigger={['click']} menu={{ items: actionOptions, onClick: onActionChange }} placement="bottom">
                <div data-test-id="admin-supervisor-plan-addAction-action-dropdown">
                  {actionName} <DownOutlined />
                </div>
              </Dropdown>
            </div>

            <div className="activityFormField">
              <div>
                <Input
                  placeholder={strings.comment}
                  value={comment}
                  onChange={changeState(setComment)}
                  data-test-id="admin-supervisor-plan-addAction-comment-input"
                />
              </div>
            </div>
          </div>
          {(actionKey === 'phoneCall' ||
            actionKey === 'supervision' ||
            actionKey === 'mentoring' ||
            actionKey === 'otherMentoring') && (
            <div>
              <Select
                mode="multiple"
                allowClear
                popupClassName="selectMenu"
                className="SelectInputMulti"
                placeholder={strings.chooseOmamas}
                onChange={handleChange}
                data-test-id="admin-supervisor-plan-addAction-omama-select"
              >
                {renderSelectOptions()}
              </Select>
            </div>
          )}
          <br />
          {lectionSearched && !lection && <div>{strings.noLectureFound}</div>}
          <br />
          {actionKey === 'supervision' && (
            <div className="dropdownSelect">
              <Dropdown
                trigger={['click']}
                menu={{ items: checklistOptions, onClick: onChecklistChange }}
                placement="top"
              >
                <div data-test-id="admin-supervisor-plan-addAction-checklist-select">
                  {checklist} <DownOutlined />
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </Modal>

      {visibleEdit ? (
        <Modal centered title={strings.editAction} open={visibleEdit} onCancel={handleEditEnd} footer={false}>
          <div className="changeActionContainer">
            <Button shape="round" onClick={() => setVisibleActionEdit(true)}>
              {strings.editAction}
            </Button>
            <Button shape="round" onClick={() => setVisibleDelete(true)}>
              {strings.deleteAction}
            </Button>
          </div>

          <Modal
            title={strings.editAction}
            open={visibleActionEdit}
            onCancel={() => setVisibleActionEdit(false)}
            cancelText={strings.cancel}
            onOk={() => editAction()}
            okText={strings.confirm}
          >
            <div className="editActionAdmin">
              <p>{currentRecord?.nameForTable}</p>
              {!['phoneCall', 'supervision', 'mentoring', 'otherMentoring'].includes(currentRecord?.actionKey) ? (
                <>
                  <label>{strings.date}</label>
                  <Input
                    type="date"
                    defaultValue={dayjs(currentRecord?.date).format('YYYY-MM-DD')}
                    onChange={(e) => setEditActionDate(e.target.value)}
                    className="picker admin-input"
                  />

                  <label>{strings.time}</label>
                  <TimePicker
                    type="time"
                    format={'HH:mm'}
                    minuteStep={15}
                    needConfirm={false}
                    onChange={(e) => setEditActionTime(e)}
                    defaultValue={dayjs(currentRecord?.date)}
                    className="picker admin-input"
                    placeholder={strings.chooseTime}
                    data-test-id="supervisor-plan-editAction-timePicker"
                  />
                </>
              ) : null}

              <label>{strings.timeSpent}</label>
              <div className="timeSpentEdit">
                <Input
                  type="number"
                  id="timeSpent"
                  onChange={(e) =>
                    setEditActionData({
                      ...editActionData,
                      timeSpent: Number(e.target.value),
                    })
                  }
                  defaultValue={currentRecord?.timeSpent}
                  step={0.5}
                  min={0}
                  className="timeSpentInput"
                />
                <span>h</span>
              </div>

              <label>{strings.comment}</label>
              <textarea
                placeholder={strings.comment}
                defaultValue={currentRecord?.comment || ''}
                className="changeCommentInput"
                onChange={(e) =>
                  setEditActionData({
                    ...editActionData,
                    comment: e.target.value,
                  })
                }
              />
            </div>
          </Modal>
          <Modal
            centered
            title={strings.deleteAction}
            open={visibleDelete}
            onCancel={() => setVisibleDelete(false)}
            onOk={() => deleteActionOk()}
          >
            {strings.question}
          </Modal>
        </Modal>
      ) : null}
    </div>
  );
}

SupervisorActions.propTypes = {
  match: PropTypes.object,
  supervisor: PropTypes.object,
};

export default SupervisorActions;
