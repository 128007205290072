/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Local imports
import {
  getOmamaClientsAction,
  getClientScreenings,
  getOmamaClientActionsAction,
  initialSkriningforClientProfile,
} from '../../../actions/omama.actions';
import { strings } from '../../../strings/StringsProvider';
import ClientProfileCard from '../../../components/ClientProfileCard';
import ScreeningTable from '../../../components/ScreeningTable/ScreeningTable';
import ChangeClientPhoto from '../ChangeClientPhoto';
import './ClientProfile.scss';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import ClientProfileAction from '../../../components/ClientProfileAction';
import { getAllScreeningQuestions } from 'slices/screenings';

function ClientProfile(props) {
  const [openClientID, setOpenClientID] = useState('');
  const [openAction, setOpenAction] = useState({});
  const [showAction, setShowAction] = useState(false);
  const [changePhoto, setChangePhoto] = useState(false);
  const [client, setClient] = useState({});
  const [doneActions, setDoneActions] = useState([]);
  const [doneActionsNumber, setDoneActionsNumber] = useState(0);
  const [futureActions, setFutureActions] = useState([]);
  const [canceledActions, setCanceledActions] = useState([]);
  const [omamaClient, setOmamaClient] = useState(null);
  const [clientsById, setClientsById] = useState({});

  const dispatch = useDispatch();

  const { omama, screenings } = useSelector((state) => state);

  useEffect(() => {
    const fetchClientsAction = async () => {
      try {
        const { payload } = await dispatch(getOmamaClientsAction());
        setOmamaClient(payload);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClientsAction();

    const clientId = props.match.params.clientProfileId;
    const dayjsFn = dayjs();
    const date = dayjsFn.add(1, 'years').format('YYYY-MM-DD');

    dispatch(getClientScreenings(clientId));
    dispatch(getOmamaClientActionsAction(clientId, '1980-01-01', date));
    dispatch(initialSkriningforClientProfile(clientId));
    dispatch(getAllScreeningQuestions());

    if (openClientID === '') {
      const { clientProfileId } = props.match.params;
      setOpenClientID(clientProfileId);
    }
  }, []);

  useEffect(() => {
    if (omamaClient) {
      const clientMatch = omamaClient.clients.find((item) => item._id === openClientID) || {};
      setClient(clientMatch);
    }
  }, [omamaClient]);

  useEffect(() => {
    if (omama.clientActions[0]) {
      /** Done actions */

      const unsortedDoneAction = omama.clientActions.filter((action) => action.status === 'done');
      const sortedDoneActions = orderBy(unsortedDoneAction, ['date'], ['asc']);
      setDoneActionsNumber(sortedDoneActions.length);

      let finalDoneActions = sortedDoneActions;
      if (sortedDoneActions.length > 8) {
        finalDoneActions = sortedDoneActions.slice(sortedDoneActions.length - 8);
      }

      setDoneActions(finalDoneActions);

      /** Future actions */

      const unsortedFutureActions = omama.clientActions.filter(
        (action) => dayjs(action.date).isAfter(dayjs()) && action.status === 'active',
      );
      const sortedFutureActions = orderBy(unsortedFutureActions, ['date'], ['asc']);

      setFutureActions(sortedFutureActions);

      /** Canceled actions */

      const unsortedCanceledActions = omama.clientActions.filter((action) => action.status.startsWith('canceled'));
      const sortedCanceledActions = orderBy(unsortedCanceledActions, ['date'], ['asc']);
      let finalCanceledActions = sortedCanceledActions;
      if (sortedCanceledActions.length > 8) {
        finalCanceledActions = sortedCanceledActions.slice(sortedCanceledActions.length - 8);
      }

      setCanceledActions(finalCanceledActions);
    }
  }, [omama.clientActions[0]]);

  useEffect(() => {
    setClientsById(omama.clients);
  }, [omama.clients]);

  const changePage = (location) => dispatch(push(location));

  const navigateToScreening = (data) => {
    const { screeningType } = data;
    changePage('/omama/screening/' + screeningType + '/klient/' + openClientID);
  };

  const toggleChangePhoto = () => {
    setChangePhoto(!changePhoto);
  };

  const updateAction = (openAction, showAction) => {
    setOpenAction(openAction);
    setShowAction(showAction);
  };

  return (
    <div>
      <div>
        <ClientProfileCard
          client={client}
          nextLection={futureActions[0]?.date}
          doneLections={doneActionsNumber}
          toScreening={(screeningID) => navigateToScreening(screeningID, openClientID)}
          toggleChangePhoto={toggleChangePhoto}
        />
      </div>
      <div style={{ padding: '0 10px', backgroundColor: 'white' }}>
        {showAction && (
          <ClientProfileAction client={client} action={openAction} goBack={() => updateAction({}, false)} />
        )}

        <div className="clientProfile-actionHeadline">{strings.lastLessons}</div>
        <div className="clientProfile-actionList">
          {doneActions.map((action) => (
            <div key={action._id} onClick={() => updateAction(action, true)}>
              {dayjs(action.date).format('D. M. YYYY')}
            </div>
          ))}
        </div>
        <div className="clientProfile-actionHeadline">{strings.lastCanceledLessons}</div>
        <div className="clientProfile-actionList">
          {canceledActions.map((action) => (
            <div key={action._id}>{dayjs(action.date).format('D. M. YYYY')}</div>
          ))}
        </div>
        {clientsById && (
          <ScreeningTable
            strings={strings}
            clientBirthdate={dayjs(clientsById[props.match?.params?.clientProfileId]?.gestacnyBirthDate).format()}
            screenings={screenings}
            initialScreenings={omama.initialScreeningsForClientProfile}
            toScreening={navigateToScreening}
          />
        )}
      </div>
      {changePhoto && <ChangeClientPhoto client={clientsById[openClientID]} toggleChangePhoto={toggleChangePhoto} />}
    </div>
  );
}

ClientProfile.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  getOmamaClientsAction: PropTypes.func,
  clientsByArr: PropTypes.arrayOf(PropTypes.object),
  changePage: PropTypes.func,
  clientScreenings: PropTypes.arrayOf(PropTypes.object),
  clientsById: PropTypes.object,
  doneActions: PropTypes.array,
  canceledActions: PropTypes.array,
  actionsDone: PropTypes.number,
  getAllScreeningQuestions: PropTypes.func,
  screeningTests: PropTypes.object,
  futureActions: PropTypes.arrayOf(PropTypes.object),
};

export default ClientProfile;
