import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from 'conf/api';

export const getScreeningClient = createAsyncThunk('screenings/getScreeningClient', async (clientId) => {
  const { data } = await axios.get(api.getClient(clientId));
  return data;
});

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    screeningClient: {
      status: 'initial',
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScreeningClient.pending, (state) => {
        state.screeningClient.status = 'pending';
      })
      .addCase(getScreeningClient.rejected, (state) => {
        state.screeningClient.status = 'rejected';
      })
      .addCase(getScreeningClient.fulfilled, (state, action) => {
        state.screeningClient.status = 'fulfilled';
        state.screeningClient.data = action.payload.data;
      });
  },
});

export default clientsSlice.reducer;
