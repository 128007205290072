import dayjs from 'dayjs';
import { calculateScreeningDate } from 'tools/date.tools';

export const getScreeningAvailability = (type, birthDate, fromDay, toDay) => {
  const fromDate = calculateScreeningDate(dayjs(birthDate), fromDay);
  const toDate = calculateScreeningDate(dayjs(birthDate), toDay);
  let isScreeningAvailable;

  if (type === '2.PP') {
    isScreeningAvailable = dayjs().isSameOrBefore(toDate);
  } else {
    isScreeningAvailable = dayjs().isBetween(fromDate, toDate, null, '[]');
  }

  return {
    isScreeningAvailable,
    fromDate,
  };
};
