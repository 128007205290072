import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../../strings/StringsProvider';

function StatisticsOmamas({ renderOmamaDesktopTable, renderOmamaMobileTable, omamasStats }) {
  return (
    <div>
      <div className="desktopOmamaStats" data-test-id="statistics-omamas-stats-desktop">
        <div>
          <h4>{strings.allUpper}</h4>
          <div className="statsOverall" data-test-id="statistics-omamas-statsOverall-desktop">
            {renderOmamaDesktopTable(omamasStats.allTime)}
          </div>
        </div>
        <div>
          <h4>{strings.thisYearSoFar}</h4>
          <div className="statsThisYear" data-test-id="statistics-omamas-statsThisYear-desktop">
            {renderOmamaDesktopTable(omamasStats.thisYear)}
          </div>
        </div>
      </div>
      <div className="mobileOmamaStats" data-test-id="statistics-omamas-stats-mobile">
        <div>
          <h4 className="mobileTableHeader">{strings.allUpper}</h4>
          <div className="statsOverall" data-test-id="statistics-omamas-statsOverall-mobile">
            {omamasStats?.allTime && renderOmamaMobileTable(omamasStats.allTime)}
          </div>
        </div>
        <div>
          <h4 className="mobileTableHeader">{strings.thisYearSoFar}</h4>
          <div className="statsThisYear" data-test-id="statistics-omamas-statsThisYear-mobile">
            {omamasStats?.thisYear && renderOmamaMobileTable(omamasStats.thisYear)}
          </div>
        </div>
      </div>
    </div>
  );
}

StatisticsOmamas.propTypes = {
  renderOmamaDesktopTable: PropTypes.func.isRequired,
  renderOmamaMobileTable: PropTypes.func.isRequired,
  omamasStats: PropTypes.shape({
    allTime: PropTypes.array.isRequired,
    thisYear: PropTypes.array.isRequired,
  }).isRequired,
};

export default StatisticsOmamas;
