import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import { Popconfirm, Modal, TimePicker, Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from './Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import axios from 'axios';
import { api } from '../conf';
import { getDateDiff, getDevelopmentStage } from 'tools/date.tools';
import { jwtDecode } from 'jwt-decode';
// Local imports
import { strings } from '../strings/StringsProvider';
import './ActionDetailAdmin.scss';
import { getActivitiesAction } from '../actions/clientActivities.action';
import Base64Image from 'components/Base64Image';
import DevelopmentStageInfo from './DevelopmentStageInfo';
import ActivityInfo from 'containers/omama/OmamaActivities/activityInfo';
import { changeOmamaActionInfo } from '../actions/omama.actions';
import { getAdminClientsAction } from 'actions/admin.actions';

const actionQuestions = [strings.questionParentJoined, strings.questionParentPraised, strings.questionParentDecicated];

/*const groupNames = {
  1: 'Kognitívne funkcie',
  2: 'Jemná motorika',
  3: 'Hrubá motorika',
  4: 'Emocionálny rozvoj',
  5: 'Čítanie knihy',
  6: 'Domáce úlohy pre rodičov'
};*/

function ActionDetailAdmin(props) {
  const [client, setClient] = useState(props.client);
  const [actionPhotos, setActionPhotos] = useState(null);
  const [photoDetail, setPhotoDetail] = useState(null);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [actionDate, setActionDate] = useState(props.action?.date || null);
  const [comment, setComment] = useState(props.action?.comment || '');
  const [timeSpent, setTimeSpent] = useState(props.action?.timeSpent || null);
  const currentUserRole = jwtDecode(localStorage.getItem('access-token'))?.role;
  const [activityDetailVisible, setActivityDetailVisible] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [developmentStageVisible, setDevelopmentStageVisible] = useState(false);
  const [clientDevelopmentStage, setClientDevelopmentStage] = useState('');

  useEffect(() => {
    setClient(props.client);
    setActionDate(props.action?.date);
    setComment(props.action?.comment);
    setTimeSpent(props.action?.timeSpent);
  }, [props.action, props.client]);

  const clientActivities = useSelector((state) => state.clientActivities.activitiesAll);
  const activitiesAll = useSelector((state) => state.clientActivities.activitiesAll);
  const clients = useSelector((state) => state.admin.clients);

  const modifiedActivitiesType1 = clientActivities?.filter((activity) => activity.type === 1);
  const modifiedActivitiesType2 = clientActivities?.filter((activity) => activity.type === 2);
  const modifiedActivitiesType3 = clientActivities?.filter((activity) => activity.type === 3);
  const modifiedActivitiesType4 = clientActivities?.filter((activity) => activity.type === 4);
  const modifiedActivitiesType5 = clientActivities?.filter((activity) => activity.type === 5);
  const modifiedActivitiesType6 = clientActivities?.filter((activity) => activity.type === 6);
  const propsToObject = {
    activitiesType1: modifiedActivitiesType1,
    activitiesType2: modifiedActivitiesType2,
    activitiesType3: modifiedActivitiesType3,
    activitiesType4: modifiedActivitiesType4,
    activitiesType5: modifiedActivitiesType5,
    activitiesType6: modifiedActivitiesType6,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(activitiesAll) && props.action.client === true) {
      dispatch(getActivitiesAction());
    }
    (async () => {
      try {
        const userType = props.action.client ? 'client' : 'omama';
        const { data } = await axios.get(api.getActionPhotos(userType, props.action._id));
        const actionPhotos = data.data.filter((photo) => photo._id.endsWith('_photo'));
        // Remove duplicate photos caused by having
        // multiple omama_actions per 1 preschool club
        const uniqPhotos = uniqBy(actionPhotos, 'date');
        setActionPhotos(uniqPhotos);
      } catch (error) {
        // console.log(error);
      }
    })();
    if (isEmpty(client)) {
      (async () => {
        try {
          const { data } = await axios.get(api.getAdminClient(props.action.id));
          setClient(data.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      })();
    }
    if (isEmpty(clients)) {
      dispatch(getAdminClientsAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectActivity = (id) => {
    setActivityDetailVisible(true);
    setSelectedActivity(id);
  };

  const closeActivityDetail = () => {
    setActivityDetailVisible(false);
    setSelectedActivity('');
  };

  const showDevelopmentStageDetail = (birthDate, actionDate) => {
    const developmentStage = getDevelopmentStage(birthDate, actionDate);

    setClientDevelopmentStage(developmentStage);
    setDevelopmentStageVisible(true);
  };

  const developmentStageDetailClose = () => {
    setClientDevelopmentStage('');
    setDevelopmentStageVisible(false);
  };

  const renderActivityList = (activityGroup, checkedActivities) => {
    const { activitiesType1, activitiesType2, activitiesType3, activitiesType4, activitiesType5, activitiesType6 } =
      propsToObject;
    let activitiesToRender = [];
    switch (activityGroup) {
      case 1:
        activitiesToRender = activitiesType1.filter((item) => {
          return checkedActivities.includes(item._id);
        });
        break;
      case 2:
        activitiesToRender = activitiesType2.filter((item) => {
          return checkedActivities.includes(item._id);
        });
        break;
      case 3:
        activitiesToRender = activitiesType3.filter((item) => {
          return checkedActivities.includes(item._id);
        });
        break;
      case 4:
        activitiesToRender = activitiesType4.filter((item) => {
          return checkedActivities.includes(item._id);
        });
        break;
      case 5:
        activitiesToRender = activitiesType5.filter((item) => {
          return checkedActivities.includes(item._id);
        });
        break;
      case 6:
        activitiesToRender = activitiesType6.filter((item) => {
          return checkedActivities.includes(item._id);
        });
        break;
      default:
        break;
    }

    const sortedActivitiesToRender = activitiesToRender.sort((a, b) => a.code - b.code);

    if (isEmpty(sortedActivitiesToRender)) {
      return null;
    } else {
      return (
        <div>
          <div style={{ paddingLeft: '10px', color: '#41aea6' }}>
            <div className="groupList">
              {sortedActivitiesToRender.map((act) => (
                <div className="keyos" key={act._id}>
                  <div className="activityName" onClick={() => selectActivity(act._id)}>
                    <span className="monthWeek">{`${act.code} - ${act.mesiac}m/${act.tyzden}t - `}</span> {act.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  };

  const showPhotoDetail = (photo) => {
    setPhotoDetail(photo);
  };

  const handleCancelPhotoDetail = useCallback(() => {
    setPhotoDetail(null);
  }, []);

  const formatChildWeek = (birthDate) => {
    const { months, weeks } = getDateDiff(birthDate, props.action.date);
    return months + 'm/' + weeks + 't';
  };

  const handleEditSubmit = (action) => {
    const info = {
      date: actionDate,
      comment: comment,
      timeSpent: timeSpent,
    };
    setVisibleEdit(false);

    if (props.timeRange) {
      const startOfMonth = props.timeRange[0];
      const endOfMonth = props.timeRange[1];
      dispatch(
        changeOmamaActionInfo(
          action._id,
          action.omamaID,
          info,
          startOfMonth || dayjs().startOf('month').format('YYYY-MM-DD'),
          dayjs(endOfMonth).format('YYYY-MM-DD') || dayjs().endOf('month').format('YYYY-MM-DD'),
        ),
      );
    } else {
      dispatch(
        changeOmamaActionInfo(
          action._id,
          action.omamaID,
          info,
          dayjs(actionDate).startOf('week').format('YYYY-MM-DD'),
          dayjs(actionDate).endOf('week').format('YYYY-MM-DD'),
        ),
      );
    }
  };

  const handleDelete = (action) => {
    props.deleteAction(action._id);
  };

  const { action } = props;
  const isClub = action.differentAction === 'club';
  const filteredClients = [];

  if (isClub) {
    action.clubIds.forEach((id) => {
      clients.forEach((c) => {
        if (c._id === id) filteredClients.push(c);
      });
    });
  } else {
    clients.forEach((c) => {
      if (c._id === action.id) filteredClients.push(c);
    });
  }

  const renderComment = useMemo(
    () =>
      comment
        ? comment.startsWith('others')
          ? comment.split('-')[1]?.trim()
          : strings.actionDeletionOptions[comment]?.description || comment
        : '',
    [comment],
  );

  if (action.client) {
    return (
      <div className="ActionDetailAdmin-main">
        <div className="clientHeader">{isClub ? strings.preschoolClub : strings.lesson}</div>
        <div className="actionHeader" data-test-id="admin-clientDetail-lessonDetail-date">
          <div>{dayjs(actionDate).format('dddd D. M. YYYY, HH:mm')}</div>
          {filteredClients.map((client) => {
            return (
              <div key={client._id} style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  onClick={() => showDevelopmentStageDetail(client.birthDate, actionDate)}
                  className="openDevStageModal"
                >
                  {client.firstName + ' ' + client.lastName + ' (' + formatChildWeek(client.birthDate) + ')'}
                </div>
                <div className="devStageTooltipIcon">
                  <Tooltip title={strings.clientDevelopmentStageTooltip}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
        <div className="clientProfileAction-main">
          <div
            className="clientProfileAction-headline"
            style={{
              paddingTop: '0',
              marginBottom: '10px',
              borderTop: 'none',
            }}
          >
            {strings.activities}
          </div>
          <div className="clientProfileAction-activitiesList">
            {renderActivityList(1, action.checkedActivities)}
            {renderActivityList(2, action.checkedActivities)}
            {renderActivityList(3, action.checkedActivities)}
            {renderActivityList(4, action.checkedActivities)}
            {renderActivityList(5, action.checkedActivities)}
            {renderActivityList(6, action.checkedActivities)}
          </div>
          {!isClub && (
            <div className="action-detail-evaluation">
              <div className="clientProfileAction-headline">{strings.assessment}</div>
              {actionQuestions.map((question, i) => (
                <div key={i}>
                  <div className="clientend-question">{question}</div>
                  <div className="clientend-question__buttons">
                    <div
                      className={classNames({
                        selected: action.evaluation['question' + (i + 1)] === 'notAtAll',
                      })}
                    >
                      {strings.notAtAll}
                    </div>
                    <div
                      className={classNames({
                        selected: action.evaluation['question' + (i + 1)] === 'sometimes',
                      })}
                    >
                      {strings.sometimes}
                    </div>
                    <div
                      className={classNames({
                        selected: action.evaluation['question' + (i + 1)] === 'often',
                      })}
                    >
                      {strings.veryOften}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="clientProfileAction-headline">
            {strings.photo} {isClub ? strings.fromPreviousClub : strings.fromPreviousLesson}
          </div>
          {actionPhotos && actionPhotos.length > 0 ? (
            <div className="ActionDetailAdmin-tempPhotos">
              {actionPhotos.map((photo, i) => (
                <div key={i} className="ActionDetailAdmin-photo" onClick={() => showPhotoDetail(photo)}>
                  <Base64Image
                    className="imageWrapper"
                    key={i}
                    type="gallery-client"
                    imageID={photo._id}
                    showDate={true}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ marginBottom: '15px' }}>
              {strings.noPhotosFound} {isClub ? strings.fromPreviousClub : strings.fromPreviousLesson}
            </div>
          )}

          <div className="clientProfileAction-headline">{strings.comment}</div>
          <div className="clientProfileAction-comment">{renderComment}</div>
        </div>
        <div className="action-detail-modal-buttons">
          <Button
            onClick={() => setVisibleEdit(true)}
            type="secondary"
            data-test-id="adminOmama-actionDetail-edit-button"
          >
            {strings.editAction}
          </Button>
          {props.deleteAction && currentUserRole !== 'omama' ? (
            <Popconfirm
              title={strings.doYouWantToDeleteThisAction}
              okText={strings.yes}
              cancelText={strings.no}
              okType="default"
              onConfirm={() => props.deleteAction(action._id, true)}
              okButtonProps={{ 'data-test-id': 'admin-actionDetail-delete-action-confirm-button' }}
              cancelButtonProps={{ 'data-test-id': 'admin-actionDetail-delete-action-cancel-button' }}
            >
              <Button type="primary" data-test-id="admin-actionDetail-delete-action-button">
                {strings.delete} {isClub ? strings.preschoolClub.toLowerCase() : strings.lesson2}
              </Button>
            </Popconfirm>
          ) : null}
        </div>
        {/* DETAIL FOTKY */}
        <Modal
          className="ActionDetailAdmin-modalGallery"
          open={photoDetail !== null}
          onCancel={handleCancelPhotoDetail}
          footer={null}
          centered={true}
        >
          {photoDetail && (
            <div id="ActionDetailAdmin-modalGallery">
              <Base64Image key={photoDetail._id} type="gallery-client" imageID={photoDetail._id} showDate={true} />
            </div>
          )}
        </Modal>

        <Modal
          centered
          title={strings.editAction}
          open={visibleEdit} // this.state.visibleEdit
          onCancel={() => setVisibleEdit(false)}
          cancelText={strings.close}
          onOk={() => handleEditSubmit(action)}
          okText={strings.confirm}
        >
          <div className="editActionAdmin">
            <label>{strings.date}</label>
            <Input
              type="date"
              value={dayjs(actionDate).format('YYYY-MM-DD')}
              onChange={(value) => setActionDate(value.target.value)}
              className="picker admin-input"
            />

            <label>{strings.time}</label>
            <TimePicker
              format={'HH:mm'}
              minuteStep={15}
              onChange={(value) => setActionDate(value)}
              value={dayjs(actionDate)}
              changeOnScroll={true}
              needConfirm={false}
              className="picker admin-input"
              placeholder={strings.chooseTime}
            />

            <label>{strings.comment}</label>
            <textarea
              placeholder={strings.comment}
              value={comment || ''}
              onChange={(value) => {
                setComment(value.target.value);
              }}
            />
          </div>
        </Modal>

        <Modal
          centered
          title={strings.developmentStageDescription}
          open={developmentStageVisible}
          onCancel={developmentStageDetailClose}
          onOk={developmentStageDetailClose}
          footer={null}
        >
          {clientDevelopmentStage !== '' && <DevelopmentStageInfo type={clientDevelopmentStage} />}
        </Modal>

        <Modal
          title={strings.activityDetail}
          open={activityDetailVisible}
          footer={null}
          onCancel={closeActivityDetail}
          onOk={closeActivityDetail}
        >
          {selectedActivity && (
            <ActivityInfo
              activity={clientActivities.find((a) => a._id === selectedActivity)}
              goBack={closeActivityDetail}
              mobileActivity={true}
            />
          )}
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="ActionDetailAdmin-main">
        <div className="clientHeader">{strings.action}</div>
        <div className="actionHeader">
          <div>{dayjs(actionDate).format('dddd D. M. YYYY, HH:mm')}</div>
          <div>{action?.name}</div>
        </div>
        <div className="clientProfileAction-main">
          <div className="clientProfileAction-headline">{strings.spentTime}</div>
          <div>
            {timeSpent} {timeSpent > 4 ? strings.hour : strings.hours}
          </div>
          <br />

          <div className="clientProfileAction-headline">{strings.photo + ' ' + strings.fromPreviousAction}</div>
          {actionPhotos && actionPhotos.length > 0 ? (
            <div className="ActionDetailAdmin-tempPhotos">
              {actionPhotos.map((photo, i) => (
                <div key={i} className="ActionDetailAdmin-photo" onClick={() => showPhotoDetail(photo)}>
                  <Base64Image
                    className="imageWrapper"
                    key={i}
                    type="gallery-omama"
                    imageID={photo._id}
                    showDate={true}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ marginBottom: '15px' }}>{strings.noPhotosFound + ' ' + strings.fromPreviousAction}</div>
          )}
          <Modal
            className="ActionDetailAdmin-modalGallery"
            open={photoDetail !== null}
            onCancel={handleCancelPhotoDetail}
            footer={null}
            centered={true}
          >
            {photoDetail && (
              <div id="ActionDetailAdmin-modalGallery">
                <Base64Image key={photoDetail._id} type="gallery-omama" imageID={photoDetail._id} showDate={true} />
              </div>
            )}
          </Modal>

          <div className="clientProfileAction-headline">{strings.comment}</div>
          <div>{action?.comment || action.differentAction}</div>
        </div>
        <div className="editButtons">
          <Button
            onClick={() => setVisibleEdit(true)}
            type="secondary"
            data-test-id="admin-actionDetail-editAction-button"
          >
            {strings.editAction}
          </Button>
          <Button onClick={() => handleDelete(action)} data-test-id="admin-actionDetail-deleteAction-button">
            {strings.deleteAction}
          </Button>
          <Modal
            centered
            title={strings.editAction}
            open={visibleEdit}
            onCancel={() => setVisibleEdit(false)}
            cancelText={strings.close}
            onOk={() => handleEditSubmit(action)}
            okText={strings.confirm}
            okButtonProps={{ 'data-test-id': 'admin-actionDetail-edit-confirmEdit-button' }}
            cancelButtonProps={{ 'data-test-id': 'admin-actionDetail-edit-close-button' }}
          >
            <div className="editActionAdmin">
              <label>{strings.date}</label>
              <Input
                type="date"
                value={dayjs(actionDate).format('YYYY-MM-DD')}
                onChange={(value) => setActionDate(value.target.value)}
                className="picker admin-input"
                data-test-id="admin-actionDetail-edit-date-input"
              />
              <label>{strings.time}</label>
              <TimePicker
                format={'HH:mm'}
                minuteStep={15}
                onChange={(value) => setActionDate(value)}
                value={dayjs(actionDate)}
                needConfirm={false}
                className="picker admin-input"
                placeholder={strings.chooseTime}
                data-test-id="admin-actionDetail-edit-time-input"
              />
              <label>{strings.timeSpent}</label>
              <div className="timeSpentEdit">
                <Input
                  type="number"
                  id="timeSpent"
                  onChange={(value) => setTimeSpent(Number(value.target.value))}
                  defaultValue={timeSpent}
                  value={timeSpent}
                  step={0.5}
                  min={0}
                  className="timeSpentInput"
                  data-test-id="admin-actionDetail-edit-timeSpent-input"
                />
                <span>h</span>
              </div>
              <label>{strings.comment}</label>
              <textarea
                placeholder={strings.comment}
                value={comment}
                onChange={(value) => {
                  setComment(value.target.value);
                }}
                data-test-id="admin-actionDetail-edit-comment-input"
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

ActionDetailAdmin.propTypes = {
  action: PropTypes.object,
  client: PropTypes.object,
  deleteAction: PropTypes.func,
  timeRange: PropTypes.array,
};

export default ActionDetailAdmin;
