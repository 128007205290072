import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { RightOutlined } from '@ant-design/icons';
import { getScreeningAvailability } from '../../containers/admin/Screening/helpers';
import './ScreeningTable.scss';

function ScreeningTable({ strings, clientBirthdate, screenings, initialScreenings, toScreening }) {
  const screeningTypes = [
    { type: '2.PP', title: `2.PP (do 4. ${strings.week3})` },
    { type: '3.PP', title: `3.PP (5-7. ${strings.week4})` },
    { type: '4.PP', title: `4.PP (8-10. ${strings.week4})` },
    { type: '5.PP', title: `5.PP (3-4. ${strings.oneMonth})` },
    { type: '6.PP', title: `6.PP (5-6. ${strings.oneMonth})` },
    { type: '7.PP', title: `7.PP (7-8. ${strings.oneMonth})` },
    { type: '8.PP', title: `8.PP (9-10. ${strings.oneMonth})` },
    { type: '9.PP', title: `9.PP (11-12. ${strings.oneMonth})` },
    { type: '10.PP', title: `10.PP (15-18. ${strings.oneMonth})` },
    { type: '11.PP', title: `11.PP (26-35. ${strings.oneMonth})` },
    { type: '12.PP', title: `12.PP (36-40. ${strings.oneMonth})` },
  ];

  const createScreening = (type, title) => {
    const { isScreeningAvailable, fromDate } = getScreeningAvailability(
      type,
      dayjs(clientBirthdate)?.format(),
      screenings.screeningTests[type]?.fromDay,
      screenings.screeningTests[type]?.toDay,
    );

    return {
      title,
      type,
      date: type === '2.PP' ? dayjs(clientBirthdate)?.format('D. M. YYYY') : fromDate?.format('D. M. YYYY'),
      isAvailable: isScreeningAvailable,
    };
  };

  const tempScreenings = screeningTypes.reduce((acc, { type, title }) => {
    acc[type] = createScreening(type, title);
    return acc;
  }, {});

  initialScreenings.forEach((initialScreening) => {
    const number = initialScreening._id;
    tempScreenings[number].initialDate = initialScreening.created;
    tempScreenings[number].id = initialScreening.key;
  });

  return (
    <table data-test-id="clientProfile-screeningTable" className="screeningTable">
      <thead>
        <tr className="tableHeader">
          <th className="tableHeader-screening">{strings.screening1.toUpperCase()}</th>
          <th className="tableHeader-plan">{strings.plan.toUpperCase()}</th>
          <th className="tableHeader-completed"> {strings.filled.toUpperCase()}</th>
        </tr>
      </thead>
      <tbody>
        {tempScreenings &&
          Object.keys(tempScreenings).map((key, index) => (
            <tr
              key={index}
              data-test-id="clientProfile-screening"
              className="tableRow"
              onClick={() =>
                toScreening({
                  screeningType: tempScreenings[key].type,
                  screeningID: tempScreenings[key].id,
                  initialDate: tempScreenings[key].initialDate,
                })
              }
            >
              <td className="tableRow-screening">{tempScreenings[key].title}</td>
              <td className="tableRow-plan">{tempScreenings[key].date}</td>
              <td className="tableRow-completed">
                {tempScreenings[key].initialDate ? (
                  <>
                    {dayjs(tempScreenings[key].initialDate).format('D. M. YYYY')}
                    <RightOutlined className="arrowIcon" />{' '}
                  </>
                ) : tempScreenings[key].isAvailable ? (
                  <img className="plusIcon" src="/images/Icons/plus_icon.png" alt="" />
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

ScreeningTable.propTypes = {
  strings: PropTypes.object.isRequired,
  clientBirthdate: PropTypes.string.isRequired,
  screenings: PropTypes.object.isRequired,
  initialScreenings: PropTypes.arrayOf(PropTypes.object).isRequired,
  toScreening: PropTypes.func.isRequired,
};

export default ScreeningTable;
