import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { ScreeningQuestionsList } from './ScreeningQuestionsList';
import { getScreeningClient } from 'slices/clients';
import { strings } from '../../../strings/StringsProvider';
import { getScreeningAvailability } from './helpers';
import './ScreeningSections.scss';

export function ScreeningSections(props) {
  const screeningClient = useSelector((store) => store.clients.screeningClient);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScreeningClient(props.clientId));
  }, [dispatch, props.clientId]);

  const renderQuestionsWithType = (questionType, sectionTitle) => {
    const { questions: allQuestions, answers, changeQuestionStatus } = props;

    let filteredQuestions = allQuestions && allQuestions.filter((q) => q.screeningQuestionType === questionType);
    return (
      !isEmpty(filteredQuestions) && (
        <>
          <ScreeningQuestionsList
            questions={filteredQuestions}
            answers={answers}
            sectionTitle={sectionTitle}
            sectionLabel={strings.part + ': '}
            changeQuestionStatus={changeQuestionStatus}
          />
          <Divider className="divider" />
        </>
      )
    );
  };

  const { isScreeningAvailable, fromDate } = getScreeningAvailability(
    props.screening?._id,
    screeningClient?.data?.gestacnyBirthDate,
    props.screening?.fromDay,
    props.screening?.toDay,
  );

  const daysUntil = fromDate.diff(dayjs(), 'days');

  const getAvailabilityWarningMessage = () => {
    if (daysUntil > 0) {
      return `${strings.screening1} ${props.screening?._id} ${strings.notAbleToFill}
      ${strings.willBeAbleToBeFilled} ${daysUntil} ${strings.days} (${fromDate.format('D. M. YYYY')}).`;
    } else {
      return `${strings.screening1} ${props.screening?._id} ${strings.notAbleToFillAnymore}`;
    }
  };

  const userRole = jwtDecode(localStorage.getItem('access-token')).role;
  const isOmama = userRole === 'omama';

  return (
    <div className={!isScreeningAvailable || !isOmama ? 'disabled' : ''}>
      {!isScreeningAvailable && (
        <div className="warning">
          <h2 className="warning-text">{getAvailabilityWarningMessage()}</h2>
        </div>
      )}
      {renderQuestionsWithType(QuestionType.motorika, strings.motorics)}
      {renderQuestionsWithType(QuestionType.adaptivneSpravanie, strings.adaptiveBehaviour)}
      {renderQuestionsWithType(QuestionType.komunikacia, strings.communication)}
      {renderQuestionsWithType(QuestionType.specifickeSpravanie, strings.specificBehaviour)}
      {renderQuestionsWithType(QuestionType.emocnaZrelost, strings.emotionalGrowth)}
      {renderQuestionsWithType(QuestionType.porozumenie, strings.understanding)}
      {renderQuestionsWithType(QuestionType.rec, strings.speech)}
      {renderQuestionsWithType(QuestionType.samostatnost, strings.independence)}
      {renderQuestionsWithType(
        QuestionType.spefificke_spravanie_11_12_skrining,
        strings.specificBehaviour + ' - 11., 12. ' + strings.screening2,
      )}
      {renderQuestionsWithType(QuestionType.obavy, strings.worries)}
    </div>
  );
}

ScreeningSections.propTypes = {
  questions: PropTypes.array,
  answers: PropTypes.object,
  changeQuestionStatus: PropTypes.func,
  clientId: PropTypes.string,
  screening: PropTypes.object,
};

const QuestionType = {
  motorika: 'motorika', // M1
  adaptivneSpravanie: 'adaptivne_spravanie', // S@
  komunikacia: 'komunikacia', // K
  specifickeSpravanie: 'specificke_spravanie', //Z
  obavy: 'obavy', // O1

  emocnaZrelost: 'emocna_zrelost', // SS
  porozumenie: 'porozumenie', // PR
  rec: 'rec', // K1
  samostatnost: 'samostatnost', //SE,
  spefificke_spravanie_11_12_skrining: 'specificke_spravanie_11_12_skrining', // SS
};
