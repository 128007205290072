import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Button as AntButton, Modal, Popover } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import ReactMarkdown from 'react-markdown';

// Local imports
import {
  updateActivityFormAction,
  addNewActivityAction,
  cleanUpActivityFormAction,
  editActivityFormAction,
} from '../../actions/activityForm.actions';
import { strings } from '../../strings/StringsProvider';
import { cleanMessageAction } from '../../actions/message.actions';
import Button from 'components/Button';
import './AddNewActivity.scss';

const { TextArea } = Input;

const formCheckFields = ['name', 'type', 'from', 'to'];

const markdownHelp = (
  <div className="markdownHelp">
    <h4>{strings.markDownHelp}.</h4>
    <div>{strings.markDownHelp1}:</div>
    <div className="section">
      <div className="column">
        <div>{strings.coarseFont}</div>
        <div>{strings.slantedFont}</div>
      </div>
      <div className="markdownPreview column noMargin">
        <ReactMarkdown>{strings.coarseFont}</ReactMarkdown>
        <ReactMarkdown>{strings.slantedFont}</ReactMarkdown>
      </div>
    </div>
    <div className="section">
      <div className="column">
        <div># {strings.header} 1</div>
      </div>
      <div className="markdownPreview column">
        <ReactMarkdown>{`# ${strings.header} 1`}</ReactMarkdown>
      </div>
    </div>
    <div className="section">
      <div className="column">
        <div>## {strings.header} 2</div>
      </div>
      <div className="markdownPreview column">
        <ReactMarkdown>{`## ${strings.header} 2`}</ReactMarkdown>
      </div>
    </div>
    <div className="section">
      <div className="column">
        <div>### {strings.header} 3</div>
      </div>
      <div className="markdownPreview column">
        <ReactMarkdown>{`### ${strings.header} 3`}</ReactMarkdown>
      </div>
    </div>
    <div className="section">
      <div className="column">
        <div>1. {strings.numberedList}</div>
        <div>1. {strings.secondItem}</div>
        <div className="whitespaced">{'   1. ' + strings.underItem}</div>
      </div>
      <div className="markdownPreview column">
        <ReactMarkdown>
          {`1. ${strings.numberedList}\n1. ${strings.secondItem}\n   1. ` + strings.underItem}
        </ReactMarkdown>
      </div>
    </div>
    <div className="section">
      <div className="column">
        <div>- {strings.notNumberedList}</div>
        <div>- {strings.secondItem}</div>
        <div className="whitespaced">{'   - ' + strings.underItem}</div>
      </div>
      <div className="markdownPreview column">
        {' '}
        <ReactMarkdown>
          {`- ${strings.notNumberedList}\n- ${strings.secondItem}\n   - ` + strings.underItem}
        </ReactMarkdown>
      </div>
    </div>
  </div>
);

function NewActivity({ match, history }) {
  const [newActivity, setNewActivity] = useState(true);

  const activity = useSelector((state) => state.activityForm);
  const messageText = useSelector((state) => state.message.text);
  const messageResult = useSelector((state) => state.message.result);

  const dispatch = useDispatch();

  useEffect(() => {
    if (match.path.includes(strings.add1)) {
      dispatch(cleanUpActivityFormAction());
    } else if (match.path.includes(strings.edit1)) {
      setNewActivity(false);
    }
  }, [dispatch, match.path]);

  const pridatActivity = useCallback(() => {
    dispatch(addNewActivityAction(activity));
  }, [activity, dispatch]);

  const editActivity = useCallback(async () => {
    await dispatch(editActivityFormAction(activity, history.goBack));
  }, [activity, dispatch, history.goBack]);

  const setFormInfo = useCallback(
    (formField) => (event) => {
      dispatch(updateActivityFormAction(formField, event.target.value));
    },
    [dispatch],
  );

  const checkFormFields = useCallback(
    () =>
      formCheckFields.some((field) => {
        return activity[field] === '';
      }),
    [activity],
  );

  const addNewPhoto = useCallback(
    (type) => () => {
      if (type === strings.helperPhotos) {
        if (!!activity.newPomockyPhoto && !activity.pomockyPhotos.find((photo) => photo === activity.newPomockyPhoto)) {
          dispatch(updateActivityFormAction(strings.newHelperPhotos, ''));
          dispatch(
            updateActivityFormAction(strings.helperPhotos, [...activity.pomockyPhotos, activity.newPomockyPhoto]),
          );
        }
      } else {
        if (!!activity.newPriebehPhoto && !activity.priebehPhotos.find((photo) => photo === activity.newPriebehPhoto)) {
          dispatch(updateActivityFormAction(strings.newStoryPhotos, ''));
          dispatch(
            updateActivityFormAction(strings.storyPhotos, [...activity.priebehPhotos, activity.newPriebehPhoto]),
          );
        }
      }
    },
    [activity.newPomockyPhoto, activity.newPriebehPhoto, activity.pomockyPhotos, activity.priebehPhotos, dispatch],
  );

  const removePhoto = useCallback(
    (item) => (type) => () => {
      if (type === strings.helperPhotos) {
        const pomockyPhotos = activity.pomockyPhotos.filter((photo) => photo !== item);
        dispatch(updateActivityFormAction(strings.helperPhotos, pomockyPhotos));
      } else {
        const priebehPhotos = activity.priebehPhotos.filter((photo) => photo !== item);
        dispatch(updateActivityFormAction(strings.storyPhotos, priebehPhotos));
      }
    },
    [activity.pomockyPhotos, activity.priebehPhotos, dispatch],
  );

  const addNewUrl = useCallback(() => {
    if (!!activity.newYoutubeVideo && !activity.youtubeVideos.find((video) => video === activity.newYoutubeVideo)) {
      dispatch(updateActivityFormAction('newYoutubeVideo', ''));
      dispatch(updateActivityFormAction('youtubeVideos', [...activity.youtubeVideos, activity.newYoutubeVideo]));
    }
  }, [activity.newYoutubeVideo, activity.youtubeVideos, dispatch]);

  const removeUrl = useCallback(
    (item) => () => {
      const youtubeVideos = activity.youtubeVideos.filter((video) => video !== item);
      dispatch(updateActivityFormAction('youtubeVideos', youtubeVideos));
    },
    [activity.youtubeVideos, dispatch],
  );

  const handleCancel = () => {
    dispatch(cleanMessageAction());
  };

  return (
    <div className="register-background">
      <div className="addNewactivityFormContainer">
        <div className="submenu">
          <Button
            type="secondary"
            icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
            label={strings.goBack}
            className="go-back-button"
            onClick={history.goBack}
          />
        </div>
        <span className="activityNameText">{activity.name ? activity.name : null}</span>
        <div className="formSection">
          <div className="leftSection" data-test-id="activityAdd-leftSection">
            <div className="addNewactivityFormField">
              <h3>{strings.name}</h3>
              <div data-test-id="activityAdd-leftSection-nameInput">
                <Input
                  value={activity.name}
                  onChange={setFormInfo('name')}
                  placeholder={strings.nameOfActivity}
                  className="add-input"
                />
              </div>
            </div>
            <div className="addNewactivityFormField">
              <h3>{strings.numberOfLecture}</h3>
              <div data-test-id="activityAdd-leftSection-lectionNumberInput">
                <Input
                  value={activity.code}
                  onChange={setFormInfo('code')}
                  placeholder={strings.numberOfLecture1}
                  className="add-input"
                />
              </div>
            </div>
            <div className="addNewactivityFormField">
              <h3>{strings.goal}</h3>
              <div data-test-id="activityAdd-leftSection-goalInput">
                <TextArea
                  value={activity.ciel}
                  onChange={setFormInfo('ciel')}
                  placeholder={strings.goalOfActivity}
                  rows={4}
                  style={{ minHeight: '100px' }}
                />
              </div>
            </div>
            <div className="addNewactivityFormField">
              <h3>{strings.helperThings}</h3>
              <div data-test-id="activityAdd-leftSection-toolsInput">
                <TextArea
                  value={activity.pomocky}
                  onChange={setFormInfo('pomocky')}
                  placeholder={strings.helperThings}
                  rows={4}
                  style={{ minHeight: '100px' }}
                />
              </div>
            </div>
            <div className="addNewactivityPhotosFormField">
              <h3>{strings.photoOfHelperThing}</h3>
              <div data-test-id="activityAdd-leftSection-toolsPhotos">
                {activity.pomockyPhotos.map((item, index) => (
                  <div className="activityPhotoItem" key={item} data-test-id={`activityAdd-leftSection-photo${index}`}>
                    <div className="uploadedImage">
                      <img alt="Fotka pomôcky" src={item} />
                    </div>
                    <MinusCircleOutlined onClick={removePhoto(item)(strings.helperPhotos)} />
                  </div>
                ))}
                <div className="activityPhotoItem">
                  <Input
                    type="link"
                    value={activity.newPomockyPhoto}
                    onChange={setFormInfo('newPomockyPhoto')}
                    placeholder={strings.urlPicture}
                  />
                  <PlusCircleOutlined onClick={addNewPhoto(strings.helperPhotos)} />
                </div>
              </div>
            </div>
          </div>
          <div className="rightSection" data-test-id="activityAdd-rightSection">
            <div className="addNewactivityFormField">
              <div className="row">
                <h3>{strings.story}</h3>
                <Popover content={markdownHelp}>
                  <QuestionCircleOutlined style={{ fontSize: '25px', color: '#41aea6' }} />
                </Popover>
              </div>
              <div data-test-id="activityAdd-rightSection-progressTextArea">
                <TextArea
                  value={activity.priebeh}
                  onChange={setFormInfo('priebeh')}
                  placeholder={strings.story}
                  rows="10"
                  cols="50"
                />
              </div>
            </div>
            <div className="addNewactivityFormField">
              <h3>{strings.showcase}</h3>
              <div className="markdownPreview">
                <ReactMarkdown>{activity.priebeh}</ReactMarkdown>
              </div>
            </div>
            <div className="addNewactivityPhotosFormField">
              <h3>{strings.storyPhoto}</h3>
              <div data-test-id="activityAdd-rightSection-progressPhoto">
                {activity.priebehPhotos.map((item) => (
                  <div className="activityPhotoItem" key={item}>
                    <div className="uploadedImage">
                      <img alt="Fotka aktivity" src={item} />
                    </div>
                    <MinusCircleOutlined onClick={removePhoto(item)('')} />
                  </div>
                ))}
                <div className="activityPhotoItem">
                  <Input
                    type="link"
                    value={activity.newPriebehPhoto}
                    onChange={setFormInfo('newPriebehPhoto')}
                    placeholder={strings.urlPicture}
                  />
                  <PlusCircleOutlined onClick={addNewPhoto()} />
                </div>
              </div>
            </div>
            <div className="addNewactivityFormField">
              <h3 data-test-id="activityAdd-rightSection-age">
                {strings.age}: {activity.mesiac}m/
                {activity.tyzden}t
              </h3>
              <h3>{strings.month}: </h3>
              <div data-test-id="activityAdd-rightSection-monthAgeInput">
                <Input
                  type="number"
                  value={activity.mesiac}
                  min="0"
                  onChange={setFormInfo('mesiac')}
                  placeholder={strings.ageInMonths}
                  className="add-input"
                />
              </div>
              <h3>{strings.week}:</h3>
              <div data-test-id="activityAdd-rightSection-weekAgeInput">
                <Input
                  type="number"
                  value={activity.tyzden}
                  min="0"
                  onChange={setFormInfo('tyzden')}
                  placeholder={strings.ageInWeeks}
                  className="add-input"
                />
              </div>
            </div>
            <div className="addNewactivityFormField"></div>
            <div className="addNewactivityFormField">
              <h3>Link</h3>
              <div data-test-id="activityAdd-rightSection-link">
                <Input
                  type="link"
                  value={activity.link}
                  onChange={setFormInfo('link')}
                  placeholder="link"
                  className="add-input"
                />
              </div>
            </div>
            <div className=" addNewactivityPhotosFormField">
              <h3>{strings.youtubeVideos}</h3>
              <div data-test-id="activityAdd-rightSection-ytLink">
                {activity.youtubeVideos.map((item) => (
                  <div className="activityYoutubeItem" key={item}>
                    <div className="youtubeUrl">
                      <a href={item} target="_blank" rel="noopener noreferrer">
                        {item}
                      </a>
                    </div>
                    <MinusCircleOutlined onClick={removeUrl(item)} />
                  </div>
                ))}
                <div className="activityYoutubeItem">
                  <Input
                    type="link"
                    value={activity.newYoutubeVideo}
                    onChange={setFormInfo('newYoutubeVideo')}
                    placeholder={strings.youtubeURL}
                  />
                  <PlusCircleOutlined onClick={addNewUrl} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="regButton" data-test-id="activityAdd-rightSection-addNewActivity">
          <AntButton
            type="primary"
            onClick={newActivity ? pridatActivity : editActivity}
            disabled={checkFormFields()}
            loading={activity.loading}
            style={{ marginTop: 16 }}
          >
            {newActivity ? strings.addNewActivity : strings.editActivity}
          </AntButton>
        </div>
      </div>
      <Modal
        className="modal"
        open={!isEmpty(messageText)}
        closable={false}
        centered={true}
        footer={null}
        styles={{ backgroundColor: 'rgba(140, 140, 140, 0.65)' }}
        onCancel={handleCancel}
      >
        {messageResult ? (
          <p className="textSuccess">&#10004;&nbsp; {messageText}</p>
        ) : (
          <p className="textFail">&#10006;&nbsp;&nbsp;{messageText}</p>
        )}
      </Modal>
    </div>
  );
}

NewActivity.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default NewActivity;
