import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { strings } from '../../../strings/StringsProvider';
import ActionAnswersStats from 'components/ActionAnswersStats';
import ScreeningAnswersStats from 'components/ScreeningAnswersStats';

const tableNames = [
  `S-PMV 2  (do 4. ${strings.week3})`,
  `S-PMV 3  (5-7. ${strings.week4})`,
  `S-PMV 4  (8-10. ${strings.week4})`,
  `S-PMV 5  (3-4. ${strings.oneMonth})`,
  `S-PMV 6  (5-6. ${strings.oneMonth})`,
  `S-PMV 7  (7-8. ${strings.oneMonth})`,
  `S-PMV 8  (9-10. ${strings.oneMonth})`,
  `S-PMV 9  (11-12. ${strings.oneMonth})`,
  `S-PMV 10  (15-18. ${strings.oneMonth})`,
  `S-PMV 11  (26-35. ${strings.oneMonth})`,
  `S-PMV 12  (36-40. ${strings.oneMonth})`,
];

function StatisticsOthers({ statYear, listOfClients }) {
  const [screeningTableFor, setScreeningTableFor] = useState('all');

  const totalLessons = useSelector((state) => state.statistics.totalLessons);
  const totalClubs = useSelector((state) => state.statistics.totalClubs);
  const totalParentClubs = useSelector((state) => state.statistics.totalParentClubs);
  const preSchoolClubCount = useSelector((state) => state.statistics.preSchoolClubCount);
  const screeningStatsTableData = useSelector((state) => state.statistics.screeningStatsTableData);
  const omamasByID = useSelector((state) => state.statistics.omamasByID);
  const omamasCatalogue = useSelector((state) => state.statistics.omamas);
  const actionAnswers = useSelector((state) => state.statistics.actionAnswers);
  const screeningAnswers = useSelector((state) => state.statistics.screeningAnswers);
  const filledScreeningsByType = useSelector((state) => state.statistics.filledScreeningsByType);
  const screeningTestsByID = useSelector((state) => state.statistics.screeningTestsByID);

  const rednerLastSkriningRow = (green, orange, red, worries, total) => {
    return (
      <div className="skriningStatsTable-flexRow">
        <div className="skriningStatCell screeningName" style={{ width: '200px' }}>
          {strings.together}
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: '#19ff62' }}>
          {total ? ((green / total) * 100).toFixed() : 0} % ({green}{' '}
          {green === 1 ? strings.clients1 : green > 1 && green < 5 ? strings.clients2 : strings.clients3})
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: '#ffcf30' }}>
          {total ? ((orange / total) * 100).toFixed() : 0} % ({orange}{' '}
          {orange === 1 ? strings.clients1 : orange > 1 && orange < 5 ? strings.clients2 : strings.clients3})
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: '#ff5252' }}>
          {total ? ((red / total) * 100).toFixed() : 0} % ({red}{' '}
          {red === 1 ? strings.clients1 : red > 1 && red < 5 ? strings.clients2 : strings.clients3})
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: 'white' }}>
          {total ? ((worries / total) * 100).toFixed() : 0} % ( {worries}{' '}
          {worries === 1 ? strings.clients1 : worries > 1 && worries < 5 ? strings.clients2 : strings.clients3})
        </div>
      </div>
    );
  };

  const onOmamaChange = (item) => {
    setScreeningTableFor(item.key);
  };

  const omamaOptions = () => {
    if (omamasCatalogue) {
      let options = [];
      options = [{ _id: 'all', name: strings.all1 }, ...omamasCatalogue];
      return options.map((omama) => ({
        key: omama._id,
        label: omama.name,
      }));
    }
  };

  const renderSkriningRow = (rowData, key, i) => {
    let totalClients = rowData.totalClients;
    return (
      <div className="skriningStatsTable-flexRow" key={i}>
        <div className="skriningStatCell screeningName" style={{ width: '200px' }}>
          {tableNames[i]}
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: '#19ff62' }}
          onClick={() => listOfClients(rowData.greenClients)}
        >
          {totalClients ? ((rowData.green / totalClients) * 100).toFixed() : 0} % ({rowData.green}{' '}
          {rowData.green === 1
            ? strings.clients1
            : rowData.green > 1 && rowData.green < 5
              ? strings.clients2
              : strings.clients3}
          )
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: '#ffcf30' }}
          onClick={() => listOfClients(rowData.orangeClients)}
        >
          {totalClients ? ((rowData.orange / totalClients) * 100).toFixed() : 0} % ({rowData.orange}{' '}
          {rowData.orange === 1
            ? strings.clients1
            : rowData.orange > 1 && rowData.orange < 5
              ? strings.clients2
              : strings.clients3}
          )
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: '#ff5252' }}
          onClick={() => listOfClients(rowData.redClients)}
        >
          {totalClients ? ((rowData.red / totalClients) * 100).toFixed() : 0} % ({rowData.red}{' '}
          {rowData.red === 1
            ? strings.clients1
            : rowData.red > 1 && rowData.red < 5
              ? strings.clients2
              : strings.clients3}
          )
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: 'white' }}
          onClick={() => listOfClients(rowData.worriesClients)}
        >
          {totalClients ? ((rowData.worries / totalClients) * 100).toFixed() : 0} % ({rowData.worries}{' '}
          {rowData.worries === 1
            ? strings.clients1
            : rowData.worries > 1 && rowData.worries < 5
              ? strings.clients2
              : strings.clients3}
          )
        </div>
      </div>
    );
  };

  const renderSkriningTable = (data) => {
    let greenCount, orangeCount, redCount, worriesCount, totalCount;
    greenCount = orangeCount = redCount = worriesCount = totalCount = 0;

    Object.keys(data).forEach((key) => {
      greenCount += data[key].green;
      orangeCount += data[key].orange;
      redCount += data[key].red;
      worriesCount += data[key].worries;
      totalCount += data[key].totalClients;
    });
    return (
      <div className="skriningStatsTable">
        <div id="skriningStatsTableHeader" className="skriningStatsTable-flexRow" key={'header'}>
          <div className="skriningStatCell" style={{ width: '200px', fontWeight: 'bold', textAlign: 'center' }}>
            {strings.screening1}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: '#19ff62',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {strings.upperGreenZone}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: '#ffcf30',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {strings.upperOrangeZone}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: '#ff5252',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {strings.upperRedZone}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: 'white',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {strings.worries}
          </div>
        </div>
        {Object.keys(data).map((key, i) => renderSkriningRow(data[key], key, i))}
        {rednerLastSkriningRow(greenCount, orangeCount, redCount, worriesCount, totalCount)}
      </div>
    );
  };

  const renderActionAnswers = () => {
    return <ActionAnswersStats actionAnswers={actionAnswers} omamasByID={omamasByID} />;
  };

  const renderScreeningAnswers = () => {
    return (
      <ScreeningAnswersStats
        screeningAnswers={screeningAnswers}
        screeningTestsByID={screeningTestsByID}
        filledScreeningsByType={filledScreeningsByType}
      />
    );
  };

  return (
    <>
      <div className="clientsStats" data-test-id="statistics-othersTab-clientsStats1">
        <h3 className="clientsStats-info">
          {strings.allAmountOfLessons}: &nbsp;&nbsp;
          {totalLessons}
        </h3>
        <h3 className="clientsStats-info">
          {strings.allAmountOfPreschoolClubs}: &nbsp;&nbsp;
          {totalClubs}
        </h3>
        <h3 className="clientsStats-info">
          {strings.allAmountOfParentClubs}: &nbsp;&nbsp;
          {totalParentClubs}
        </h3>
        {dayjs().year() === statYear && (
          <h3 className="clientsStats-info">
            {strings.clientGoingToPreschoolClub}: &nbsp;&nbsp;
            {preSchoolClubCount}
          </h3>
        )}
      </div>
      <div className="clientsStats" data-test-id="statistics-othersTab-clientsStats2">
        <h4 className="clientsStats-header">{strings.growthScreening} </h4>
        {omamasByID && (
          <div className="editProfile-dropdown" data-test-id="statistics-othersTab-clientsStats2-omamaOption">
            <Dropdown menu={{ items: omamaOptions(), onClick: onOmamaChange }} trigger={['click']} placement="bottom">
              <div>
                {omamasByID[screeningTableFor] ? omamasByID[screeningTableFor].name : strings.all1}
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        )}
        {screeningStatsTableData && renderSkriningTable(screeningStatsTableData[screeningTableFor])}
        <h4 className="clientsStats-header">{strings.growthScreeningScore}</h4>
        {filledScreeningsByType && screeningAnswers && screeningTestsByID && renderScreeningAnswers()}
        <h4 className="clientsStats-header">{strings.parentEngagement}</h4>
        {omamasByID && actionAnswers && renderActionAnswers()}
      </div>
    </>
  );
}

StatisticsOthers.propTypes = {
  statYear: PropTypes.number.isRequired,
  listOfClients: PropTypes.func.isRequired,
};

export default StatisticsOthers;
