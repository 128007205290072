/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../../strings/StringsProvider';

function StatisticsCommunities({ sortedClientsByCity, listOfClients }) {
  const renderCommunities = (element, index) => {
    return (
      <div key={index} className="userCounter comCounter">
        <div className="counterMainTitle counterCommunityTitle">{element.city}</div>
        <div className="firstCounter">
          <div onClick={() => listOfClients(element.clients.active, true)} className="firstNumber pointer">
            {element.countActive}
          </div>
          {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'sk' && (
            <div className="userCounterInnerText">
              {element.countTotal > 1 && strings.active4}
              {element.countTotal === 1 && strings.active}
              {element.countTotal === 0 && strings.active2}
            </div>
          )}
          {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz' && (
            <div className="userCounterInnerText">
              {element.countTotal > 1 && strings.active}
              {element.countTotal === 1 && strings.active}
              {element.countTotal === 0 && strings.active3}
            </div>
          )}
        </div>
        <div className="secondaryCounters">
          <div>
            <div
              onClick={() => listOfClients([...element.clients.active, ...element.clients.nonActive], true)}
              className="secondNumber pointer"
              style={{ color: '#41aea6' }}
            >
              {element.countTotal}
            </div>
            <div
              style={{
                fontSize: '0.8em',
                fontWeight: 'bold',
                borderTop: '0.5px solid gray',
                padding: '0 5px',
              }}
            >
              {strings.together}
            </div>
          </div>
          <div>
            <div onClick={() => listOfClients(element.clients.nonActive, true)} className="secondNumber pointer">
              {element.countNonActive}
            </div>
            <div
              style={{
                fontSize: '0.8em',
                fontWeight: 'bold',
                borderTop: '0.5px solid gray',
                padding: '0 5px',
              }}
            >
              {strings.notActive}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="communitiesSection">
      <div className="communities" data-test-id="statistics-community-counter">
        {sortedClientsByCity.length !== 0 ? (
          sortedClientsByCity.map((element, i) => renderCommunities(element, i))
        ) : (
          <span className="noDataInfo">{strings.noData}</span>
        )}
      </div>
    </div>
  );
}
StatisticsCommunities.propTypes = {
  sortedClientsByCity: PropTypes.array.isRequired,
  listOfClients: PropTypes.func.isRequired,
};

export default StatisticsCommunities;
