import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { ConfigProvider, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { jwtDecode } from 'jwt-decode';
import preval from 'preval.macro';

// Local Imports
import './signin.scss';
import Formfield from '../../../components/Formfield';
import { strings } from '../../../strings/StringsProvider';
import { api } from '../../../conf';
import { addNewMessageAction } from '../../../actions/message.actions';
import { getOmamaTokenAction } from '../../../actions/omama.actions';
import { getUserNotifications } from 'slices/notifications';
import { showBugButton } from 'conf/loggers';
import packageData from '../../../../package.json';

const redirect = {
  omama: '/omama/plan',
  admin: '/admin',
  mentor: '/admin/users',
  supervisor: '/admin/users',
};

function SignIn() {
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const [loading, setLoading] = React.useState(false);

  const changePage = useCallback((location) => () => dispatch(push(location)), [dispatch]);

  const removeOldLocalStorageItems = () => {
    localStorage.removeItem('logs');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('displayRole');
    localStorage.removeItem('firstNameSearch');
    localStorage.removeItem('lastNameSearch');
    localStorage.removeItem('omama');
    localStorage.removeItem('status');
    localStorage.removeItem('statusName');
  };

  const signIn = async (data) => {
    setLoading(true);
    const { username, password } = data;

    try {
      removeOldLocalStorageItems();
      showBugButton(false);
      const tfToken = localStorage.getItem(username);
      const response = await axios.post(api.signIn, { username, password }, { headers: { tfToken } });

      const { data: resData } = response;

      if (resData.redirect) {
        changePage(resData.redirect)();
      } else {
        localStorage.setItem('access-token', resData.token);
        const tokenDecoded = jwtDecode(resData.token);
        setLoading(false);

        dispatch(getOmamaTokenAction());
        changePage(redirect[tokenDecoded.role])();

        if (notifications.status === 'done') {
          dispatch(getUserNotifications(true));
        }
      }
    } catch (error) {
      if (error.message.includes('Request failed with status code 40')) {
        if (
          typeof error.response?.data?.message === 'string' &&
          error.response?.data?.message?.includes('Username not active')
        ) {
          dispatch(addNewMessageAction(strings.userNotActive, false));
        } else if (
          typeof error.response?.data?.message === 'string' &&
          error.response?.data?.message?.includes('Geolocation is not allowed!')
        ) {
          dispatch(addNewMessageAction(strings.deniedLocationLoginMessage, false));
        } else {
          dispatch(addNewMessageAction(strings.wrongPassword, false));
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(addNewMessageAction(strings.backendError, false));
      }

      setLoading(false);
    }
  };

  const onPasswordKeyPress = async (event) => {
    if (event.key === 'Enter') {
      handleSubmit(signIn)();
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            paddingInline: 16,
          },
        },
      }}
    >
      <div className="background">
        <div className="centerDiv">
          <img className="logo" src="/images/cestavonLOGO_unofficial.png" alt="logo" />
          <form onSubmit={handleSubmit(signIn)}>
            <div onKeyDown={onPasswordKeyPress}>
              <div className="inputDiv loginForm">
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => <Formfield placeholder={strings.userName} {...field} />}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => <Formfield placeholder={strings.password} type="password" {...field} />}
                />
              </div>
              <Button className="loginButton" shape="round" htmlType="submit" disabled={loading}>
                {strings.login}
              </Button>
            </div>
          </form>
          <div style={{ position: 'absolute', bottom: 0 }}>
            Build Date: {preval`module.exports = new Date().toLocaleString();`}, v{packageData.version}
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default SignIn;
