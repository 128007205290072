import React, { useEffect } from 'react';
import { Layout } from 'antd';
import OmamaProfileStat from './OmamaProfileStat';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from '../../../components/ProgressBar';
import dayjs from 'dayjs';

import './OmamaProfile.scss';

import {
  getOmamaClientsAction,
  getOmamaTokenAction,
  getFinishedLessonsCount,
  getFinishedClubsCount,
  getOmamaMilestones,
  getActionsAction,
} from 'actions/omama.actions';
import { strings } from '../../../strings/StringsProvider';
import { getMonthWorkingDays } from '../../../tools/date.tools';
import { nonClientActionsMap } from 'data/enums';

const milestonesText = {
  lessons: strings.lessons,
  clubs: strings.clubs,
  supervisions: strings.supervisions1,
  supervisionVisits: strings.supervisionsVisits,
};

function prepareMilestones(level, data) {
  let milestones = [];
  switch (level) {
    case 1:
    case 2:
      milestones = ['lessons', 'clubs', 'supervisions'];
      break;
    case 3:
      milestones = ['supervisions', 'supervisionVisits'];
      break;
    case 4:
      milestones = [];
      break;
    default:
      break;
  }

  return milestones.map((key) => {
    return {
      text: milestonesText[key],
      key,
      done: data ? data[key] : 0,
      total: data ? data[`max${key.charAt(0).toUpperCase() + key.slice(1)}`] : 0,
    };
  });
}

const LayoutStyle = {
  padding: '2rem',
  backgroundColor: 'white',
};

const levelMap = {
  1: strings.enums.beginner,
  2: strings.enums.junior,
  3: strings.enums.senior,
  4: strings.enums.expert,
};

function OmamaProfile() {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  useEffect(() => {
    state.omama.omama.username || dispatch(getOmamaTokenAction());
  }, [dispatch, state.omama.omama.username]);

  useEffect(() => {
    if (state.omama?.omama?.username) {
      const thisYear = new Date().getFullYear();
      const startOfYear = new Date(thisYear, 0, 1);
      const today = new Date();

      dispatch(getOmamaClientsAction());
      dispatch(getFinishedLessonsCount(startOfYear, today));
      dispatch(getFinishedClubsCount(startOfYear, today));
      dispatch(getOmamaMilestones(state.omama?.omama?.username));
      dispatch(
        getActionsAction(dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')),
      );
    }
  }, [dispatch, state.omama.omama.username]);

  const omama = state.omama;
  const omamaLevel = Number(omama.omama?.level);
  const milestones = prepareMilestones(omamaLevel, omama.milestones);
  const startedSince = omama.omama?.onLevelSince ? new Date(omama.omama?.onLevelSince) : new Date();
  const maxClients = omama?.milestones?.maxClients || 0;
  let startedMonths = dayjs(new Date()).diff(startedSince, 'months');
  switch (true) {
    case isNaN(startedMonths):
      startedMonths = '';
      break;
    case startedMonths === 1:
      startedMonths = `${startedMonths} ${strings.oneMonth}`;
      break;
    case startedMonths === 0 || startedMonths >= 5:
      startedMonths = `${startedMonths} ${strings.zeroOrFiveMonths}`;
      break;
    case startedMonths >= 2 && startedMonths <= 4:
      startedMonths = `${startedMonths} ${strings.twoTillFourMonths}`;
      break;
    default:
      startedMonths = `${startedMonths} ${strings.zeroOrFiveMonths}`;
      break;
  }

  const omamaActions = omama.activities;
  let clientActions = 0;
  let nonClientActions = 0;
  let parentClubs = 0;
  const countedPreschoolClubs = {};

  const dailyWorkingHours = {
    1: 8,
    2: 4,
    3: 6,
  };

  omamaActions.forEach((action) => {
    const isAfterDate = dayjs(action.date).isAfter(dayjs(new Date()).endOf('day'));
    if (isAfterDate) {
      return;
    }
    if (!action.client) {
      if (action.id === strings.parentalClub1) {
        parentClubs += 1;
      } else if (nonClientActionsMap[action.id]) {
        nonClientActions += action.timeSpent;
      }
    } else if (action.differentAction === 'club' && action.status === 'done') {
      // Avoid counting club duplicates
      const uid = action.clubNames + action.date;
      if (!countedPreschoolClubs[uid]) {
        clientActions += action.clubIds.length;
        countedPreschoolClubs[uid] = true;
      }
    } else if (action.status === 'done') {
      clientActions += 1;
    }
  });

  const employmentType = omama.omama?.employmentType || 1;
  const omamaDailyWorkingHours = dailyWorkingHours[employmentType];
  const workdays = getMonthWorkingDays(dayjs().month() + 1, dayjs().year());
  const expectedActions = Math.max(
    0,
    Math.round((maxClients * workdays) / 5 - Math.round((nonClientActions * maxClients) / 5 / omamaDailyWorkingHours)),
  );
  const actionsPercentage = (clientActions / expectedActions) * 100;

  return (
    <Layout style={LayoutStyle} className="content" id="omama-profile">
      <Content>
        <div className="omama-level">
          <p className="section-title">{strings.myPosition}:</p>
          <p className="section-title omama-position">{levelMap[omamaLevel]}</p>
        </div>
      </Content>
      <Content>
        <div id="omama-profile-stats">
          <OmamaProfileStat title={`${strings.amountOfClients}:`} content={`${omama?.clientsCount} / ${maxClients}`} />
          <OmamaProfileStat
            title={`${strings.onPositionFrom}:`}
            content={startedMonths === '' ? '-' : `${startedSince.toLocaleDateString('sk')} (${startedMonths})`}
          />
          <OmamaProfileStat
            title={`${strings.countDoneLections}:`}
            content={`${strings.thisYear} ${omama.actionsCount?.lessons?.thisYear || 0}, ${strings.together} ${
              omama.actionsCount?.lessons?.allTime || 0
            }`}
          />
          <OmamaProfileStat
            title={`${strings.countDoneClubs}:`}
            content={`${strings.thisYear} ${omama.actionsCount?.clubs?.thisYear || 0}, ${strings.together} ${
              omama.actionsCount?.clubs?.allTime || 0
            }`}
          />
        </div>
      </Content>
      <Content>
        <div id="omama-profile-activity">
          <p className="section-title">{strings.activityThisMonth}</p>
          <ProgressBar
            text={strings.lessonsSoFar}
            pointsWidth={100}
            current={clientActions}
            total={expectedActions}
            fillClass={actionsPercentage <= 59 ? ' red' : actionsPercentage <= 89 ? ' orange' : ''}
            showPercentage
          />
          <ProgressBar text={strings.parentalClub} pointsWidth={100} current={parentClubs} total={1} showPercentage />
        </div>
      </Content>
      {omamaLevel !== 4 && (
        <Content>
          <div id="omama-profile-milestones" className="section">
            <p className="section-title">{strings.final}</p>

            <div>
              {milestones.map((milestone) => {
                return (
                  <ProgressBar
                    text={milestone.text}
                    pointsWidth={100}
                    key={milestone.key}
                    current={milestone.done}
                    total={milestone.total}
                  />
                );
              })}
            </div>
            {levelMap[omamaLevel + 1] && (
              <div className="omama-level">
                <p className="section-title">{strings.positionAfterComplition}:</p>
                <p className="section-title omama-position">{levelMap[omamaLevel + 1]}</p>
              </div>
            )}
          </div>
        </Content>
      )}
    </Layout>
  );
}

export default OmamaProfile;
