/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Table, Tooltip } from 'antd';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import dayjs from 'dayjs';

// Local imports
import './Statistics.scss';
import {
  getStatistics,
  getStatisticsClients,
  getStatisticsOmamas,
  getStatisticsOther,
  getTrends,
} from '../../../actions/statistics.actions';
import { strings } from '../../../strings/StringsProvider';

import { omamaLevelMap } from '../../../data/enums';
import { stringSorter } from 'tools/utils';
import StatisticsCommunities from './StatisticsCommunities';
import StatisticsClients from './StatisticsClients';
import StatisticsOmamas from './StatisticsOmamas';
import StatisticsOthers from './StatisticsOthers';
import StatisticsTrends from './StatisticsTrends';

function Statistics() {
  const [clientsTab, setClientsTab] = useState(false);
  const [communitiesTab, setCommunitiesTab] = useState(false);
  const [othersTab, setOthersTab] = useState(false);
  const [trendsTab, setTrendsTab] = useState(false);
  const [omamasTab, setOmamasTab] = useState(false);
  const [initialClients, setInitialClients] = useState(true);
  const [initialCommunities, setInitialCommunities] = useState(true);
  const [initialOthers, setInitialOthers] = useState(true);
  const [initialTrends, setInitialTrends] = useState(true);
  const [initialOmamas, setInitialOmamas] = useState(true);

  const [statYear, setStatYear] = useState(parseInt(dayjs().format('YYYY')));

  const dispatch = useDispatch();

  const clientsByBirthDate = useSelector((state) => state.statistics.clientsByBirthDate);
  const clientsByCity = useSelector((state) => state.statistics.clientsByCity);
  const omamasStats = useSelector((state) => state.statistics.omamasStats);

  const clientsByID = useSelector((state) => state.statistics.clientsByID);

  const clientList = useSelector((state) => state.statistics.clientList);
  const omamaList = useSelector((state) => state.statistics.omamaList);
  const mentorList = useSelector((state) => state.statistics.mentorList);
  const supervisorList = useSelector((state) => state.statistics.supervisorList);
  const countDoneLections = useSelector((state) => state.statistics.countDoneLections);
  const countDoneClubs = useSelector((state) => state.statistics.countDoneClubs);
  const countParentalClub = useSelector((state) => state.statistics.countParentalClub);

  const users = useSelector((state) => [
    {
      name: strings.omamy,
      total: state.statistics.allOmamas,
      active: state.statistics.activeOmamas,
      inactive: state.statistics.inactiveOmamas,
    },
    {
      name: strings.mentors,
      total: state.statistics.allMentors,
      active: state.statistics.activeMentors,
      inactive: state.statistics.inactiveMentors,
    },
    {
      name: strings.supervisors,
      total: state.statistics.allSupervisors,
      active: state.statistics.activeSupervisors,
      inactive: state.statistics.inactiveSupervisors,
    },
    {
      name: strings.clients,
      total: state.statistics.allClients,
      active: state.statistics.activeClients,
      inactive: state.statistics.inactiveClients,
    },
  ]);

  useEffect(() => {
    dispatch(getStatistics(dayjs().format('YYYY')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getStatisticsOther(statYear.toString()));
    dispatch(getStatistics(statYear.toString()));
    dispatch(getStatisticsOmamas(statYear.toString(), omamasStats.allTime));
    dispatch(getStatisticsClients(statYear.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statYear]);

  const renderUsers = (element, index) => {
    const omamasStatsTooltipTitle =
      element.name === 'Omamy' &&
      (statYear === dayjs().year() ? strings.tooltipStatsOmamasThisYear : strings.tooltipStatsOmamasOtherYears) +
        (omamasStats?.thisYear?.length === 1
          ? `${strings.wasActive1} ${omamasStats?.thisYear?.length} ${strings.oneOmama}.`
          : omamasStats?.thisYear?.length > 1 && omamasStats?.thisYear?.length < 5
            ? `${strings.wasActive2} ${omamasStats?.thisYear?.length} ${strings.twoAndMoreOmamas}.`
            : `${strings.wasActive3} ${omamasStats?.thisYear?.length} ${strings.fiveAndMoreOmamas}.`);

    let list = null;
    switch (element.name) {
      case strings.clients:
        list = clientList;
        break;
      case strings.omamy:
        list = omamaList;
        break;
      case strings.mentors:
        list = mentorList;
        break;
      case strings.supervisors:
        list = supervisorList;
        break;
      default:
        break;
    }

    if (clientList && omamaList && mentorList && supervisorList) {
      return (
        <div key={index} className="userCounter">
          <div className="counterMainTitle" data-test-id={`statistics-${element.name}`}>
            {element.name}
          </div>
          <Tooltip title={omamasStatsTooltipTitle} placement="left">
            <div
              className="firstCounter pointer"
              onClick={() => showList(list.active, element.name)}
              data-test-id={`statistics-${element.name}-firstCounter`}
            >
              <div className="firstNumber pointer" data-test-id={`statistics-${element.name}-firstNumber`}>
                {element.active}
              </div>

              {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'sk' && (
                <div className="userCounterInnerText" data-test-id={`statistics-${element.name}-innerText`}>
                  {((element.name === 'Mentorky' && element.total === 1) ||
                    (element.name === 'Omamy' && element.total === 1)) &&
                    strings.isActive}
                  {((element.name === 'Mentorky' && element.total > 1) ||
                    (element.name === 'Omamy' && element.total > 1)) &&
                    strings.active2}
                  {((element.name === 'Klienti' && element.total === 1) ||
                    (element.name === 'Supervízori' && element.total === 1)) &&
                    strings.active}
                  {((element.name === 'Klienti' && element.total > 1) ||
                    (element.name === 'Supervízori' && element.total > 1) ||
                    (element.name === 'Supervízori' && element.total === 0)) &&
                    strings.active4}
                  {((element.name === 'Mentorky' && element.total === 0) ||
                    (element.name === 'Omamy' && element.total === 0) ||
                    (element.name === 'Klienti' && element.total === 0)) &&
                    strings.active2}
                </div>
              )}

              {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz' && (
                <div className="userCounterInnerText" data-test-id={`statistics-${element.name}-innerText`}>
                  {((element.name === 'Mentorky' && element.total === 1) ||
                    (element.name === 'Omamy' && element.total === 1) ||
                    (element.name === 'Mentorky' && element.total > 1) ||
                    (element.name === 'Omamy' && element.total > 1) ||
                    (element.name === 'Klienti' && element.total === 1) ||
                    (element.name === 'Supervizoři' && element.total === 1) ||
                    (element.name === 'Klienti' && element.total > 1) ||
                    (element.name === 'Supervizoři' && element.total > 1)) &&
                    strings.active}
                  {((element.name === 'Mentorky' && element.total === 0) ||
                    (element.name === 'Omamy' && element.total === 0) ||
                    (element.name === 'Klienti' && element.total === 0) ||
                    (element.name === 'Supervizoři' && element.total === 0)) &&
                    strings.active3}
                </div>
              )}
            </div>
          </Tooltip>
          <div className="secondaryCounters">
            <div onClick={() => showList(list.total, element.name)}>
              <div
                className="secondNumber pointer"
                style={{ color: '#41aea6' }}
                data-test-id={`statistics-${element.name}-secondNumber-total`}
              >
                {element.total}
              </div>
              <div
                style={{
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                  borderTop: '0.5px solid gray',
                  padding: '0 5px',
                }}
              >
                {strings.together}
              </div>
            </div>
            <div onClick={() => showList(list.inactive, element.name)}>
              <div className="secondNumber pointer" data-test-id={`statistics-${element.name}-secondNumber-nonActive`}>
                {element.inactive}
              </div>
              <div
                style={{
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                  borderTop: '0.5px solid gray',
                  padding: '0 5px',
                }}
              >
                {element.name === strings.clients || element.name === strings.supervisors
                  ? strings.notActive
                  : strings.notActive1}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderAgeGroups = (data) => {
    const config = {
      data,
      xField: 'range',
      yField: 'count',
      label: {
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
      },
      tooltip: {
        title: strings.listOfClients,
        customContent: (title, data) => {
          const clientList = data[0] ? data[0].data.clientList.toString().replaceAll(',', '<br>') : '';
          return `<div><strong>${title}</strong></div><br><div>${clientList}</div>`;
        },
      },
    };
    return data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderOmamaDesktopTable = (data) => {
    const omamaTableColumns = [
      {
        title: strings.omama,
        dataIndex: 'name',
        key: 'name',
        sorter: stringSorter('name'),
      },
      {
        title: strings.region,
        dataIndex: 'region',
        key: 'region',
        sorter: stringSorter('region'),
      },
      {
        title: strings.location,
        dataIndex: 'city',
        key: 'city',
        sorter: stringSorter('city'),
      },
      {
        title: strings.level,
        dataIndex: 'level',
        key: 'level',
        render: (level) => <span>{omamaLevelMap[level]}</span>,
        sorter: (a, b) => a.level - b.level,
      },
      {
        title: strings.activeClients,
        dataIndex: 'activeClients',
        key: 'activeClients',
        className: 'centeredColumn',
        sorter: (a, b) => a.activeClients - b.activeClients,
      },
      {
        title: strings.nonClosedLessons,
        dataIndex: 'lessons',
        key: 'lessons',
        className: 'centeredColumn',
        render: (lessons, row) => (
          <div>
            <span>{lessons}</span>
            <span className="hidePercentage">{` (${Math.round(row.actionsPercentage)} %)`}</span>
          </div>
        ),
        sorter: (a, b) => a.lessons - b.lessons,
      },
      {
        title: strings.preschoolClubs1,
        dataIndex: 'preschoolClubs',
        key: 'preschoolClubs',
        className: 'centeredColumn',
        sorter: (a, b) => a.preschoolClubs - b.preschoolClubs,
      },
      {
        title: strings.parentClubs1,
        dataIndex: 'parentClubs',
        key: 'parentClubs',
        className: 'centeredColumn',
        sorter: (a, b) => a.parentClubs - b.parentClubs,
      },
      {
        title: strings.supervisions2,
        dataIndex: 'supervisions',
        key: 'supervisions',
        className: 'centeredColumn',
        sorter: (a, b) => a.supervisions - b.supervisions,
      },
      {
        title: strings.supervisionRatingAverage1,
        dataIndex: 'supervisionRatingAverage',
        key: 'supervisionRatingAverage',
        className: 'centeredColumn',
        render: (supervisionRatingAverage) => (supervisionRatingAverage ? `${supervisionRatingAverage}%` : '—'),
        sorter: (a, b) => a.supervisionRatingAverage - b.supervisionRatingAverage,
      },
    ];
    let sortedData = data && data.sort((a, b) => a.level - b.level || b.lessons - a.lessons);
    return (
      <div className="omamaStatisticsTable">
        <Table
          size="small"
          className="table desktopOmamaStatsTable"
          rowKey={(record) => record._id}
          pagination={false}
          dataSource={sortedData}
          columns={omamaTableColumns}
          indentSize={0}
          scroll={{ y: 400 }}
        />
      </div>
    );
  };

  const filterSameLevel = (data) => {
    const levelsMap = {};
    data.forEach((omama) => {
      const level = omama.level;
      if (!levelsMap[level]) {
        levelsMap[level] = [];
      }
      levelsMap[level].push(omama);
    });

    return levelsMap;
  };

  const renderMobileStatRow = (title, primaryData, secondaryData) => {
    return (
      <div className="statTable-statRow">
        <span className="statTable-statRow-title">{title}</span>
        <span className="statTable-statRow-number">
          {primaryData}
          {secondaryData !== '' && <span className="hidePercentage">{` (${Math.round(secondaryData)} %)`}</span>}
        </span>
      </div>
    );
  };

  const renderOmamaMobileTable = (data) => {
    const omamaMobileTableColumns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
      },
    ];
    const sameLevel = filterSameLevel(data);
    return (
      <div>
        {Object.keys(sameLevel).map((key) => {
          return (
            <div key={key} className="mobileOmamaStatsTotal">
              <div className="mobileOmamaLevel">{omamaLevelMap[key]}</div>
              <Table
                className="mobileOmamaTable"
                size="small"
                scroll={{ y: 200 }}
                pagination={false}
                rowKey={(record) => record._id}
                columns={omamaMobileTableColumns}
                dataSource={sameLevel[key]}
                expandedRowRender={(record) => (
                  <div className="statTable">
                    {renderMobileStatRow(record.city, '', '')}
                    {renderMobileStatRow(strings.nonClosedLessons, record.lessons, record.actionsPercentage)}
                    {renderMobileStatRow(strings.activeClients, record.activeClients, '')}
                    {renderMobileStatRow(strings.preschoolClubs1, record.preschoolClubs, '')}
                    {renderMobileStatRow(strings.parentClubs1, record.parentClubs, '')}
                    {renderMobileStatRow(strings.supervisions2, record.supervisions, '')}
                    {renderMobileStatRow(
                      strings.supervisionRatingAverage1,
                      record.supervisionRatingAverage ? `${record.supervisionRatingAverage}%` : '—',
                      '',
                    )}
                  </div>
                )}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const sortListOfNames = (list, type) => {
    if (type !== strings.clients) {
      return list;
    }
    const names = [];
    list.map((e) => {
      return names.push(`${e.lastName} ${e.firstName}`);
    });
    return names.sort();
  };

  const handleTab = (tab) => () => {
    switch (true) {
      case tab === 'clientsTab':
        if (initialClients) dispatch(getStatisticsClients(statYear.toString()));
        setClientsTab(!clientsTab);
        setInitialClients(false);
        break;
      case tab === 'omamasTab':
        if (initialOmamas) dispatch(getStatisticsOmamas(statYear.toString()));
        setOmamasTab(!omamasTab);
        setInitialOmamas(false);
        break;
      case tab === 'communitiesTab':
        if (initialCommunities) dispatch(getStatisticsClients(statYear.toString()));
        setCommunitiesTab(!communitiesTab);
        setInitialCommunities(false);
        break;
      case tab === 'othersTab':
        if (initialOthers) dispatch(getStatisticsOther(statYear.toString()));
        setOthersTab(!othersTab);
        setInitialOthers(false);
        break;
      case tab === 'trendsTab':
        if (initialTrends) dispatch(getTrends());
        setTrendsTab(!trendsTab);
        setInitialTrends(false);
        break;
      default:
        break;
    }
  };

  const showList = (list, type) => {
    const names = sortListOfNames(list, type);
    Modal.info({
      title:
        type === strings.clients
          ? strings.listOfClients
          : type === strings.omamy
            ? strings.listOfOmamas
            : type === strings.supervisors
              ? strings.listOfSupervisors
              : strings.listOfMentors,
      centered: true,
      maskClosable: true,
      content: (
        <div>
          {names.map((item, i) => {
            return (
              <div key={i} style={{ fontWeight: 'bold', color: '#41aea6' }}>
                {type === strings.clients ? `${item}` : item.name}
              </div>
            );
          })}
          {list.length === 0 && <div>{strings.listIsEmpty}</div>}
        </div>
      ),
    });
  };

  const listOfClients = (list, formated = false) => {
    Modal.info({
      title: strings.listOfClients,
      centered: true,
      maskClosable: true,
      content: (
        <div>
          {list.map((id, i) => (
            <div key={i} style={{ fontWeight: 'bold', color: '#41aea6' }}>
              {formated ? id : `${clientsByID[id].firstName} ${clientsByID[id].lastName}`}
            </div>
          ))}
          {list.length === 0 && <div>{strings.listIsEmpty}</div>}
        </div>
      ),
    });
  };

  const changeStatYear = (flag) => {
    if (flag === '-') {
      setStatYear(statYear - 1);
    } else if (flag === '+') {
      setStatYear(statYear + 1);
    }
  };

  let sortedClientsByCity = clientsByCity.sort((a, b) => a.city.localeCompare(b.city));

  return (
    <div className="statsBackground">
      <div className="boxStats">
        <div className="admin-main">
          <div className="navigationStats">
            <LeftOutlined onClick={() => changeStatYear('-')} data-test-id="statistics-leftArrow" />
            <div style={{ margin: 'auto' }} data-test-id="statistics-statYear">
              {statYear}
            </div>
            <RightOutlined onClick={() => changeStatYear('+')} data-test-id="statistics-rightArrow" />
          </div>

          <div className="mainStats">
            {/* Omamy, mentorky, supervizori, klienti */}
            <div className="firstSection">
              {users.map((element, i) => renderUsers(element, i))}
              {
                <div className="userCounter">
                  <div className="counterMainTitle" data-test-id="statistics-actions">
                    {strings.actionOneUpperCase}
                  </div>
                  <div className="firstCounter" data-test-id="statistics-actions-firstCounter">
                    <div className="firstNumber" data-test-id="statistics-actions-firstNumber">
                      {countDoneLections + countDoneClubs + countParentalClub}
                    </div>
                    <div className="userCounterInnerText" data-test-id="statistics-actions-firstNumber">
                      {strings.inThisYear}
                    </div>
                  </div>
                  <div className="secondaryCounters" data-test-id="statistics-actions-secondaryCounter">
                    <div>
                      <div
                        className="secondNumber"
                        style={{ color: '#41aea6' }}
                        data-test-id="statistics-actions-lections"
                      >
                        {countDoneLections}
                      </div>
                      <div
                        style={{
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                          borderTop: '0.5px solid gray',
                          padding: '0 5px',
                        }}
                      >
                        {strings.moreThenFiveLessons}
                      </div>
                    </div>
                    <div>
                      <div
                        className="secondNumber"
                        style={{ color: '#41aea6' }}
                        data-test-id="statistics-actions-clubs"
                      >
                        {countDoneClubs}
                      </div>
                      <div
                        style={{
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                          borderTop: '0.5px solid gray',
                          padding: '0 5px',
                        }}
                      >
                        {strings.clubs1}
                      </div>
                    </div>
                    <div>
                      <div className="secondNumber" data-test-id="statistics-actions-familyClubs">
                        {countParentalClub}
                      </div>
                      <div
                        style={{
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                          borderTop: '0.5px solid gray',
                          padding: '0 5px',
                        }}
                      >
                        {strings.clubs2}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            {/* Komunity */}
            <div
              className="secondSection"
              style={{ borderTop: '1px solid #41aea6' }}
              data-test-id="statistics-community-tab"
            >
              <div className="statsTab" onClick={handleTab('communitiesTab')}>
                <div>{strings.comunities}</div>
                {!communitiesTab ? (
                  <RightOutlined style={{ marginLeft: '15px' }} />
                ) : (
                  <DownOutlined style={{ marginLeft: '15px' }} />
                )}
              </div>
              {communitiesTab && (
                <StatisticsCommunities
                  sortedClientsByCity={sortedClientsByCity}
                  listOfClients={listOfClients}
                  data-test-id="statistics-communities"
                />
              )}
            </div>
            {/* Klienti */}
            <div className="secondSection" style={{ borderTop: '1px solid #41aea6' }}>
              <div className="statsTab" onClick={handleTab('clientsTab')} data-test-id="statistics-clients-tab">
                <div>{strings.clients}</div>
                {!clientsTab ? (
                  <RightOutlined style={{ marginLeft: '15px' }} />
                ) : (
                  <DownOutlined style={{ marginLeft: '15px' }} />
                )}
              </div>

              {clientsTab && (
                <StatisticsClients
                  renderAgeGroups={renderAgeGroups}
                  clientsByBirthDate={clientsByBirthDate}
                  data-test-id="statistics-clients"
                />
              )}
              {/* Omamy */}
              <div className="secondSection" style={{ borderTop: '1px solid #41aea6' }}>
                <div className="statsTab" onClick={handleTab('omamasTab')} data-test-id="statistics-omamas-tab">
                  <div>{strings.omamy}</div>
                  {!omamasTab ? (
                    <RightOutlined style={{ marginLeft: '15px' }} />
                  ) : (
                    <DownOutlined style={{ marginLeft: '15px' }} />
                  )}
                </div>
                {omamasTab && (
                  <StatisticsOmamas
                    renderOmamaDesktopTable={renderOmamaDesktopTable}
                    renderOmamaMobileTable={renderOmamaMobileTable}
                    omamasStats={omamasStats}
                    data-test-id="statistics-omamas"
                  />
                )}
              </div>
              <div
                className="statsTab"
                style={{ borderTop: '1px solid #41aea6' }}
                onClick={handleTab('othersTab')}
                data-test-id="statistics-othesTab"
              >
                <div>{strings.otherStatistics}</div>
                {!othersTab ? (
                  <RightOutlined style={{ marginLeft: '15px' }} />
                ) : (
                  <DownOutlined style={{ marginLeft: '15px' }} />
                )}
              </div>
              {othersTab && (
                <StatisticsOthers statYear={statYear} listOfClients={listOfClients} data-test-id="statistics-others" />
              )}
              <div className="statsTab" style={{ borderTop: '1px solid #41aea6' }} onClick={handleTab('trendsTab')}>
                <div data-test-id="statistics-trends-tab">{strings.trends}</div>
                {!trendsTab ? (
                  <RightOutlined style={{ marginLeft: '15px' }} />
                ) : (
                  <DownOutlined style={{ marginLeft: '15px' }} />
                )}
              </div>
              {trendsTab && <StatisticsTrends data-test-id="statistics-trends" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
