import React from 'react';
import './GalleryFolders.scss';
import PropTypes from 'prop-types';

function GalleryFolders({ folders, clickHandler, folderLabels }) {
  return Object.keys(folders || []).map((key) => (
    <div key={key} onClick={() => clickHandler(key)} className="galleryFolder adminShadow">
      {folderLabels ? <span>{folderLabels[key]}</span> : <span>{key}</span>}
    </div>
  ));
}

GalleryFolders.propTypes = {
  folders: PropTypes.object,
  clickHandler: PropTypes.func,
  folderLabels: PropTypes.object,
};

export default GalleryFolders;
